import { Roles } from 'enums/Roles'
import { useCallback, useMemo, useState } from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'

export const usePostRange = ({ readOnly }: { readOnly: boolean } = { readOnly: false }) => {
  const [isConsumerChecked, setIsConsumerChecked] = useState(false)
  const [isChiefChecked, setIsChiefChecked] = useState(false)
  const [isMemberChecked, setIsMemberChecked] = useState(false)
  const [isRetrieverChecked, setIsRetrieverChecked] = useState(false)
  const [isFactoryCHecked, setIsFactoryCHecked] = useState(false)
  const [isShowHint, setIsShowHint] = useState(false)

  const range: Roles[] = useMemo(() => {
    const range = []
    if (isConsumerChecked) range.push(Roles.CONSUMER)
    if (isChiefChecked) range.push(Roles.CHIEF)
    if (isMemberChecked) range.push(Roles.MEMBER)
    if (isRetrieverChecked) range.push(Roles.RETRIEVER)
    if (isFactoryCHecked) range.push(Roles.FACTORY)

    return range
  }, [isChiefChecked, isConsumerChecked, isFactoryCHecked, isMemberChecked, isRetrieverChecked])

  const updateRange = useCallback((rangeArray: Roles[]) => {
    setIsConsumerChecked(rangeArray.includes(Roles.CONSUMER))
    setIsChiefChecked(rangeArray.includes(Roles.CHIEF))
    setIsMemberChecked(rangeArray.includes(Roles.MEMBER))
    setIsRetrieverChecked(rangeArray.includes(Roles.RETRIEVER))
    setIsFactoryCHecked(rangeArray.includes(Roles.FACTORY))
  }, [])

  const renderPostRange = () => (
    <div className="flex gap-4 msm:flex-col msm:gap-0">
      <div className="relative">
        {isShowHint ? (
          <div className="ballon z-20">선택된 사용자에게 해당 글이 공개됩니다.</div>
        ) : null}
        <div
          className="flex items-center gap-1 text-gray-400 cursor-help"
          onMouseOver={() => setIsShowHint(true)}
          onMouseOut={() => setIsShowHint(false)}>
          <AiOutlineQuestionCircle />
          공개설정
        </div>
      </div>
      <div className="flex flex-wrap gap-3 gap-y-0">
        <div className="flex items-center">
          <input
            disabled={readOnly}
            id="range-consumer"
            type="checkbox"
            checked={isConsumerChecked}
            onChange={e => setIsConsumerChecked(e.target.checked)}
          />
          <label className="pl-2" htmlFor="range-consumer">
            고객
          </label>
        </div>
        <div className="flex items-center">
          <input
            disabled={readOnly}
            id="range-chief"
            type="checkbox"
            checked={isChiefChecked}
            onChange={e => setIsChiefChecked(e.target.checked)}
          />
          <label className="pl-2" htmlFor="range-chief">
            센터매니저
          </label>
        </div>

        <div className="flex items-center">
          <input
            disabled={readOnly}
            id="range-member"
            type="checkbox"
            checked={isMemberChecked}
            onChange={e => setIsMemberChecked(e.target.checked)}
          />
          <label className="pl-2" htmlFor="range-member">
            센터멤버
          </label>
        </div>

        <div className="flex items-center">
          <input
            disabled={readOnly}
            id="range-retriever"
            type="checkbox"
            checked={isRetrieverChecked}
            onChange={e => setIsRetrieverChecked(e.target.checked)}
          />
          <label className="pl-2" htmlFor="range-retriever">
            회수매니저
          </label>
        </div>

        <div className="flex items-center">
          <input
            disabled={readOnly}
            id="range-factory"
            type="checkbox"
            checked={isFactoryCHecked}
            onChange={e => setIsFactoryCHecked(e.target.checked)}
          />
          <label className="pl-2" htmlFor="range-factory">
            세척센터
          </label>
        </div>
      </div>
    </div>
  )

  return { range, updateRange, renderPostRange }
}
