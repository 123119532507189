import { BsFillPlusSquareFill } from 'react-icons/bs'
import { Place } from 'types/Place'

interface Props {
  text: string
  openAddPage?: () => void
  place?: Place[]
  setPlace?: React.Dispatch<React.SetStateAction<Place[]>>
}

export const SubHeaderComponent = ({ text, openAddPage, place, setPlace }: Props) => {
  // const [searchText, setSearchText] = useState('')
  // const handleSubmit = (e: { preventDefault: () => void }) => {
  //   e.preventDefault()
  //   const re = new RegExp(searchText, 'gi') //g는 전역, i는 대소문자 구분없이
  //   const filterPack = place?.filter(searchText => searchText.name.match(re))
  //   if (!filterPack) return
  //   if (!setPlace) return
  //   setPlace(filterPack)
  // }
  return (
    <div className="flex items-center justify-between mxl:px-5 mxl:py-0 mxl:flex-col mxl:items-start gap-2 h-12 mb-2 mxl:h-[92px]">
      <h2 className="text-2xl">{text}</h2>
      <div className="flex gap-4 mxl:w-full">
        {/* <form className="relative flex justify-end gap-4 mxl:flex-grow" onSubmit={handleSubmit}>
          <input
            className="px-2 leading-10 border border-gray-300 border-solid rounded-md w-72 mxl:w-full"
            type="text"
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            placeholder="검색어"
          />
          <button
            className=" text-2xl mxl:w-[28px] absolute right-[5%] top-1/4 mxl:right-2"
            type="submit">
            <CgSearch />
          </button>
        </form> */}
        {openAddPage !== undefined && (
          <button
            className="rounded-md text-blue-600 text-[40px] hover:cursor-pointer hover:text-blue-700"
            onClick={openAddPage}>
            <BsFillPlusSquareFill />
          </button>
        )}
      </div>
    </div>
  )
}
