import { getPackStatusLabelText } from 'lib/helpers'
import { getPackStatusTextColor } from 'pages/Factory/FactoryPackPage'
import { useNavigate } from 'react-router-dom'
import { Pack } from 'types/Pack'

interface Props {
  packs: Pack[]
}

export const EarthingpackTableBoxComponent = ({ packs }: Props) => {
  const navigate = useNavigate()
  return (
    <table className="w-full border rounded mt-2 text-center">
      <thead className="border-t border-b">
        <tr className="bg-gray-100">
          {/* <th className="p-2">id</th> */}
          <th className="p-2">시리얼넘버</th>
          <th className="p-2">위치</th>
          <th className="p-2">상태</th>
        </tr>
      </thead>

      <tbody className="">
        {packs.length === 0 && (
          <tr className="">
            <td className="p-2" colSpan={2}>
              보유 중인 얼싱팩이 없습니다
            </td>
          </tr>
        )}

        {packs.map(item => {
          const statusLabelColor = getPackStatusTextColor(item)
          return (
            <tr
              className="hover:bg-gray-100 even:bg-gray-50"
              key={item.id}
              onClick={() => navigate(`/earthingpack/${item.id}`)}>
              {/* <td className="p-2">{item.id}</td> */}
              <td className="p-2">{item.serialNumber}</td>
              <td>
                {item.holder
                  ? `${item.holder.name}(${item.holder.roles[0].type})`
                  : item.place
                  ? `${item.place.name}(${item.place.type})`
                  : '-'}
              </td>
              <td className={`p-2 ${statusLabelColor}`}>{getPackStatusLabelText(item)}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
