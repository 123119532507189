import axios from 'api/axios'
import { useCallback, useEffect, useState } from 'react'
import { Pack } from 'types/Pack'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { EarthingpackTableBoxComponent } from './EarthingpackTableBoxComponent'
import { PackStatus } from 'pages/Factory/FactoryPackPage'
import { FilterRadio } from './StatusRadio'
import { Pagenator } from './Pagenator'
import { PAGENATION_PER_PAGE } from 'lib/constants'

export type PackQueryOptions = {
  page: string | number
  perPage: string | number
  status: string | undefined
}

export const EarthingpackPage = () => {
  const [packs, setPacks] = useState<Pack[]>([])
  const [searchParams] = useSearchParams()
  const [query, setQuery] = useState<PackQueryOptions>({
    page: 1,
    perPage: PAGENATION_PER_PAGE,
    status: undefined,
  })
  const navigate = useNavigate()

  useEffect(() => {
    setQuery({
      page: searchParams.get('page') ?? 1,
      perPage: searchParams.get('perPage') ?? PAGENATION_PER_PAGE,
      status: searchParams.get('status') ?? '',
    })
  }, [searchParams])

  const [packCount, setPackCount] = useState(0)

  const fetchPack = useCallback(async () => {
    try {
      const res = await axios.get<{ packs: [Pack[], number] }>(
        `/packs?page=${query.page}&perPage=${query.perPage}&status=${query.status}`,
      )
      setPacks(res.data.packs[0])
      setPackCount(res.data.packs[1])
    } catch (error) {
      console.log('not data', error)
    }
  }, [query])

  useEffect(() => {
    if (query) fetchPack()
  }, [query, fetchPack])

  return (
    <div className="w-full p-5">
      <div className="flex items-center justify-between">
        <h2 className="">얼싱팩</h2>

        <div className="flex justify-end gap-2 items-center">
          <div className="flex gap-2">
            <FilterRadio filter={undefined} />
            <FilterRadio filter={PackStatus.RECEIVED} />
            <FilterRadio filter={PackStatus.CLEANING} />
            <FilterRadio filter={PackStatus.REPAIRING} />
            <FilterRadio filter={PackStatus.DROPPED} />
            <FilterRadio filter={PackStatus.READY} />
            <FilterRadio filter={PackStatus.RECYCLING} />
          </div>

          <button
            className="px-2 py-1 bg-blue-600 text-white rounded hover:bg-blue-700"
            onClick={() => navigate('scan')}>
            스캔하기
          </button>
        </div>
      </div>

      <div className="mt-2">
        <EarthingpackTableBoxComponent packs={packs} />
        <Pagenator total={packCount} />
      </div>
    </div>
  )
}
