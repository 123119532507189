import axios from 'api/axios'
import { Order } from 'components/Order'
import { Places } from 'enums/Places'
import { PackStatus } from 'pages/Factory/FactoryPackPage'
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Place } from 'types/Place'

const initialOrder = {
  from: -1,
  to: -1,
  quantity: 0,
  message: '',
}

export default function OrderAddPage() {
  const navigate = useNavigate()
  const [factories, setFactories] = useState<Place[]>([])
  const [partners, setPartners] = useState<Place[]>([])
  const [order, setOrder] = useState(initialOrder)

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const _from = searchParams.get('from') ? Number(searchParams.get('from')) : -1
    const _to = searchParams.get('to') ? Number(searchParams.get('to')) : -1
    const _quantity = searchParams.get('quantity') ? Number(searchParams.get('quantity')) : 0

    setOrder(o => ({
      ...o,
      from: _from ? _from : -1,
      to: _to ? _to : -1,
      quantity: _quantity ? _quantity : 0,
    }))
  }, [searchParams])

  const fetchPlaces = async () => {
    try {
      const res = await axios.get<{ places: Place[] }>('/places')
      setFactories(res.data.places.filter(p => p.type === Places.FACTORY))
      setPartners(
        res.data.places.filter(p => [Places.HQ, Places.PARTNER].includes(p.type as Places)),
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPlaces()
  }, [])

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (order.from < -1 || order.to < -1 || order.quantity === 0)
      return alert('출발지, 도착지, 팩 수를 입력하세요')

    const orderDTO = {
      from: { id: order.from },
      to: { id: order.to },
      quantity: order.quantity,
      message: order.message,
    }

    try {
      await axios.post<{ order: Order }>('/order', orderDTO)
      navigate('/order')
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (e.target.name === 'message') {
        setOrder(o => ({ ...o, message: e.target.value }))
        return
      }

      Number(e.target.value) > 0
        ? searchParams.set(e.target.name, e.target.value)
        : searchParams.delete(e.target.name)

      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  return (
    <div className="w-full p-4">
      <div className="flex justify-between">
        <h3 className="">주문 추가</h3>

        <div className="">
          <button onClick={() => navigate('/order')}>주문목록</button>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="flex justify-between">
          <div className="flex-1">
            <label htmlFor="from" className="mr-2">
              출발지
            </label>

            <select
              className="block border border-gray-300 px-0.5 py-2 rounded"
              name="from"
              value={order.from}
              onChange={handleChange}
              id="from">
              <option value={-1}>출발지 선택</option>
              {factories.map(f => (
                <option value={f.id} key={f.id}>
                  {f.name}(팩 {f.packs.filter(p => p.status === PackStatus.READY).length}개 가능)
                </option>
              ))}
            </select>
          </div>

          <div className="flex-1">
            <label htmlFor="to" className="mr-2">
              도착지
            </label>

            <select
              value={order.to}
              onChange={handleChange}
              name="to"
              id="to"
              className="block border border-gray-300 px-0.5 py-2 rounded">
              <option value={-1}>목적지 선택</option>
              {partners.map(p => (
                <option value={p.id} key={p.id}>
                  {p.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="">
          <label htmlFor="to" className="mr-2">
            팩
          </label>
          <input
            className="w-full p-2 border rounded"
            min="0"
            type="number"
            name="quantity"
            id="quantity"
            value={order.quantity}
            onChange={handleChange}
          />
        </div>

        <div className="">
          <label htmlFor="to" className="mr-2">
            메시지
          </label>
          <input
            className="w-full p-2 border rounded"
            type="text"
            name="message"
            id="message"
            value={order.message}
            onChange={handleChange}
          />
        </div>

        <div className="flex justify-end mt-4">
          <button className="bg-red-500 text-white rounded px-3 py-1">완료</button>
        </div>
      </form>
    </div>
  )
}
