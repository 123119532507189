// import { Data, DataTableBox } from "components/DataTableBox";
import { useAppSelector } from 'store'
import {
  selectIsAdmin,
  selectIsChief,
  selectIsFactoryWorker,
  selectIsLoggedIn,
} from 'store/userSlice'
import { AuthPlaceHolderComponent } from 'components/AuthPlaceHolderComponent'
import { AdminMainPage } from './AdminMainPage'
import { FactoryMainPage } from './FactoryMainPage'

export const IndexMainPage = () => {
  // const pack = useAppSelector(state => state.pack)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const isAdmin = useAppSelector(selectIsAdmin)
  const isChief = useAppSelector(selectIsChief)
  const isFactoryWorker = useAppSelector(selectIsFactoryWorker)

  if (!isLoggedIn) return <AuthPlaceHolderComponent />
  if (isAdmin) return <AdminMainPage />
  if (isChief && isFactoryWorker) return <FactoryMainPage />

  return <div className="">fix me in IndexMainPage</div>
}
