import axios from 'api/axios'
import { useFactory } from 'hooks/useFactory'
import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useCallback, useEffect, useState } from 'react'
import { Transfer, Transfers, TransferStatus } from 'types/Transfer'

export default function FactoryReleases() {
  const [transfers, setTransfers] = useState<Transfer[]>([])
  const [idFilter, setIdFilter] = useState(-1)
  const [statusFilter, setStatusFilter] = useState<TransferStatus | undefined>()
  const [partners, setPartners] = useState<any[]>()

  const { factory } = useFactory()

  let filteredTransfer = idFilter < 0 ? transfers : transfers.filter(t => t.to.id === idFilter)
  filteredTransfer = !statusFilter
    ? filteredTransfer
    : filteredTransfer.filter(t => t.status === statusFilter)

  useEffect(() => {
    if (transfers.length === 0) return
    const placesToList = transfers.map(i => ({ id: i.to.id, name: i.to.name }))

    const filterPlace = placesToList.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i)
    setPartners(filterPlace)
  }, [transfers])

  const fetchTransfers = useCallback(async () => {
    try {
      const res = await axios.get<{ transfers: Transfer[] }>(`/transfer?factoryId=${factory.id}`)
      setTransfers(
        res.data.transfers.filter(t => [Transfers.MOVING, Transfers.ISSUING].includes(t.type)),
      )
    } catch (error) {
      console.log(error)
    }
  }, [factory.id])

  useEffect(() => {
    if (!factory) return

    fetchTransfers()
  }, [factory, fetchTransfers])

  return (
    <div className="">
      <div className="flex justify-between items-center">
        <h3 className="">출고내역</h3>

        <div className="flex gap-2 items-center">
          <div className="flex gap-2">
            <div className="">
              <input
                type="radio"
                name="statusFilter"
                id="allReleaseStatus"
                checked={!statusFilter}
                onChange={() => setStatusFilter(undefined)}
              />
              <label className="hover:cursor-pointer ml-1" htmlFor="allReleaseStatus">
                전체
              </label>
            </div>

            <div className="">
              <input
                type="radio"
                name="statusFilter"
                id="releaseCompleted"
                checked={statusFilter === TransferStatus.COMPLETED}
                onChange={() => setStatusFilter(TransferStatus.COMPLETED)}
              />
              <label className="hover:cursor-pointer ml-1" htmlFor="releaseCompleted">
                도착
              </label>
            </div>

            <div className="">
              <input
                type="radio"
                name="statusFilter"
                id="releaseStarted"
                checked={statusFilter === TransferStatus.STARTED}
                onChange={() => setStatusFilter(TransferStatus.STARTED)}
              />
              <label className="hover:cursor-pointer ml-1" htmlFor="releaseStarted">
                출고됨
              </label>
            </div>
          </div>

          <select
            className="border border-gray-300 px-0.5 py-2 rounded ml-2"
            onChange={e => setIdFilter(Number(e.target.value))}>
            <option value={-1}>목적지 필터</option>
            {partners?.map(p => (
              <option value={p.id} key={p.id}>
                {p.name}{' '}
              </option>
            ))}
          </select>
        </div>
      </div>

      <table className="w-full border rounded mt-2">
        <thead className="border-t border-b">
          <tr className="bg-gray-100">
            <th className="p-2 text-center">목적지</th>
            <th className="p-2 text-center">수량</th>
            <th className="p-2 text-center">작업자</th>
            <th className="p-2 text-center">수령인</th>
            <th className="p-2 text-center">일시</th>
            <th className="p-2 text-center">상태</th>
          </tr>
        </thead>

        <tbody className="w-full">
          {filteredTransfer.length === 0 && (
            <tr className="">
              <td colSpan={6} className="p-2 text-center">
                출고내역이 없습니다
              </td>
            </tr>
          )}

          {filteredTransfer.map(transfer => {
            return (
              <tr
                className="hover:bg-gray-100 hover:cursor-pointer even:bg-gray-50"
                key={transfer.id}>
                <td className="p-2 text-center">{transfer.to.name}</td>
                <td className="p-2 text-center">{transfer.packs.length}</td>
                <td className="p-2 text-center">{transfer.giver.name}</td>
                <td className="p-2 text-center">{transfer.receiver?.name || '미정'}</td>
                <td className="p-2 text-center">{formatDateString(transfer.createdAt)}</td>
                <td className="p-2 text-center">
                  {transfer.type === Transfers.MOVING &&
                  transfer.status === TransferStatus.COMPLETED
                    ? '도착'
                    : '출고됨'}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
