import axios from 'api/axios'
import PackListTable from 'components/PackListTable'
import {
  formatAddress,
  formatDateString,
  formatPhoneNumber,
} from 'pages/RetrivalManager/CentersPage'
import React, { useEffect, useState } from 'react'
import { IoMdList } from 'react-icons/io'
import { RiEditFill } from 'react-icons/ri'
import { useNavigate, useParams } from 'react-router-dom'
import { Place } from 'types/Place'

export const FactoryDetailsPage = () => {
  const navigate = useNavigate()
  const { factoryId } = useParams()
  const [factory, setFactory] = useState<Place>()

  useEffect(() => {
    if (!factoryId) return
    const factoryData = async () => {
      try {
        const res = await axios.get<{ place: Place }>(`places/${factoryId}`)
        const { place } = res.data
        setFactory(place)
      } catch (error) {
        console.log(error)
      }
    }
    factoryData()
  }, [factoryId])

  if (!factory) return null

  return (
    <div className="w-full p-5">
      <div className="flex items-center justify-between mb-2 h-12">
        <h2 className="">{factory.name}</h2>

        <div className="flex">
          <button
            className="flex items-center text-blue-600 border border-blue-600 hover:bg-blue-800 hover:border-blue-700 hover:text-white px-2 py-1 rounded"
            onClick={() => {
              navigate('edit')
            }}>
            <RiEditFill />
            수정
          </button>

          <button
            className="flex items-center border rounded border-gray-400 text-gray-500 px-2 py-1 ml-2 hover:text-white hover:bg-gray-400"
            onClick={() => {
              navigate('/factory')
            }}>
            <IoMdList />
            목록보기
          </button>
        </div>
      </div>

      <div className="flex w-full py-5 border-y border-gray-300">
        <dl className="flex flex-col justify-center pr-5">
          <dt className="font-bold">반납센터명</dt>
          <dd className="">{factory.name}</dd>
        </dl>

        <dl className="flex flex-col justify-center px-5">
          <dt className="font-bold">대표명</dt>
          <dd className="">{factory.roles[0]?.user?.name ? factory?.roles[0].user.name : '-'}</dd>
        </dl>

        <dl className="flex flex-col justify-center px-5">
          <dt className="font-bold">전화번호</dt>
          <dd className="">{formatPhoneNumber(factory.phoneNumber)}</dd>
        </dl>

        <dl className="flex flex-col justify-center px-5">
          <dt className="font-bold">주소</dt>
          <dd className="">{formatAddress(factory.address)}</dd>
        </dl>
        <dl className="flex flex-col justify-center px-5">
          <dt className="font-bold">가입일</dt>
          <dd className="">{formatDateString(factory.createdAt)}</dd>
        </dl>

        {factory.email && (
          <dl className="flex flex-col justify-center px-5">
            <dt className="font-bold">이메일</dt>
            <dd className="">{factory?.email ? factory?.email : '-'}</dd>
          </dl>
        )}
      </div>

      <div className="mt-5">
        <h3 className="">얼싱팩</h3>

        <PackListTable packs={factory?.packs} />
      </div>
    </div>
  )
}
