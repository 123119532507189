import { Roles } from 'enums/Roles'
import { useFactory } from 'hooks/useFactory'
import { useEffect } from 'react'
import { RiMailAddFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { formatDateString, formatPhoneNumber } from './CentersPage'

export const FactoryRetrieverList = () => {
  const navigate = useNavigate()

  const { factory, fetchFactory } = useFactory()
  const retrievers = factory?.roles.filter(r => r.type === Roles.RETRIEVER).map(r => r.user)

  useEffect(() => {
    fetchFactory()
  }, [fetchFactory])

  if (!retrievers) return null

  return (
    <div className="w-full p-5">
      <div className="flex justify-between items-start">
        <h2 className="">회수매니저</h2>

        <button
          className="flex gap-1 items-center rounded text-white bg-teal-500 px-4 py-1 text-sm"
          onClick={() => navigate('/retrievers/add')}>
          <RiMailAddFill className="text-sm" /> 새 회수매니저 초대
        </button>
      </div>

      <table className="w-full border rounded mt-2">
        <thead className="border-t border-b">
          <tr className="bg-gray-100">
            <th className="p-2 text-center">이름</th>
            <th className="p-2 text-center">전화번호</th>
            <th className="p-2 text-center">회수팩 개수</th>
            <th className="p-2 text-center">가입일</th>
          </tr>
        </thead>

        <tbody className="w-full text-center">
          {retrievers.length === 0 && (
            <tr className="">
              <td colSpan={6} className="p-2 text-center">
                출고주문이 없습니다
              </td>
            </tr>
          )}

          {retrievers.map(r => (
            <tr
              className="hover:bg-gray-100 hover:cursor-pointer even:bg-gray-50"
              key={r.id}
              onClick={() => navigate(`/retrievers/${r.id}`)}>
              <td className="p-2 text-center">{r.name}</td>
              <td className="p-2 text-center">{formatPhoneNumber(r.phoneNumber)}</td>
              <td className="p-2 text-center">{r.packs.length} 개</td>
              <td className="p-2 text-center">{formatDateString(r.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
