import IssueScanner from 'components/IssueScanner'
import PackStatusScanner from 'components/PackStatusScanner'
import { useFactory } from 'hooks/useFactory'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ReceiveScanner } from '../../components/ReceiveScanner'
import { ScanModeButton } from '../../components/ScanModeButton'

export type ScanMode =
  | 'none'
  | 'register'
  | 'receive'
  | 'clean'
  | 'repair'
  | 'drop'
  | 'ready'
  | 'release'

export const FactoryPackScanPage = () => {
  const [mode, setMode] = useState<ScanMode>('none')
  const [searchParams, setSearchParams] = useSearchParams()
  const { fetchFactory } = useFactory()

  useEffect(() => {
    fetchFactory()
  }, [fetchFactory])

  useEffect(() => {
    setMode((searchParams.get('mode') as ScanMode) ?? 'none')
  }, [searchParams])

  const setQuery = (targetMode: string) => {
    searchParams.delete('orderId')
    targetMode === mode ? searchParams.delete('mode') : searchParams.set('mode', targetMode)
    setSearchParams(searchParams)
  }

  return (
    <div className="w-full p-5">
      <div className="flex items-center justify-center gap-10">
        <div className=" overflow-hidden rounded-full bg-slate-200">
          <ScanModeButton
            mode="receive"
            selectedMode={mode}
            onClick={() => setQuery('receive')}
            label="입고"
          />
          <ScanModeButton
            mode="clean"
            selectedMode={mode}
            onClick={() => setQuery('clean')}
            label="세척"
          />
          <ScanModeButton
            mode="repair"
            selectedMode={mode}
            onClick={() => setQuery('repair')}
            label="수리"
          />
          <ScanModeButton
            mode="drop"
            selectedMode={mode}
            onClick={() => setQuery('drop')}
            label="폐기"
          />
          <ScanModeButton
            mode="ready"
            selectedMode={mode}
            onClick={() => setQuery('ready')}
            label="준비완료"
          />
          <ScanModeButton
            mode="release"
            selectedMode={mode}
            onClick={() => setQuery('release')}
            label="출고"
          />
        </div>
      </div>

      {mode === 'receive' && <ReceiveScanner mode={mode} />}
      {mode === 'clean' && <PackStatusScanner mode={mode} />}
      {mode === 'repair' && <PackStatusScanner mode={mode} />}
      {mode === 'drop' && <PackStatusScanner mode={mode} />}
      {mode === 'ready' && <PackStatusScanner mode={mode} />}
      {mode === 'release' && <IssueScanner mode={mode} />}
    </div>
  )
}
