import axios from 'api/axios'
import { useHQ } from 'hooks/useHQ'
import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Transfer, Transfers, TransferStatus } from 'types/Transfer'
import { FilterRadio } from './StatusRadio'

function AdminReciveList() {
  const [issueList, setIssueList] = useState<Transfer[]>([])
  const [filterIssue, setFilterIssue] = useState<Transfer[]>([])
  const { hq } = useHQ()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const status = searchParams.get('status')
    !status
      ? setFilterIssue([...issueList])
      : setFilterIssue(issueList.filter(t => t.status === status))
  }, [issueList, searchParams])

  useEffect(() => {
    const getIssueList = async () => {
      const res = await axios.get<{ transfers: Transfer[] }>(
        `/transfer?type=${Transfers.MOVING}&hqId=${hq?.id}`,
      )
      setIssueList(res.data.transfers)
    }
    getIssueList()
  }, [hq])

  return (
    <div className="w-full p-5">
      <div className="flex flex-1 justify-between">
        <h2>입고 내역</h2>

        <div className="flex gap-2">
          <FilterRadio isIssue={true} filter={undefined} />
          <FilterRadio isIssue={true} filter={TransferStatus.COMPLETED} />
          <FilterRadio isIssue={true} filter={TransferStatus.STARTED} />
        </div>
      </div>

      <table className="w-full border rounded mt-2">
        <thead className="border-t border-b">
          <tr className="bg-gray-100">
            <th className="p-2 text-center">반냅센터</th>
            <th className="p-2 text-center">수량</th>
            <th className="p-2 text-center">수령인</th>
            <th className="p-2 text-center">일시</th>
            <th className="p-2 text-center">상태</th>
          </tr>
        </thead>

        <tbody className="w-full">
          {issueList.length === 0 && (
            <tr className="">
              <td colSpan={6} className="p-2 text-center">
                입고내역이 없습니다
              </td>
            </tr>
          )}

          {filterIssue.map(transfer => {
            return (
              <tr
                className="hover:bg-gray-100 hover:cursor-pointer even:bg-gray-50"
                key={transfer.id}>
                <td className="p-2 text-center">{transfer.from?.name}</td>
                <td className="p-2 text-center">{transfer.packs.length}</td>
                <td className="p-2 text-center">{transfer.receiver?.name || '미정'}</td>
                <td className="p-2 text-center">{formatDateString(transfer.createdAt)}</td>
                <td className="p-2 text-center">
                  {transfer.type === Transfers.MOVING &&
                  transfer.status === TransferStatus.COMPLETED ? (
                    <p className="text-red-400">입고완료</p>
                  ) : (
                    <p>출고됨</p>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default AdminReciveList
