import axios from 'api/axios'
import { Places } from 'enums/Places'
import { Roles } from 'enums/Roles'
import { PackStatus } from 'pages/Factory/FactoryPackPage'
import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Pack } from 'types/Pack'
import { Place } from 'types/Place'
import { User } from 'types/User'

export const AdminMainPage = () => {
  const [centers, setCenters] = useState<Place[]>([])
  const [factorys, setFactorys] = useState<Place[]>([])
  const [partners, setPartners] = useState<Place[]>([])
  const [packs, setPacks] = useState<Pack[]>([])
  const [users, setUsers] = useState<User[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    const fatchCenter = async () => {
      try {
        const center = await axios.get<{ places: Place[] }>(`/places?type=${Places.CENTER}`)
        const pack = await axios.get<{ packs: Pack[] }>('/packs')
        const user = await axios.get<{ users: User[] }>('/users')
        const factory = await axios.get<{ places: Place[] }>(`/places?type=${Places.FACTORY}`)
        const partner = await axios.get<{ places: Place[] }>(`/places?type=${Places.PARTNER}`)
        setCenters(center.data.places)
        setPacks(pack.data.packs)
        setUsers(user.data.users)
        setFactorys(factory.data.places)
        setPartners(partner.data.places)
      } catch (error) {
        console.log(error)
      }
    }
    fatchCenter()
  }, [])

  return (
    <div className="w-full p-5">
      <div className="flex justify-between items-center">
        <h1 className="text-5xl font-extrabold leading-none tracking-tight">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-emerald-700 to-lime-600">
            얼싱팩 관리자
          </span>
        </h1>

        <div className="text-2xl font-extrabold leading-none tracking-tight">
          <span className="bg-clip-text text-transparent bg-gradient-to-l to-lime-600 from-emerald-700">
            {formatDateString(new Date().toDateString())}
          </span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 content-start gap-5 mt-5">
        <div className="border border-slate-600 rounded p-5">
          <h3
            className="text-lg pb-2 font-bold hover:cursor-pointer"
            onClick={() => navigate('/earthingpack')}>
            얼싱팩
          </h3>

          <div className="grid grid-cols-3 gap-2 mt-2">
            <div className="hover:cursor-pointer" onClick={() => navigate('/earthingpack')}>
              <div className="">전체</div>
              <div className="text-lg  font-semibold">{packs.length}</div>
            </div>

            <div
              className="hover:cursor-pointer"
              onClick={() => navigate('/earthingpack?status=recycling')}>
              <div className="">순환중</div>
              <div className="text-lg  font-semibold">
                {packs.filter(p => p.status === PackStatus.RECYCLING).length}
              </div>
            </div>

            <div
              className="hover:cursor-pointer"
              onClick={() => navigate('/earthingpack?status=received')}>
              <div className="">입고됨</div>
              <div className="text-lg  font-semibold">
                {packs.filter(pack => pack.status === 'received').length}
              </div>
            </div>

            <div
              className="hover:cursor-pointer"
              onClick={() => navigate('/earthingpack?status=cleaning')}>
              <div className="">세척중</div>
              <div className="text-lg  font-semibold">
                {packs.filter(pack => pack.status === 'cleaning').length}
              </div>
            </div>

            <div
              className="hover:cursor-pointer"
              onClick={() => navigate('/earthingpack?status=repairing')}>
              <div className="">수리중</div>
              <div className="text-lg  font-semibold">
                {packs.filter(pack => pack.status === 'repairing').length}
              </div>
            </div>

            <div
              className="hover:cursor-pointer"
              onClick={() => navigate('/earthingpack?status=dropped')}>
              <div className="">폐기됨</div>
              <div className="text-lg  font-semibold">
                {packs.filter(pack => pack.status === 'dropped').length}
              </div>
            </div>

            <div
              className="hover:cursor-pointer"
              onClick={() => navigate('/earthingpack?status=ready')}>
              <div className="">대기중</div>
              <div className="text-lg  font-semibold">
                {packs.filter(pack => pack.status === 'ready').length}
              </div>
            </div>
          </div>
        </div>

        <div className="border border-slate-600 rounded p-5">
          <h3
            className="text-lg pb-2 font-bold hover:cursor-pointer"
            onClick={() => navigate('/center')}>
            반납센터
          </h3>

          <div className="grid grid-cols-3 gap-2 mt-2">
            <div className=" hover:cursor-pointer" onClick={() => navigate('/center')}>
              <div className="">전체</div>
              <div className="text-lg  font-semibold">{centers.length}</div>
            </div>

            <div className="flex-1">
              <div
                className="  hover:cursor-pointer"
                onClick={() => navigate('/center?status=waiting')}>
                승인대기
              </div>
              <div className="text-lg  font-semibold">
                {centers.filter(center => !center.isValid).length}
              </div>
            </div>
          </div>
        </div>

        <div className="border border-slate-600 rounded p-5">
          <h3 className="text-lg pb-2 font-bold">이용자</h3>

          <div className="grid grid-cols-3 gap-2 mt-2">
            <div className="hover:cursor-pointer" onClick={() => navigate('/client')}>
              <div className="">고객</div>
              <div className="text-lg  font-semibold">
                {users.filter(user => user.roles.find(r => r.type === Roles.CONSUMER)).length}
              </div>
            </div>

            <div className="hover:cursor-pointer" onClick={() => navigate('/factory')}>
              <div className="">세척센터</div>
              <div className="text-lg  font-semibold">{factorys.length}</div>
            </div>

            <div className="hover:cursor-pointer" onClick={() => navigate('/partner')}>
              <div className="">파트너</div>
              <div className="text-lg  font-semibold">{partners.length}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
