import { useSearchParams } from 'react-router-dom'
import { PackStatus } from 'pages/Factory/FactoryPackPage'
import { useEffect, useState } from 'react'
import { TransferStatus } from 'types/Transfer'
import {
  getIssueingStatusLabelText,
  getPackStatusLabelText,
  getTransferLabelText,
} from 'lib/helpers'

export function FilterRadio({
  isIssue,
  filter,
}: {
  isIssue?: boolean
  filter: PackStatus | TransferStatus | 'missing' | undefined
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [status, setStatus] = useState<string>()
  const checked = status === filter
  const id = filter || 'all'

  const isPack = Object.values(PackStatus).includes(filter as PackStatus)

  useEffect(() => {
    setStatus(searchParams.get('status') ?? undefined)
  }, [searchParams])

  return (
    <div className="text-center">
      <input
        type="radio"
        id={id}
        checked={checked}
        onChange={() => {
          filter ? searchParams.set('status', filter) : searchParams.delete('status')
          searchParams.delete('page')
          setSearchParams(searchParams)
        }}
      />
      <label className="hover:cursor-pointer block sm:inline-block sm:pl-1" htmlFor={id}>
        {isPack
          ? getPackStatusLabelText(filter as PackStatus)
          : isIssue
          ? getIssueingStatusLabelText(filter as TransferStatus)
          : getTransferLabelText(filter as TransferStatus)}
      </label>
    </div>
  )
}
