import axios from 'api/axios'
import { useFactory } from 'hooks/useFactory'
import {
  formatAddress,
  formatDateString,
  formatPhoneNumber,
} from 'pages/RetrivalManager/CentersPage'
import { useCallback, useEffect, useState } from 'react'
import { RiEditFill } from 'react-icons/ri'
import { Link, useNavigate } from 'react-router-dom'
import { useAppSelector } from 'store'
import { Post } from 'types/Post'

export const FactoryMainPage = () => {
  const navigate = useNavigate()
  const user = useAppSelector(state => state.user)
  const { factory } = useFactory()
  const [notice, setNotice] = useState<Post[]>()

  const getNotice = useCallback(async () => {
    try {
      const res = await axios.get(`/post?type=notice`)
      setNotice(
        res.data.posts
          .filter((post: Post) => post.range.findIndex(range => range === 'factory') !== -1)
          .reverse()
          .slice(0, 5),
      )
    } catch (error) {
      console.log(error)
    } finally {
    }
  }, [])

  useEffect(() => {
    getNotice()
  }, [getNotice])

  if (!factory) return null

  return (
    <div className="w-full p-5">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl inline-block mr-4">{factory.name}</h2>

        <button
          className="flex items-center text-blue-600 border border-blue-600 hover:bg-blue-800 hover:border-blue-700 hover:text-white px-2 py-1 rounded"
          onClick={() => {
            navigate('factory/edit')
          }}>
          <RiEditFill />
          수정
        </button>
      </div>

      <div className="flex gap-5 p-5 border items-start border-gray-300 mt-5">
        <dl className="">
          <dt className="font-bold">전화번호</dt>
          <dd className="">{formatPhoneNumber(factory.phoneNumber)}</dd>
        </dl>

        <dl className="">
          <dt className="font-bold">주소</dt>
          <dd className="">{formatAddress(factory.address)}</dd>
        </dl>

        <dl className="">
          <dt className="font-bold">담당자</dt>
          <dd className="">{user.name ? user.name : '-'}</dd>
        </dl>

        <dl className="">
          <dt className="font-bold">이메일</dt>
          <dd className="">{factory.email ? factory.email : '-'}</dd>
        </dl>

        <dl className="">
          <dt className="font-bold">얼싱팩</dt>
          <dd className="">{factory.packs.length}</dd>
        </dl>
      </div>

      <div className="">
        <h2 className="inline-block mr-4 mt-5">공지</h2>

        <div className="rounded border-2 mt-2 ">
          {notice?.length ? (
            notice.map(post => (
              <div className="" key={post.id}>
                <Link
                  to={`/setting/notice/${post.id}`}
                  className="flex-1 px-5 py-2 even:bg-gray-400 flex justify-between items-center">
                  <span>{post.title}</span>
                  <span className="text-gray-400 text-sm">{formatDateString(post.createdAt)}</span>
                </Link>
              </div>
            ))
          ) : (
            <div className="p-5">
              <p>공지사항이 없습니다.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
