import axios from 'api/axios'
import { Posts } from 'enums/Posts'
import { useCallback, useMemo, useRef } from 'react'
import ReactQuill from 'react-quill'
import { makeId } from '../lib/makeId'
import 'react-quill/dist/quill.snow.css'

type QuillEditorProps = {
  type: Posts
  body: string
  setBody: React.Dispatch<React.SetStateAction<string>>
}

export function QuillEditorComponent({ type, body, setBody }: QuillEditorProps) {
  const quillRef = useRef<ReactQuill>(null)

  const imageHandler = useCallback(() => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.addEventListener('change', async () => {
      if (!input.files) return
      const file = input.files[0]
      const prefix = type
      const fileName = makeId(10) + '.' + file.name.split('.')[1]
      try {
        const presignedUrl = await axios.post('/upload/presigned-url', { prefix, fileName })
        await fetch(
          new Request(presignedUrl.data.url, {
            method: 'PUT',
            body: file,
            headers: new Headers({
              'Content-Type': file.type,
            }),
          }),
        )

        const editor = quillRef.current?.getEditor()
        const range = editor?.getSelection()
        if (!range) return

        editor?.insertEmbed(
          range.index,
          'image',
          `https://earthingpack-manager.s3.ap-northeast-2.amazonaws.com/${prefix}/${fileName}`,
        )
      } catch (error) {
        console.log('quill image update failed: ', error)
      }
    })
    input.click()
  }, [type])

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [[{ header: [1, 2, 3, false] }, 'bold', 'underline', 'image', 'link']],
        handlers: {
          image: imageHandler,
        },
      },
    }
  }, [imageHandler])

  const formats = useMemo(() => ['header', 'bold', 'underline', 'image', 'link'], [])

  return (
    <ReactQuill
      placeholder="본문"
      ref={quillRef}
      theme="snow"
      value={body}
      onChange={setBody}
      modules={modules}
      formats={formats}
      className="h-full"
    />
  )
}
