import axios from 'api/axios'
import { PostCodeComponent } from 'components/PostCodeComponent'
import { Places } from 'enums/Places'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Place } from 'types/Place'

export const PartnerAddPage = () => {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [ownerName, setOwnerName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [address, setAddress] = useState('')
  const [roadAddress, setRoadAddress] = useState('')
  const [extraAddress, setExtraAddress] = useState('')
  const [email, setEmail] = useState('')
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const addPartnerData = async () => {
    console.log(email)
    if (!name) return alert('파트너명')
    if (!phoneNumber) return alert('전화번호')
    if (!(address && roadAddress)) return alert('주소')
    if (!ownerName) return alert('담당자명')

    try {
      const res = await axios.post<{ partner: Place }>('/places/partner/add', {
        name,
        ownerName,
        phoneNumber,
        address: { address, roadAddress, extraAddress },
        type: Places.PARTNER,
        email: email,
      })
      navigate(`/partner/${res.data.partner.id}`)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="flex p-5 flex-1 justify-center gap-10 ">
      <div className="flex gap-5 flex-col mxl:border-0 mxl:px-0 w-full">
        <h2 className="flex justify-between items-center border-b border-solid border-gray-300 mxl:p-2 h-12 text-2xl">
          추가하기
        </h2>
        <div className="border border-solid border-gray-300">
          <dl className="flex flex-wrap">
            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 flex items-center border-r border-solid border-gray-300 mxl:w-[35%] font-bold">
              파트너명
            </dt>
            <dd className=" py-2 px-5 w-5/6 flex items-center mxl:w-[65%]">
              <input
                type="text"
                className="leading-10 border border-solid border-gray-300 w-full px-2"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="파트너명"
              />
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold">
              전화번호
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 border-solid border-gray-300 flex items-center mxl:w-[65%]">
              <input
                type="text"
                className=" leading-10 border border-solid border-gray-300 w-full px-2"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                placeholder="전화번호"
              />
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold">
              주소
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 border-solid border-gray-300 flex mxl:w-[65%] gap-2 flex-col">
              <div className="flex gap-2">
                <input
                  type="text"
                  className=" leading-10 border border-solid border-gray-300 w-full mxl:w-1/2 px-2"
                  value={address}
                  onChange={() => {}}
                  onClick={() => setIsPopupOpen(true)}
                  placeholder="주소"
                />
                <button
                  className="w-1/5 bg-gray-100 border border-gray-300 border-solid mxl:w-1/2 hover:bg-gray-300"
                  onClick={() => setIsPopupOpen(true)}>
                  주소찾기
                </button>
              </div>
              <input
                type="text"
                className=" leading-10 border border-solid border-gray-300 w-full px-2"
                value={extraAddress}
                onChange={e => setExtraAddress(e.target.value)}
                placeholder="상세주소"
              />
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold">
              이메일
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 border-solid border-gray-300 flex mxl:w-[65%] gap-2 flex-col">
              <input
                type="text"
                className=" leading-10 border border-solid border-gray-300 w-full px-2"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="이메일"
              />
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold">
              담당자
            </dt>
            <dd className=" py-2 px-5 w-5/6 border-t border-solid border-gray-300 flex items-center mxl:w-[65%] leading-10">
              <input
                type="text"
                className=" leading-10 border border-solid border-gray-300 w-full px-2"
                value={ownerName}
                onChange={e => setOwnerName(e.target.value)}
                placeholder="담당자 명"
              />
            </dd>
          </dl>
        </div>
        <div className="flex items-center justify-center gap-3 mt-5 mxl:flex-col">
          <button
            className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-blue-600 text-white hover:bg-blue-800"
            onClick={() => addPartnerData()}>
            추가
          </button>
          <button
            className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-slate-200 text-gray-500 hover:bg-gray-400 hover:text-white"
            onClick={() => navigate(-1)}>
            취소
          </button>
        </div>
      </div>
      {isPopupOpen && (
        <PostCodeComponent
          setAddress={setAddress}
          setRoadAdress={setRoadAddress}
          setIsPopupOpen={setIsPopupOpen}
        />
      )}
    </div>
  )
}
