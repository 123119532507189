import { useFactory } from 'hooks/useFactory'
import { getPackStatusLabelText } from 'lib/helpers'
import { FilterRadio } from 'pages/Earthingpack/StatusRadio'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Pack } from 'types/Pack'

export enum PackStatus {
  RECEIVED = 'received',
  CLEANING = 'cleaning',
  REPAIRING = 'repairing',
  DROPPED = 'dropped',
  READY = 'ready',
  RECYCLING = 'recycling',
}

export const FactoryPackPage = () => {
  const [status, setStatus] = useState<PackStatus>()
  const [searchParams] = useSearchParams()
  const { factory, fetchFactory } = useFactory()
  const filteredPacks = status ? factory.packs.filter(p => p.status === status) : factory.packs

  useEffect(() => {
    fetchFactory()
  }, [fetchFactory])

  useEffect(() => {
    const status = searchParams.get('status') as PackStatus
    setStatus(status ?? undefined)
  }, [searchParams])

  return (
    <div className="w-full p-5">
      <div className="flex justify-between">
        <h2 className="">
          보유 얼싱팩{filteredPacks.length > 0 ? ` ${filteredPacks.length}개` : ''}
        </h2>

        <div className="flex gap-2">
          <FilterRadio filter={undefined} />
          <FilterRadio filter={PackStatus.RECEIVED} />
          <FilterRadio filter={PackStatus.CLEANING} />
          <FilterRadio filter={PackStatus.REPAIRING} />
          <FilterRadio filter={PackStatus.DROPPED} />
          <FilterRadio filter={PackStatus.READY} />
        </div>
      </div>

      <table className="w-full border rounded mt-2 text-center">
        <thead className="border-t border-b">
          <tr className="bg-gray-100">
            <th className="p-2">시리얼넘버</th>
            <th className="p-2">상태</th>
          </tr>
        </thead>

        <tbody className="">
          {filteredPacks.length === 0 && (
            <tr className="">
              <td className="p-2" colSpan={2}>
                보유 중인 얼싱팩이 없습니다
              </td>
            </tr>
          )}

          {filteredPacks.map(pack => {
            const statusLabelColor = getPackStatusTextColor(pack)
            return (
              <tr className="hover:bg-gray-100 even:bg-gray-50" key={pack.id}>
                <td className="p-2">{pack.serialNumber}</td>
                <td className={`p-2 ${statusLabelColor}`}>{getPackStatusLabelText(pack)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export const getPackStatusTextColor = (pack: Pack) => {
  return pack.status === 'received'
    ? 'text-gray-700'
    : pack.status === 'cleaning'
    ? 'text-blue-500'
    : pack.status === 'repairing'
    ? 'text-yellow-600'
    : pack.status === 'dropped'
    ? 'text-red-500'
    : pack.status === 'ready'
    ? 'text-green-500'
    : 'text-black'
}
