import axios from 'api/axios'
import { Roles } from 'enums/Roles'
import { formatDateString, formatPhoneNumber } from 'pages/RetrivalManager/CentersPage'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { User } from 'types/User'

export const ClientPage = () => {
  const navigate = useNavigate()
  const [users, setUsers] = useState<User[]>([])

  const openClientDetails = (clientId: number) => {
    navigate(`${clientId}`)
  }

  useEffect(() => {
    const fatchUsers = async () => {
      try {
        const res = await axios.get<{ users: User[] }>('/users')
        setUsers(res.data.users.filter(u => u.roles.find(r => r.type === Roles.CONSUMER)))
      } catch (error) {
        console.log(error)
      }
    }
    fatchUsers()
  }, [])

  return (
    <div className="w-full p-5">
      <h2 className="">고객</h2>

      <table className="w-full border rounded mt-2 text-center">
        <thead className="border-t border-b">
          <tr className="bg-gray-100">
            <th className="p-2">이름</th>
            <th className="p-2">휴대폰번호</th>
            <th className="p-2">로그인타입</th>
            <th className="p-2">포인트</th>
            <th className="p-2">가입일</th>
          </tr>
        </thead>

        <tbody className="">
          {users.length === 0 && (
            <tr className="">
              <td className="p-2" colSpan={2}>
                가입한 고객이 없습니다.
              </td>
            </tr>
          )}

          {users.map(user => (
            <tr
              className="hover:bg-gray-100 even:bg-gray-50"
              key={user.id}
              onClick={() => openClientDetails(user.id)}>
              <td className="p-2">{user.name || '-'}</td>
              <td className="p-2">{formatPhoneNumber(user.phoneNumber)}</td>
              <td className="p-2 uppercase">{user.snsType || '-'}</td>
              <td className="p-2">{user.points}</td>
              <td className="p-2">{formatDateString(user.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
