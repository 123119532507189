import axios from 'api/axios'
import dayjs from 'dayjs'
import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useCallback, useEffect, useState } from 'react'
import { IoMdList } from 'react-icons/io'
import { useNavigate, useParams } from 'react-router-dom'
import { Coupon } from 'types/Coupon'
import { Transfer } from 'types/Transfer'
import { User } from 'types/User'
import { ClientPointTableComponent } from './ClientPointTableComponent'

export const ClientDetailsPage = () => {
  const navigate = useNavigate()
  const { clientId } = useParams()
  const [client, setClient] = useState<User>()
  const [isActive, setIsActive] = useState(0)
  const [history, setHistory] = useState<any[]>([])

  useEffect(() => {
    if (!clientId) return
    const factoryUser = async () => {
      try {
        const res = await axios.get<{ user: User }>(`/users/${clientId}`)

        setClient(res.data.user)
      } catch (error) {
        console.log(error)
      }
    }
    factoryUser()
  }, [clientId])

  const getSave = useCallback(async () => {
    try {
      const res = await axios.get<{ transfers: Transfer[] }>(
        `/transfer?type=earthing&consumerId=${clientId}`,
      )
      const filterTransfer = res.data.transfers.filter((t: Transfer) => t.status === 'completed')
      const historyData = filterTransfer.map(item => ({
        title: item.to.name,
        createdAt: item.createdAt,
        point: item.packs.length,
        type: 'save',
      }))

      setHistory(historyData)
    } catch (error) {
      console.log(error)
    }
  }, [clientId])

  const getUse = useCallback(async () => {
    try {
      const res = await axios.get<{ coupons: Coupon[] }>(`/coupon/${clientId}`)
      const couponData = res.data.coupons.map(item => ({
        title: item.reward.title,
        createdAt: item.createdAt,
        point: item.reward.point,
        type: 'use',
      }))
      setHistory(couponData)
    } catch (error) {
      console.log(error)
    }
  }, [clientId])

  const getHistory = useCallback(async () => {
    try {
      const res = await axios.get<{ transfers: Transfer[] }>(
        `/transfer?type=earthing&consumerId=${clientId}`,
      )
      const filterTransfer = res.data.transfers.filter((t: Transfer) => t.status === 'completed')

      const historyData = filterTransfer.map(item => ({
        title: item.to.name,
        createdAt: item.createdAt,
        point: item.packs.length,
        type: 'save',
      }))

      const getCoupon = await axios.get<{ coupons: Coupon[] }>(`/coupon/${clientId}`)
      const couponData = getCoupon.data.coupons.map(item => ({
        title: item.reward.title,
        createdAt: item.createdAt,
        point: item.reward.point,
        type: 'use',
      }))

      setHistory(
        [...historyData, ...couponData].sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt))),
      )
    } catch (error) {
      console.log(error)
    }
  }, [clientId])

  useEffect(() => {
    isActive === 0 && getHistory()
    isActive === 1 && getSave()
    isActive === 2 && getUse()
  }, [getHistory, getSave, getUse, isActive])

  return (
    <div className="w-full p-5">
      <div className="flex items-center justify-between mb-2 h-12">
        <h2 className="text-2xl">{client?.name ? client.name : '-'}</h2>
        <button
          className="flex items-center border rounded border-gray-400 text-gray-500 px-2 py-1 ml-2 hover:text-white hover:bg-gray-400"
          onClick={() => {
            navigate('/client')
          }}>
          <IoMdList />
          목록보기
        </button>
      </div>

      <div className="flex w-full py-5 border-y border-gray-300 h-28">
        <dl className="flex flex-col justify-center pr-5">
          <dt className="font-bold text-cent">이름</dt>
          <dd className="">{client ? client.name : '-'}</dd>
        </dl>
        <dl className="flex flex-col justify-center px-5">
          <dt className="font-bold text-cent">전화번호</dt>
          <dd className="">
            {client?.phoneNumber
              ? client.phoneNumber.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
              : '-'}
          </dd>
        </dl>
        <dl className="flex flex-col justify-center px-5">
          <dt className="font-bold">로그인 타입</dt>
          <dd className="uppercase">{client ? client.snsType : '-'}</dd>
        </dl>
        <dl className="flex flex-col justify-center px-5">
          <dt className="font-bold">포인트</dt>
          <dd className="">{client?.points} P</dd>
        </dl>
        <dl className="flex flex-col justify-center px-5">
          <dt className="font-bold">가입일</dt>
          <dd className="">{client ? formatDateString(client.createdAt) : '-'}</dd>
        </dl>
      </div>

      <div className=" mxl:p-0 mxl:py-2 flex flex-col mt-5">
        <div className="relative flex items-center justify-center px-5 gap-6 w-full">
          <button
            className={`text-xl w-auto rounded-md py-3 px-5 cursor-pointer relative bottom-[-1px] left-[-2px] ${
              isActive === 0
                ? ` bg-blue-600 hover:bg-blue-800 text-white`
                : ` bg-gray-300 hover:bg-gray-500 text-gray-500 hover:text-white`
            }`}
            onClick={() => setIsActive(0)}>
            전체보기
          </button>
          <button
            className={`text-xl w-auto rounded-md py-3 px-5 cursor-pointer relative bottom-[-1px] left-[-2px] ${
              isActive === 1
                ? ` bg-blue-600 hover:bg-blue-800 text-white`
                : ` bg-gray-300 hover:bg-gray-500 text-gray-500 hover:text-white`
            }`}
            onClick={() => setIsActive(1)}>
            적립내역
          </button>
          <button
            className={`text-xl w-auto rounded-md py-3 px-5 cursor-pointer relative bottom-[-1px] left-[-2px] ${
              isActive === 2
                ? ` bg-blue-600 hover:bg-blue-800 text-white`
                : ` bg-gray-300 hover:bg-gray-500 text-gray-500 hover:text-white`
            }`}
            onClick={() => setIsActive(2)}>
            사용내역
          </button>
        </div>
        <ClientPointTableComponent data={history} />
      </div>
    </div>
  )
}
