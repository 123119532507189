import axios from 'api/axios'
import { OrderStatus } from 'components/OrderStatus'
import { Order } from 'components/Order'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDateString } from 'pages/RetrivalManager/CentersPage'

export default function OrdersPage() {
  const navigate = useNavigate()
  const [orders, setOrders] = useState<Order[]>([])

  const fetchOrders = async () => {
    try {
      const res = await axios.get<{ orders: Order[] }>('/order')
      setOrders(res.data.orders)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchOrders()
  }, [])

  const removeOrder = async (id: number) => {
    try {
      await axios.delete(`/order/${id}`)
      setOrders(orders.filter(o => o.id !== id))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="w-full p-5">
      <div className="flex justify-between">
        <h3>주문 목록</h3>

        <div className="">
          <button className=" bg-teal-500 text-white px-2 rounded" onClick={() => navigate(`add`)}>
            새 주문 작성
          </button>
        </div>
      </div>

      <table className="w-full border rounded mt-2">
        <thead className="border-t border-b">
          <tr className="bg-gray-100">
            <th className="p-2 text-center">출발</th>
            <th className="p-2 text-center">도착</th>
            <th className="p-2 text-center">수량</th>
            <th className="p-2 text-center">의뢰인</th>
            <th className="p-2 text-center">일시</th>
            <th className="p-2 text-center">상태</th>
            <th className="p-2 text-center">액션</th>
          </tr>
        </thead>
        <tbody className="">
          {orders.map(o => {
            const isPending = o.status === OrderStatus.PENDING
            return (
              <tr className="hover:bg-gray-50 hover:cursor-pointer even:bg-gray-50" key={o.id}>
                <td className="p-2 text-center">{o.from.name}</td>
                <td className="p-2 text-center">{o.to.name}</td>
                <td className="p-2 text-center">{o.quantity}</td>
                <td className="p-2 text-center">{o.orderer.name}</td>
                <td className="p-2 text-center">{formatDateString(o.createdAt)}</td>
                <td className="p-2 text-center">{o.status}</td>
                <td className="p-2 text-center">
                  {/* <button
                    disabled={!isPending}
                    className={`bg-gray-500 text-white px-2 rounded ${
                      isPending ? 'bg-yellow-500' : 'hidden'
                    }`}>
                    수정
                  </button> */}
                  <button
                    onClick={() => removeOrder(o.id)}
                    disabled={!isPending}
                    className={` text-white px-2 rounded ml-2 ${
                      isPending ? 'bg-red-500' : 'bg-gray-100'
                    }`}>
                    삭제
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
