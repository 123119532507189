import React from 'react'
import './Terms.css'

export default function Terms() {
  return (
    <>
      <div className="terms_container">
        <article id="f265f24f-14cd-42ab-8a8f-5109d4ad7fde" className="page sans">
          <header>
            <h1 className="page-title"> </h1>
          </header>
          <div className="page-body">
            <p id="2ecc7b18-5608-4df3-b8ad-a002f2a0f0aa" className="">
              <strong>얼싱팩 앱(APP) 이용약관</strong>
            </p>
            <p id="374b2a6f-683e-45c6-a7c5-d4cdd139dbc1" className="">
              <strong>제1장 종 칙</strong>
            </p>
            <p id="a56c3e88-aa14-40c9-a9c4-638f54e6fafc" className="">
              제1조 (목적)
            </p>
            <p id="ef69ebb3-a4ad-4e88-9336-0f88d8d5050d" className="">
              본 약관은 주식회사 샵플(이하 “회사”라 합니다)이 운영하는 리유저블 택배 파우치 – 얼싱팩
              “웹사이트” 와 “애플리케이션”(이하 “웹사이트” 와 “애플리케이션”을 “APP”이라고 합니다)
              이용 및 제공에 관한 제반 사항의 규정을 목적으로 합니다.
            </p>
            <p id="79b4ed35-01e3-40cb-a47e-2af3dd4ea2d5" className="">
              <strong>제2조 (용어의 정의)</strong>
            </p>
            <p id="74cf0b4e-54a4-466f-bd5c-054a170248ab" className="">
              ① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            </p>
            <ol
              type="1"
              id="8d9fc18d-0d2d-475b-be2e-49368e22deea"
              className="numbered-list"
              start={1}>
              <li>
                “얼싱팩 서비스”라 함은 구현되는 모바일 기기를 통하여 “이용자”가 이용할 수 있는
                얼싱팩 등 회사가 제공하는 제반 서비스를 의미합니다.
              </li>
            </ol>
            <ol
              type="1"
              id="638b4075-145f-4c96-a8fb-4b892192a17a"
              className="numbered-list"
              start={2}>
              <li>“얼싱팩”이란 회사가 제공하는 리유저블 택배 파우치를 말합니다.</li>
            </ol>
            <ol
              type="1"
              id="6fc31fbd-ed83-4f54-a595-b43c6338c567"
              className="numbered-list"
              start={3}>
              <li>
                “이용자”란 “APP”에 접속하여 본 약관에 따라 “APP”이 제공하는 서비스를 이용하는 자를
                말합니다.
              </li>
            </ol>
            <ol
              type="1"
              id="2076950e-a218-4dd7-ba8b-6b15705745b2"
              className="numbered-list"
              start={4}>
              <li>
                “회원”이란 “APP”에 개인정보를 제공하여 회원등록을 한 자로서, “APP”이 제공하는
                서비스를 이용하는 자를 말합니다.
              </li>
            </ol>
            <ol
              type="1"
              id="25eb4da6-dfd7-4fd9-9149-c0f9fc90c12b"
              className="numbered-list"
              start={5}>
              <li>
                “모바일 기기”란 콘텐츠를 다운로드 받거나 설치하여 사용할 수 있는 기기로서, 휴대폰,
                스마트폰, 휴대정보단말기(PDA), 태블릿 등을 의미합니다.
              </li>
            </ol>
            <ol
              type="1"
              id="8f1ad09c-619a-47bd-a85d-4e4fecb8e71a"
              className="numbered-list"
              start={6}>
              <li>
                “계정정보”란 회원의 회원번호와 얼싱팩 등 외부계정정보, 기기정보 등 회원이 회사에
                제공한 정보를 의미합니다.
              </li>
            </ol>
            <ol
              type="1"
              id="d5ad0e35-1a4a-4f78-ab6f-6f2198cfd5b2"
              className="numbered-list"
              start={7}>
              <li>
                “애플리케이션”이란 회사가 제공하는 서비스를 이용하기 위하여 모바일 기기를 통해
                다운로드 받거나 설치하여 사용하는 프로그램 일체를 의미합니다.
              </li>
            </ol>
            <ol
              type="1"
              id="afa2a636-bed5-4cc7-9286-1d4c8aa96e7d"
              className="numbered-list"
              start={8}>
              <li>
                “얼싱팩 포인트”(이하 “포인트”)라 함은 이용자가 APP 서비스를 통해 리유저블 택배
                파우치 얼싱팩을 반납하고 반납한 이용 횟수에 따라 회사가 부여하는 포인트를 말합니다.
              </li>
            </ol>
            <ol
              type="1"
              id="fb85a07c-65d1-4be8-b48d-7193bfafa7f5"
              className="numbered-list"
              start={9}>
              <li>
                “반납센터”란 회사가 제공하는 얼싱팩 이용자가 얼싱팩을 반납하는 반납장소(처)이며,
                반납된 얼싱팩 확인 및 할인 서비스를 제공하는 제휴 파트너샵(장소)을 의미합니다.
              </li>
            </ol>
            <ol
              type="1"
              id="c6347b10-6ad1-4429-b5eb-a317d3185165"
              className="numbered-list"
              start={1}>
              <li>
                “회수매니저”라 함은 이용자가 반납한 얼싱팩을 반납센터에서 회수하는 자를 말합니다.
              </li>
            </ol>
            <ol
              type="1"
              id="11d50844-f9e2-4b24-8e9f-c60accaac5b1"
              className="numbered-list"
              start={1}>
              <li>
                “세척센터”란 회수한 얼싱팩을 세척하고 수선하여 회사가 지정한 얼싱팩 사용처에
                얼싱팩을 제공하는 업무를 제공하는 센터를 말합니다.
              </li>
            </ol>
            <p id="9cc71ca5-9f17-4253-970c-4e0ab69b98da" className="">
              ② 본 약관에서 사용하는 용어의 정의는 본 조 제1항에서 정하는 것을 제외하고는 관계법령
              및 서비스별 정책에서 정하는 바에 의하며, 이에 정하지 아니한 것은 일반적인 상 관례에
              따릅니다.
            </p>
            <p id="79d29437-e82d-4916-8e8c-5eaa9fc6d17d" className="">
              <strong>제3조 (서비스 이용계약의 성립)</strong>
            </p>
            <p id="4ad6e64c-4607-4d2f-b9a8-94cf1470f4f0" className="">
              ① 본 서비스를 이용하고자 하는 자는 본 약관에 동의하여야 하며, 본 서비스를 이용하신
              경우 본 약관에 동의하는 것으로 간주됩니다.
            </p>
            <p id="fe1d8988-02ba-46f9-9dc7-58b02fbc44e0" className="">
              ② 본 서비스를 이용하고자 하는 자는, 회사가 정한 회원 가입 신청 양식에 따라 필요한
              사항을 기입하고 회원가입을 신청하실 수 있습니다. 회사는 회원의 종류에 따라 회사에서
              요구하는 자격에 부합하는지 심사를 진행할 수 있으며, 심사 절차를 거쳐 최종 승인된
              경우에 한하여 회원가입 절차가 종료됩니다.
            </p>
            <p id="f2abc552-e6cb-442a-8126-dcaad5997954" className="">
              ③ 회원가입 신청 처리는 이용자의 신청 순서에 의하며, 회원가입 완료시기는 회사의 승낙이
              회원에게 도달한 시점으로 하며, 이용자는 그 이후부터 회원으로서 서비스 이용이
              가능합니다.
            </p>
            <p id="448ae8d3-f442-4133-b930-3cf4d35a58b9" className="">
              ④ 회원가입 신청 시 이용자 본인의 정보로 가입신청을 해야 하며, 본인의 정보로 가입하지
              않은 사실(타인의 정보를 도용하는 경우 포함)이 확인된 경우 서비스 이용이 제한되거나
              관련 법령에 따라 처벌받을 수 있습니다.
            </p>
            <p id="135cbc8b-505f-4424-8b0f-4460c1dc0a74" className="">
              ⑤ 회원은 회사의 승인 절차를 진행하며 회사의 승낙 처리가 된 자에 한하며, 가입 여부는
              APP 서비스 해당 화면에서 확인할 수 있습니다. 회사는 승인 여부에 대해 본 서비스에 별도
              공지 또는 전자우편(E-Mail) 방식으로 통지합니다.
            </p>
            <p id="2bc96a53-5dcb-4085-bd82-08968167e5c5" className="">
              ⑥ 반납센터는 회사와 제휴 계약 체결을 통해 등록 절차를 진행하여, 회사의 승인 처리가 된
              자에 한하여 접근할 수 있으며, 승인 여부는 반납센터 서비스 해당 화면에서 확인하실 수
              있습니다. 회사는 승인 여부에 대해 반납센터 서비스에 별도 공지 또는 전자우편(E-Mail)
              방식으로 통지합니다.
            </p>
            <p id="ea57af17-b372-4607-9eed-750c8d25ecd9" className="">
              ⑦ 반납센터 관리자는 부관리자를 지정할 수 있으며, 반납센터 관리자의 승인 처리가 된 자에
              한하여 접근할 수 있으며, 승인 여부는 반납센터 서비스 해당 화면에서 확인하실 수
              있습니다. 회사는 승인 여부에 대해 반납센터 서비스에 별도 공지 또는 전자우편(E-Mail)
              방식으로 통지합니다.
            </p>
            <p id="11ac62e0-419a-439c-a244-89b7637cdccd" className="">
              ⑦ 세척센터는 회사와 계약 체결을 통해 등록 절차를 진행하며, 회사의 승인 처리가 된 자에
              한하여 접근할 수 있으며, 승인 여부는 세척센터 서비스에 별도 공지 또는 전자우편(E-Mail)
              방식으로 통지합니다.
            </p>
            <p id="77416b28-016a-4f2e-afb5-9bc744c98a2a" className="">
              ⑧ 세척센터는 회수매니저를 지정할 수 있으며, 세척센터 관리자의 승인 처리가 된 자에
              한하여 접근할 수 있으며, 승인 여부는 반납센터 서비스 해당 화면에서 확인하실 수
              있습니다. 회사는 승인 여부에 대해 회수매니저 서비스에 별도 공지 또는 전자우편(E-Mail)
              방식으로 통지합니다.
            </p>
            <p id="0bc64c39-d581-4d7d-b824-90bed73c81a7" className="">
              ⑧ 회사는 다음과 같은 사유가 발생한 경우 이용신청에 대한 승낙을 거부하거나 유보할 수
              있습니다.
            </p>
            <ol
              type="1"
              id="82267304-876a-4d4e-ba79-720e0e5a5f56"
              className="numbered-list"
              start={1}>
              <li>실제 정보로 가입 신청하지 않은 것이 확인된 경우</li>
            </ol>
            <ol
              type="1"
              id="a794b382-1b84-496d-a625-99ede40974b4"
              className="numbered-list"
              start={2}>
              <li>이미 가입된 회원 정보와 동일한 경우</li>
            </ol>
            <ol
              type="1"
              id="eac6d37a-c4c9-4d6a-a71e-d0d9174bc2a3"
              className="numbered-list"
              start={3}>
              <li>
                회사에 의하여 서비스 이용계약이 해지된 날로부터 7일 이내에 재이용 신청을 하는 경우
              </li>
            </ol>
            <ol
              type="1"
              id="fa073cd4-c99d-414f-9ff6-6f912feaca79"
              className="numbered-list"
              start={4}>
              <li>
                회사로부터 회원자격 정지 조치 등을 받은 회원이 그 조치 기간 중에 서비스 이용계약을
                임의 해지하고 재이용 신청을 하는 경우
              </li>
            </ol>
            <ol
              type="1"
              id="d5f06a3c-ab05-450f-9248-90348da043f3"
              className="numbered-list"
              start={5}>
              <li>
                기타 회사 정책에 위반 또는 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된
                경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우
              </li>
            </ol>
            <p id="cc1ed439-c0af-4813-84d7-c5ecb6992b3d" className="">
              <strong>제4조 (서비스 약관의 효력 및 변경)</strong>
            </p>
            <p id="20d8b9ca-5278-4a64-a166-2a51be321114" className="">
              ① 본 약관은 “웹사이트” 와 “APP” 내 또는 그 연결화면에 게시하거나 이용자에게
              공지함으로써 효력이 발생합니다.
            </p>
            <p id="97ffe6fd-5517-4b59-91a4-6bdad63c7c7e" className="">
              ② 회사는 불가피한 여건이나 사정이 있을 경우 「약관의 규제에 관한 법률」,
              「정보통신망이용촉진 및 정보보호 등에 관한 법률」등 관련 법령에 위배하지 않는 범위에서
              본 약관을 개정할 수 있습니다.
            </p>
            <p id="75eaabcc-afba-4d85-89c3-cc367f3af098" className="">
              ③ 회사가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유 등을 명시하여 최소한 그
              적용일 7일 이전부터 “APP” 내 또는 그 연결화면에 게시하여 이용자에게 공지합니다. 다만,
              변경된 내용이 회원에게 불리하거나 중대한 사항의 변경인 경우에는 그 적용일 30일
              이전까지 본문과 같은 방법으로 공지하고, 회원의 전자우편주소, 전자메모, 서비스 내 쪽지,
              문자메시지(LMS/SMS)의 방법으로 회원에게 통지합니다. 이 경우 개정 전 내용과 개정 후
              내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다.
            </p>
            <p id="192ab3f2-fdad-4fb2-895a-0af50bcff148" className="">
              ④ 회사가 약관을 개정할 경우 개정약관 공지 후 개정약관의 적용에 대한 회원의 동의 여부를
              확인합니다. 회사는 제4항의 공지 또는 통지를 할 경우 회원이 개정약관에 대해 동의 또는
              거부의 의사표시를 하지 않으면 동의한 것으로 볼 수 있다는 내용도 함께 공지 또는 통지를
              하며, 회원이 본 약관 시행일까지 거부의 의사표시를 하지 않는다면 개정약관에 동의한
              것으로 볼 수 있습니다. 회원이 개정약관에 대해 동의하지 않는 경우 회사 또는 회원은
              서비스 이용계약을 해지할 수 있습니다.
            </p>
            <p id="7ab7f02a-9bc5-49dd-8cf8-2c2fc4c76e4e" className="">
              <strong>제5조 (약관 외 준칙)</strong>
            </p>
            <p id="75b8ffda-f330-427b-aa0f-4c6f55e21ee2" className="">
              본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는 「약관의 규제에 관한
              법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」 등 관련 법령 또는 상 관례에
              따릅니다.
            </p>
            <p id="72fdf30c-9510-4d06-8e9d-1aa4e0ad590b" className="">
              <strong>제6조 (회원가입)</strong>
            </p>
            <p id="bcc3c4d2-9488-4099-a250-021d6adb53c5" className="">
              ① 이용자는 “APP”에서 정한 양식에 따라 회원정보를 기입한 후 본 약관의 내용에 대하여
              동의하여 회원가입 신청을 하면, 회사가 이러한 신청을 승낙하여 회원으로 가입됩니다.
            </p>
            <p id="60994d00-bb30-4a47-9fc5-1028f6a43096" className="">
              ② 회사는 원칙적으로 전 항에 따라 회원가입신청에 대하여 승낙함을 원칙으로 합니다. 다만,
              회사는 다음 각 호의 어느 하나에 해당하는 이용자에 대해서는 회원가입을 거절하거나
              사후에 회원자격을 상실 시킬 수 있습니다.
            </p>
            <ol
              type="1"
              id="0b2318c2-85cd-420b-9604-2a876adcd73d"
              className="numbered-list"
              start={1}>
              <li>회원정보 내용을 허위로 기재하거나 타인의 명의를 도용한 경우</li>
            </ol>
            <ol
              type="1"
              id="37a34427-da5d-404f-8c82-12054fcda99c"
              className="numbered-list"
              start={2}>
              <li>
                회사가 서비스를 제공하지 않은 국가에서 비정상적이거나 우회적인 방법을 통해 서비스를
                이용하는 경우
              </li>
            </ol>
            <ol
              type="1"
              id="abcdf272-c1af-45ce-a04d-b11046adc604"
              className="numbered-list"
              start={3}>
              <li>사회의 안녕과 질서 또는 미풍양속을 저해할 목적으로 신청한 경우</li>
            </ol>
            <ol
              type="1"
              id="fbaa5699-1c9d-4bd3-aad3-1909195df4d4"
              className="numbered-list"
              start={4}>
              <li>부정한 용도로 서비스를 이용하고자 하는 경우</li>
            </ol>
            <ol
              type="1"
              id="d96892cb-ccc8-4c1b-bf24-adcc72a0a966"
              className="numbered-list"
              start={5}>
              <li>영리를 추구할 목적으로 서비스를 이용하고자 하는 경우</li>
            </ol>
            <ol
              type="1"
              id="1d0573ad-a92f-401a-b48d-8c9d451a461f"
              className="numbered-list"
              start={6}>
              <li>가입 신청자가 본 약관에 의거 이전에 회원자격을 상실한 적이 있는 경우</li>
            </ol>
            <ol
              type="1"
              id="6af49d65-0215-4a79-a398-a88659821fd0"
              className="numbered-list"
              start={7}>
              <li>만 14세 미만인 경우</li>
            </ol>
            <ol
              type="1"
              id="44f7276f-8eca-4f43-82b6-9f9dcebd0dc4"
              className="numbered-list"
              start={8}>
              <li>그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우</li>
            </ol>
            <p id="75abf3b9-0e11-4ef1-8a22-5f2f1e73cab1" className="">
              ③ 회원은 가입시 등록한 회원정보의 변경이 발생한 경우, 즉시 “APP”에서 직접 수정 또는
              전자우편, 기타 방법으로 회사에 그 변경 사실을 알려야 합니다.
            </p>
            <p id="cea325b9-372a-4845-8dd5-a1fe61cf968a" className="">
              <strong>제7조 (회원탈퇴 및 자격상실)</strong>
            </p>
            <p id="8b4b69ee-d80f-4596-89d4-c196245f96b2" className="">
              ① 회원은 언제든지 서비스 이용을 원하지 않는 경우 언제든지 탈퇴를 요청할 수 있으며, 이
              경우 회사는 즉시 회원탈퇴를 처리합니다. 회원탈퇴로 인해 회원이 서비스 내에서 보유한
              이용정보는 모두 삭제되어 복구가 불가능하게 됩니다.
            </p>
            <p id="92fee82a-9a13-4bcc-ad5f-cd1001d4375f" className="">
              ② 회사는 회원이 본 약관이 금지하거나 공서양속에 반하는 행위를 하는 등 본 계약을 유지할
              수 없는 중대한 사유가 있는 경우에는 회원에게 통지하고, 서비스 이용을 제한․중지하거나
              회원 자격을 상실 시킬 수 있습니다.
            </p>
            <p id="b8449043-fde4-4c8b-814a-5dbcabd75bd9" className="">
              ③ 회사가 회원자격을 상실시키는 경우에는 회원 등록을 말소합니다. 이 경우 회원에게 이를
              통지하고, 회원 등록 말소 전에 소명할 기회를 부여합니다.
            </p>
            <p id="e67f965a-d669-444d-8bbe-f028a16470e5" className="">
              ④ 회사는 최근의 서비스 이용일부터 연속하여 1년 동안 회사의 서비스를 이용하지 않은
              회원(이하 “휴면계정”이라 합니다)의 개인정보를 보호하기 위해 이용계약을 정지 또는
              해지하고 회원의 개인정보를 분리보관 또는 파기 등의 조치를 취할 수 있습니다. 이 경우
              조치일 30일 전까지 계약 정지 또는 해지, 개인정보 분리보관 또는 파기 등의 조치가
              취해진다는 사실 및 파기될 개인정보 등을 회원에게 통지합니다.
            </p>
            <p id="c1e9992d-fa7e-4bd3-a246-493b5f0c0d92" className="">
              <strong>제8조 (회사의 의무)</strong>
            </p>
            <p id="b156d4bb-ade7-4ed2-b9c8-de1a4a28f972" className="">
              ① 회사는 관련 법령, 본 약관에서 정하는 권리의 행사 및 의무의 이행을 신의에 따라
              성실하게 준수합니다.
            </p>
            <p id="a62ac704-2a4c-48b2-974b-8a6dc670f84a" className="">
              ② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해
              보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다. 회사는 본 약관 및
              개인정보처리방침에서 정한 경우를 제외하고는 회원의 개인정보가 제3자에게 공개 또는
              제공되지 않도록 합니다.
            </p>
            <p id="f22f57fa-1570-4486-aaa7-2196c6a3cdfa" className="">
              ③ 회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에
              장애가 생기거나 데이터 등이 멸실․훼손된 때에는 천재지변, 비상사태, 현재의 기술로는
              해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는
              복구하도록 최선의 노력을 다합니다.
            </p>
            <p id="8be64ff5-2195-4c9b-af06-b67c54d1e088" className="">
              <strong>제9조 (회원의 의무)</strong>
            </p>
            <p id="0c8b1cc1-da0e-40fd-bbe9-377791f7d7b4" className="">
              ① 회원은 회사에서 제공하는 서비스의 이용과 관련하여 다음 각 호에 해당하는 행위를
              해서는 안 됩니다.
            </p>
            <ol
              type="1"
              id="09ee0733-ae65-43a6-beea-5e0d126d4dd5"
              className="numbered-list"
              start={1}>
              <li>
                이용신청 또는 회원 정보 변경 시 타인의 명의를 도용하거나 허위사실을 기재하는 행위
              </li>
            </ol>
            <ol
              type="1"
              id="912fb29c-867f-43dd-aad8-7eeba8911d36"
              className="numbered-list"
              start={2}>
              <li>
                회사의 직원이나 운영자를 가장하거나 타인의 명의를 도용하여 메일을 발송하는 행위,
                타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위
              </li>
            </ol>
            <ol
              type="1"
              id="6da965b7-3649-497e-b7f2-f6f0c799bce9"
              className="numbered-list"
              start={3}>
              <li>다른 회원의 개인정보를 무단으로 수집⋅저장⋅게시 또는 유포하는 행위</li>
            </ol>
            <ol
              type="1"
              id="7cd80820-04e5-4a1b-aeb2-695e5701e5c1"
              className="numbered-list"
              start={4}>
              <li>
                서비스를 무단으로 영리, 영업, 광고, 홍보, 정치활동, 선거운동 등 본래의 용도 이외의
                용도로 이용하는 행위
              </li>
            </ol>
            <ol
              type="1"
              id="451f9959-9be2-4018-8bee-54dbf1a2052f"
              className="numbered-list"
              start={5}>
              <li>
                회사의 서비스를 이용하여 얻은 정보를 무단으로 복제․유통․조장하거나 상업적으로
                이용하는 행위, 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위
              </li>
            </ol>
            <ol
              type="1"
              id="5fc43166-68c3-45e2-9e3d-fb43e416688f"
              className="numbered-list"
              start={6}>
              <li>
                타인을 기망하여 이득을 취하는 행위, 회사의 서비스의 이용과 관련하여 타인에게 피해를
                입히는 행위
              </li>
            </ol>
            <ol
              type="1"
              id="c84d9dc5-76bc-4209-94c6-233c483d72d6"
              className="numbered-list"
              start={7}>
              <li>
                회사나 타인의 지적재산권 또는 초상권을 침해하는 행위, 타인의 명예를 훼손하거나
                손해를 가하는 행위
              </li>
            </ol>
            <ol
              type="1"
              id="50137eda-9471-4294-8f96-7106fbaa4f3b"
              className="numbered-list"
              start={8}>
              <li>
                법령에 의하여 전송 또는 게시가 금지된 정보(컴퓨터 프로그램)나 컴퓨터
                소프트웨어⋅하드웨어 또는 전기통신장비의 정상적인 작동을 방해⋅파괴할 목적으로 고안된
                바이러스⋅컴퓨터 코드⋅파일⋅프로그램 등을 고의로 전송⋅게시⋅유포 또는 사용하는 행위
              </li>
            </ol>
            <ol
              type="1"
              id="7408b23a-f74e-429f-9f8c-7ff78ebb023c"
              className="numbered-list"
              start={9}>
              <li>
                회사로부터 특별한 권리를 부여받지 않고 애플리케이션을 변경하거나, 애플리케이션에
                다른 프로그램을 추가⋅삽입하거나, 서버를 해킹⋅역설계하거나, 소스 코드나 애플리케이션
                데이터를 유출⋅변경하거나, 별도의 서버를 구축하거나, 웹사이트의 일부분을 임의로
                변경⋅도용하여 회사를 사칭하는 행위
              </li>
            </ol>
            <ol
              type="1"
              id="f1c0ed3e-d6d2-4c07-97d1-c08c6d74aac5"
              className="numbered-list"
              start={1}>
              <li>그 밖에 관련 법령에 위반되거나 선량한 풍속 기타 사회통념에 반하는 행위</li>
            </ol>
            <p id="dde542b4-9ef4-4c16-9fb3-b240edf51b77" className="">
              ② 회원의 ID와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록
              하여서는 안 됩니다.
            </p>
            <p id="9de977d2-a58d-40ac-83de-5c68af31b85e" className="">
              ③ 이용자는 본 약관 및 관련법령에 규정한 사항을 준수하여야 합니다.
            </p>
            <p id="b6a4e4dc-7f8a-45eb-9ba3-f57cbcb9da5a" className="">
              <strong>제10조 (서비스의 이용)</strong>
            </p>
            <p id="a9bbce74-2891-4fe0-a8de-c585f2018e8e" className="">
              ① “APP”은 다음과 같은 서비스를 회원에게 제공합니다. 단, 회사가“APP”으로 제공하는
              서비스 이용을 위해 필요시 이용자에게 위치정보이용약관 및 “계정정보”의 제공에 관한
              동의를 추가로 요구할 수 있으며, 동의하지 않을 경우 얼싱팩 서비스 및 포인트 조회 등
              일부 서비스가 제한될 수 있습니다.
            </p>
            <ol
              type="1"
              id="780110f3-a86e-479e-a095-4d6077d6f8ef"
              className="numbered-list"
              start={1}>
              <li>얼싱팩 이용내역 조회</li>
            </ol>
            <ol
              type="1"
              id="004d01ce-573c-4f76-8427-c0d94a80a398"
              className="numbered-list"
              start={2}>
              <li>얼싱팩 APP 가입 대행</li>
            </ol>
            <ol
              type="1"
              id="bf317ac3-e972-44ad-933a-a0a7e76f0622"
              className="numbered-list"
              start={3}>
              <li>이메일 수신 서비스</li>
            </ol>
            <ol
              type="1"
              id="1d660645-6623-4ef4-b5ca-0a3cd6fb7166"
              className="numbered-list"
              start={4}>
              <li>얼싱팩 반납 및 할인받기</li>
            </ol>
            <ol
              type="1"
              id="50ae5257-c738-4e83-87b9-3d85ff0e3fa7"
              className="numbered-list"
              start={5}>
              <li>얼싱팩 포인트 적립</li>
            </ol>
            <ol
              type="1"
              id="9337fb53-f30f-42f6-8cbc-ff6be0e5ebb1"
              className="numbered-list"
              start={6}>
              <li>얼싱팩 서비스 설명 및 안내</li>
            </ol>
            <ol
              type="1"
              id="54df91e3-a49f-47c5-aea5-97797b1a5a83"
              className="numbered-list"
              start={7}>
              <li>얼싱팩 포인트 및 관련 컨텐츠 제공</li>
            </ol>
            <ol
              type="1"
              id="f8fa4bdb-952a-463b-a8ff-517689d322c3"
              className="numbered-list"
              start={8}>
              <li>기타 회사가 정하는 서비스</li>
            </ol>
            <p id="c2ece210-15cc-432d-adf4-f322702e86c9" className="">
              ② 회사는 회원에게 별도의 동의를 받은 경우 서비스 이용에 대한 유용한 각종 정보에 대하여
              “APP”에 게재하는 것 이외에 문자메시지, 푸시(Push) 알림 등의 방법으로 회원에게 제공할
              수 있습니다.
            </p>
            <p id="c2afec5f-16b1-41c5-8a13-5c259f4ccbe8" className="">
              ③ 서비스의 이용은 “APP”의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴를 원칙으로
              합니다. 다만, 정기점검 등의 필요로 인하여 회사가 정한 날 및 시간에 대해서는 예외로
              합니다.
            </p>
            <p id="4511f73f-3f38-4ca2-a8c5-7b491a9c7f28" className="">
              ④ 회사는 “APP” 시스템 등의 보수, 점검, 교체, 시스템의 고장, 통신의 두절, 기타
              불가항력적 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다. 다만,
              서비스 중단의 경우 회사는 “APP”에 사전 통지하고, 사전에 통지할 수 없는 부득이한 사유가
              있는 경우 제 조에 정한 방법으로 회원에게 통지합니다.
            </p>
            <p id="3f895f36-be0e-48a7-be0d-ac0861004b46" className="">
              <strong>제11조 (할인 및 포인트)</strong>
            </p>
            <p id="efa4c09f-a4c9-4ff1-97d3-ee429fa0ac5b" className="">
              ① APP을 이용하여 얼싱팩을 반납센터에 반납 완료한 회원에게 아래와 같은 할인 서비스
              제공받고 포인트를 부여받게 됩니다.
            </p>
            <p id="17553bf5-6aba-4f0d-b3f3-f0b38745cb46" className="">
              단, 포인트의 적립과 관련하여 발생하는 제세공과금은 회원이 부담합니다.
            </p>
            <ol
              type="1"
              id="1418f0c4-450d-43b5-981a-355c9a67422a"
              className="numbered-list"
              start={1}>
              <li>할인 서비스 = (얼싱팩 반납 완료와 함께 제공되는 할인 서비스)</li>
            </ol>
            <ol
              type="1"
              id="9834ca61-c324-4e53-bf85-bd81717446b2"
              className="numbered-list"
              start={2}>
              <li>적립 포인트 = (얼싱팩 반납 완료 1건당 1P 적립 포인트)</li>
            </ol>
            <p id="cf24d63c-2fcb-4412-8a31-07f99c605bb6" className="">
              ② 포인트는 얼싱팩을 반납 완료하고 3일 이내 적립되며, 회사가 정한 기준에 따라 합산하여
              적립 및 사용할 수 있습니다.
            </p>
            <p id="ac4b6d9a-e459-4632-93fb-0be5fbd60e2d" className="">
              ③ 사용가능포인트를 보유한 회원은 회사가 정한 지정처 및 제휴사에서 회사가 정한 소정
              절차에 따라 상품 구매나 서비스 이용에 따른 대금의 일부 또는 전부를 사용가능포인트로
              결제할 수 있습니다.
            </p>
            <p id="782ee479-0f59-461d-8456-3377aa826b11" className="">
              <strong>제12조 (개인정보의 보호 및 사용)</strong>
            </p>
            <p id="6c14e3ed-9920-416f-aa90-c1030d61d2ae" className="">
              ① 회사는 이용자의 정보 수집시 원활한 서비스 제공을 위해 필요한 최소한의 정보를
              수집합니다.
            </p>
            <p id="e01e6b52-8c8a-4789-8ff9-c12e8741c867" className="">
              ② 회사가 이용자의 개인식별이 가능한 개인정보 및 계정정보를 수집하는 때에는 반드시 당해
              이용자의 동의를 받습니다.
            </p>
            <p id="f3d14136-9ab6-4ff3-9b7e-7208a2a4260b" className="">
              ③ 회사는 관련 법령에 의하거나, 관련 국가기관 등의 요청이 있는 경우를 제외하고는 회원의
              개인정보를 본인의 동의 없이 타인에게 제공하지 않습니다.
            </p>
            <p id="cd3e961b-a7ad-4e8c-94b4-e69108fdb781" className="">
              ④ 이용자는 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정 또는
              동의 철회를 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무를
              집니다. 이용자가 오류의 정정을 요구한 경우에는 그 오류를 정정할 때까지 해당 개인정보를
              이용하지 않습니다.
            </p>
            <p id="bb7f516a-6ee9-4fa6-897a-1f32a788bcdc" className="">
              ⑤ 기타 개인정보관련사항은 “APP”에 별도로 게시하는 개인정보취급방침에 의거합니다.
            </p>
            <p id="41bfb232-0b2b-484f-b4d1-30d7bcb8880f" className="">
              <strong>제13조 (회사의 면책)</strong>
            </p>
            <p id="931b84ae-22e3-4812-bc1c-d2ec0f2a1787" className="">
              ① 회사는 본 약관 제10조 제3항, 제4항의 사유로 서비스 제공이 일시적으로 중단됨으로
              인하여 이용자 또는 제3자가 입은 손해에 대해서는 관련법에 특별한 규정이 없는 한
              회원에게 별도의 보상을 하지 않습니다.
            </p>
            <p id="8a46f7b3-7112-4767-9b4c-8c6ffb045a76" className="">
              ② 회사는 회원의 고의 또는 과실로 인한 서비스 이용의 장애에 대하여는 책임을 지지
              않습니다.
            </p>
            <p id="484263e5-6c11-4afc-8f14-8ef12e200a6c" className="">
              ③ 서비스와 관련하여 게재한 정보나 자료 등의 신뢰성, 정확성 등에 대하여 회사는 고의
              또는 중대한 과실이 없는 한 책임을 지지 않습니다.
            </p>
            <p id="51aecd3e-8ec2-47b3-9963-104d6a99ecd3" className="">
              ④ 회사는 무료로 제공되는 서비스 이용과 관련하여 회원에게 발생한 손해에 대해서는 책임을
              지지 않습니다.
            </p>
            <p id="574c8e36-692a-4d22-b191-435cb0d7d56f" className="">
              ⑤ 회사는 회원이 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한 것에 대하여
              책임을 지지 않습니다.
            </p>
            <p id="4fa9c13e-1c22-4c8f-a7af-5fb020eb1baf" className="">
              ⑥ 회사는 회원이 “APP”아이디 및 비밀번호, 모바일 기기 비밀번호, 계정정보 등을 관리하지
              않아 발생하는 손해에 대해 책임을 지지 않습니다.
            </p>
            <p id="5eefd096-4183-4da2-be7d-0df744651066" className="">
              ⑦ 회원이 모바일 기기의 변경, 모바일 기기의 번호 변경, 운영체제(OS) 버전의 변경, 해외
              로밍, 통신사 변경 등으로 인해 콘텐츠 전부나 일부의 기능을 이용할 수 없는 경우 회사는
              이에 대해 책임을 지지 않습니다.
            </p>
            <p id="7f035e17-6e55-4e0b-8f1e-ac71670da196" className="">
              ⑧ 회원이 회사가 제공하는 콘텐츠나 계정정보를 삭제한 경우 회사는 이에 대해 책임을 지지
              않습니다.
            </p>
            <p id="a2e2c1bc-236c-4a52-a20f-7b4bb45ac3ab" className="">
              <strong>제14조 (저작권 등의 귀속)</strong>
            </p>
            <p id="c7fdd82b-49de-434b-b821-fcd47588674d" className="">
              ① 회사가 제작한 서비스 내의 콘텐츠에 대한 저작권과 기타 지적재산권은 회사에
              귀속합니다.
            </p>
            <p id="aa846ea9-5047-49ca-8a98-c0c8927dd5cd" className="">
              ② 회원은 회사가 제공하는 서비스를 이용하여 얻은 정보 중에서 회사 또는 제공업체에
              지적재산권이 귀속된 정보를 회사 또는 제공업체의 사전 동의 없이 복제⋅전송 등의
              방법(편집, 공표, 공연, 배포, 방송, 2차적 저작물 작성 등을 포함합니다. 이하 같습니다)에
              의하여 영리목적으로 이용하거나 타인에게 이용하게 하여서는 안 됩니다.
            </p>
            <p id="75fa1ce1-94ce-4b1c-b678-77869b8ae818" className="">
              ③ 회원의 게시물이 정보통신망법 및 저작권법 등 관련법에 위반되는 내용을 포함하는 경우,
              권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수
              있으며, 이로 인해 발생하는 민·형사상의 책임은 전적으로 해당 회원 본인이 부담하여야
              하며, 회사는 관련법에 따라 조치를 취하여야 합니다
            </p>
            <p id="aa69f366-d963-493b-a3f3-b84c81c9a8db" className="">
              ④ 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가
              있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해
              임시조치 등을 취할 수 있습니다.
            </p>
            <p id="68e327e9-138b-4943-bbc7-332a60820947" className="">
              ⑤ 이 조는 회사가 서비스를 운영하는 동안 유효하며, 회원 탈퇴 후에도 지속적으로
              적용됩니다.
            </p>
            <p id="abd5d05a-1199-4201-a8e3-8903b457f8ad" className="">
              <strong>제15조 (회원의 고충처리 및 분쟁해결)</strong>
            </p>
            <p id="52454be3-3f77-4879-acb5-3d8f47376760" className="">
              ① 회사는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는 방법을 서비스 내 또는
              그 연결화면에 안내합니다.
            </p>
            <p id="192ebd70-2765-422a-9519-4c55dc12c6d3" className="">
              ② 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는
              합리적인 기간 내에 이를 신속하게 처리합니다. 다만, 처리에 장기간이 소요되는 경우에는
              회원에게 장기간이 소요되는 사유와 처리일정을 서비스 내 공지하거나 제8조에 따라
              통지합니다.
            </p>
            <p id="2ea4ee7e-ba96-44f3-9bfb-80e16be91deb" className="">
              <strong>제16조 (개인정보의 보호)</strong>
            </p>
            <p id="ce0bf975-714c-4d61-9a7a-9372214e2667" className="">
              ① 회원 및 이용자는 본 서비스를 이용하면서 얻은 타인의 개인정보를 본 약관에서 정한 목적
              이외의 용도로 사용할 수 없으며, 이를 위반하면 관련 법령에 따라 모든 민/형사상의 법적
              책임을 지고 자신의 노력과 비용으로 회사를 면책시켜야만 하며, 회사는 본 서비스 이용을
              제한할 수 있습니다.
            </p>
            <p id="28814a0b-08dc-4b8c-8701-ce9d51214e49" className="">
              ② 회사는 본 서비스 이행 목적으로 회원 및 이용자에게 제공된 타인의 개인정보에 대하여
              일정 기간이 지난 후 비공개 조치할 수 있습니다.
            </p>
            <p id="c62e83e8-5692-494c-b7a2-a89572ee9967" className="">
              ③ 회사가 개인정보를 보호하는데 주의를 하였음에도 불구하고 특정 회원 및 이용자가
              제1항을 위반하여 타인의 개인정보를 유출하거나 유용한 경우 회사는 그에 대하여 아무런
              책임을 지지 않습니다.
            </p>
            <p id="f88a5aeb-2014-40e1-bfb6-d7316c576e39" className="">
              ④ 회사는 전기통신사업법 등 관련 법령이 규정하는 적법한 절차에 따라 수사관서 등이 회원
              및 이용자에 관한 정보 제공을 요청받았을 경우 회사는 해당 자료를 제출할 수 있습니다.
            </p>
            <p id="4c6e679f-2b09-4f93-a489-1df393162fd4" className="">
              ⑤ 회사는 회원 및 이용자가 불법행위를 하였다면 이에 대한 수사 등을 의뢰하기 위하여 관련
              자료를 수사관서 등에 제출할 수 있고, 회원은 이에 동의합니다.
            </p>
            <p id="4bfa0cd2-6912-44ae-9cca-c04a4b162ed7" className="">
              ⑥ 회원 및 이용자는 회사로부터 제공받은 정보를 제공받은 목적(상품 서비스 제공 등)의
              용도로 법령 또는 회사가 정한 기간 동안 보유할 수 있으며 그 기간이 종료될 경우 즉시
              파기해야 합니다. 또한 제공받은 개인정보의 주체로부터 직접 파기 요청을 받은 경우 이에
              응해야 합니다.
            </p>
            <p id="d248014d-74a0-4334-93e6-110514338c69" className="">
              ⑦ 회원 및 이용자는 관련 법령 및 방송통신위원회 고시에 따라 회사로부터 제공받은
              개인정보를 보호하기 위하여 기술적∙관리적 보호조치를 취해야 합니다.
            </p>
            <p id="20c9cf48-daac-4f19-879f-63225c84f7ba" className="">
              <strong>제16조 (손해배상)</strong>
            </p>
            <p id="3dc5192d-0011-4979-8bd2-f4b5fcc2f6b0" className="">
              ① 회사 또는 회원 및 이용자 일방이 본 약관을 불이행함으로써 상대방 또는 제3자에게
              손해가 발생한 경우, 귀책 당사자는 상대방 또는 제3자에게 발생한 손해를 배상할 책임이
              있습니다.
            </p>
            <p id="f9c0cc54-219f-497f-bf47-a44adcd2fda5" className="">
              ② 회원 및 이용자가 본 서비스를 이용하면서 회사의 명예, 신용 등 대외 이미지를
              실추함으로써 회사에 유∙무형적 손해가 발생한 경우, 회원 및 이용자는 이에 따른 회사의
              손해를 배상해야 합니다.
            </p>
            <p id="17dea07c-7dd1-4208-86ce-9344e0672d58" className="">
              <strong>제17조 (금지행위 및 이용제한)</strong>
            </p>
            <p id="c3069851-9292-477a-afe6-a094d64c3293" className="">
              ① 회원 및 이용자는 회사의 경영 또는 영업 활동을 방해하거나, 회사의 명예, 신용 등 대외
              이미지를 실추하는 행위를 직∙간접적으로 하거나 제3자로 하여금 하도록 하여서는 안 됩니다
            </p>
            <p id="61c58032-b866-4df7-9b35-0b1cefffd0ba" className="">
              ② 회사는 제휴 카페 회원의 법령 위반, 본 약관 위반, 기타 금지행위 여부에 대한
              모니터링을 실시할 수 있고, 제휴 카페 회원은 회사의 모니터링 업무에 대하여 협조하여야
              하며 회사가 요청하는 필요 최소한의 증빙자료를 제출할 의무가 있습니다.
            </p>
            <p id="0e0206d9-b9e4-44df-a1fd-d2a3d09ca774" className="">
              <strong>제18조 (비밀유지)</strong>
            </p>
            <p id="a7d620f3-e108-4610-afc8-6886c3cc3dc9" className="">
              회사와 회원 및 이용자는 법령상 요구되는 경우를 제외하고는 본 서비스를 통하여
              상대방으로부터 취득한 정보, 기술정보, 판매계획, 노하우 등 비밀로 관리되는 정보를
              제3자에게 누설해서는 안 되며, 그 정보를 본 약관 이행 이외의 목적으로 사용해서는 안
              됩니다.
            </p>
            <p id="a90f812b-1bee-43c0-8b5a-2115f4fc9ed0" className="">
              <strong>제19조 (정보제공)</strong>
            </p>
            <p id="9ac7f480-ffbf-47b6-b8af-abfddfd5d7ac" className="">
              회사는 이용자로부터 수집한 개인정보를 통해 회원의 서비스 이용 중 필요하다고 판단되는
              다양한 마케팅 정보를 스마트폰 알림(앱 Push 알림), 전자우편(E-Mail), SMS(LMS)등을
              활용하여 발송할 수 있으며, 회원은 이에 동의합니다. 다만, 회원은 거래 관련 정보 및 고객
              문의 등에 대한 답변을 제외하고 관련법에 따라 언제든지 마케팅 수신 동의를 철회하실 수
              있으며, 이 경우 회사는 마케팅 정보 등을 제공하는 행위를 중단합니다. (단, 시스템 반영에
              시차가 있을 수 있음)
            </p>
            <p id="9148d750-84fd-4bf6-b2ee-912167e2c61c" className="">
              <strong>제20조 (관할법원)</strong>
            </p>
            <p id="434700aa-84d2-4aa1-a97d-552027d1d67d" className="">
              본 약관과 관련한 제반 분쟁 및 소송은 회사의 본점 소재지를 관할하는 법원을 제1심 관할
              법원으로 합니다
            </p>
            <p id="43a1ac5c-c883-4ed5-a5ae-442629de9510" className="">
              <strong>제21조 (보칙)</strong>
            </p>
            <p id="b8c8db25-4e57-4ad6-b159-6dea0ba987cc" className="">
              본 약관과 관련하여 당사자가 서로 합의하여 추가로 작성한 계약서, 협정서, 약정서 등과
              회사의 정책 변경, 법령의 제·개정 또는 공공기관의 고시·지침 등에 따라 회사가 본 서비스
              등을 통해 이용자에게 공지하는 내용도 본 약관의 일부로 구성됩니다. ② 본 약관과
              개별약관, 별도 정책에서 정하지 않은 사항은 관련 법령 및 상관례를 따릅니다.
            </p>
            <p id="0e50e83a-cab3-4712-a5e7-1e4844ee1ca0" className="">
              <strong>제2장 서비스</strong>
            </p>
            <p id="ee237e64-8181-4913-855d-41fefda36ca6" className="">
              <strong>제22조 (얼싱팩)</strong>
            </p>
            <p id="60331e09-027c-483a-8ee6-4d06185bf46c" className="">
              ① 회사는 얼싱팩 서비스를 이용하는 회원 및 이용자에게 얼싱팩을 제공해 줍니다.
            </p>
            <p id="684c7c34-ebb8-405f-98e5-579b26533ae0" className="">
              ② 얼싱팩의 소유권은 회사에게 있습니다.
            </p>
            <p id="d6f426c4-e82c-44c2-81ec-3b69534f1676" className="">
              ③ 반납센터, 회수매니저, 세척센터는 제휴 계약자로써 지위 상실, 소유 변경 등 상품 및
              시설 운영에 필요한 정보가 변경된 경우 지체 없이 회사에게 알리고 보유하고 있는 얼싱팩을
              회사에 반납해야 합니다. 만약, 전산에 등록된 얼싱팩의 수량만큼 회사에 반납하지 않으면
              1개당 별도로 지정된 금액을 회사에게 지불해야 합니다.
            </p>
            <p id="58caeb74-5d04-4de4-b24d-b50c998cf7b8" className="">
              ④ 회사는 본 이용자와 제3자 상호 간에 분쟁이 발생하였을 때 이에 개입할 수 없으며,
              이러한 분쟁으로 발생하는 손해를 배상할 책임도 없습니다.
            </p>
            <p id="1a3b1306-0e23-42ae-8e8f-217e78a54520" className="">
              ⑤ 회원 및 이용자는 얼싱팩을 제3자에게 유상으로 제공(임대, 양도 등을 포함하여 이에
              한정되지 아니함)하는 행위는 해서는 안 됩니다.
            </p>
            <p id="f0fb5e35-7572-4afe-bd05-fd8895ee75fb" className="">
              부칙
            </p>
            <p id="c19438bb-edb0-4ee6-8169-a66b9a0ef960" className="">
              본 이용 약관은 2022년 9월 29일부터 시행합니다.
            </p>
          </div>
        </article>
      </div>
    </>
  )
}
