import axios from 'api/axios'
import { BannerInputPreviewComponent } from 'components/BannerInputPreviewComponent'
import { QuillEditorComponent } from 'components/QuillEditorComponent'
import { Posts } from 'enums/Posts'
import { usePostRange } from 'hooks/usePostRange'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Post } from 'types/Post'

export const PointUpdatePage = () => {
  const { pointId } = useParams()
  const navigate = useNavigate()
  const [point, setPoint] = useState(0)
  const [bannerUrl, setBannerUrl] = useState<string>()
  const [body, setBody] = useState('')
  const { range } = usePostRange()
  const [title, setTitle] = useState('')

  const updatePoint = async () => {
    if (!bannerUrl) return alert('변경할 배너 이미지를 넣어주세요')
    if (!point) return alert('변경할 포인트')
    if (!body) return alert('변경할 본문')
    if (!title) return alert('제목')

    try {
      const res = await axios.patch<{ post: Post }>(`/post/${pointId}`, {
        post: { bannerUrl, body, point, range, title },
      })

      navigate(`/setting/point/${res.data.post.id}`)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const fetchPoint = async () => {
      try {
        const res = await axios.get<{ post: Post }>(`/post/${pointId}`)

        if (!res.data.post.bannerUrl) return
        if (!res.data.post.point) return

        setPoint(res.data.post.point)
        setBannerUrl(res.data.post.bannerUrl)
        setBody(res.data.post.body)
        setTitle(res.data.post.reward.title)
      } catch (error) {
        console.log(error)
      }
    }

    fetchPoint()
  }, [pointId])

  return (
    <div className="flex-1 p-5 bg-white mxl:p-0">
      <h2 className="text-2xl h-12 flex items-center">포인트 게시물 수정</h2>
      <BannerInputPreviewComponent
        type={Posts.POINT}
        bannerUrl={bannerUrl}
        setBannerUrl={setBannerUrl}
      />
      <div className="flex items-center gap-4 py-3">
        <label htmlFor="" className=" text-md font-bold w-12">
          포인트
        </label>
        <input
          type="text"
          className="flex-1 w-full px-2 leading-10 border border-gray-300 border-solid rounded-md mxl:w-full"
          value={point}
          onChange={e => setPoint(Number(e.target.value))}
          placeholder="적용될 포인트"
        />
      </div>

      <div className="flex items-center gap-4 py-3">
        <label htmlFor="" className="font-bold  text-md w-12">
          제목
        </label>
        <input
          type="text"
          className="flex-1 w-full px-2 leading-10 border border-gray-300 border-solid rounded-md mxl:w-full"
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder="제목"
        />
      </div>

      <div className="mt-5 h-[55vh] md:h-[66vh]">
        <QuillEditorComponent type={Posts.EVENT} body={body} setBody={setBody} />
      </div>

      <div className="flex items-center justify-center gap-3 mt-20 mxl:flex-col">
        <button
          className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-blue-600 text-white hover:bg-blue-800"
          onClick={() => updatePoint()}>
          수정
        </button>
        <button
          className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-slate-200 text-gray-500 hover:bg-gray-400 hover:text-white"
          onClick={() => navigate(-1)}>
          취소
        </button>
      </div>
    </div>
  )
}
