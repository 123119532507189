import axios from 'api/axios'
import { Places } from 'enums/Places'
import { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import { setFactory } from 'store/factorySlice'
import { Place } from 'types/Place'

export const useFactory = () => {
  const user = useAppSelector(state => state.user)
  const factory = useAppSelector(s => s.factory)
  const dispatch = useAppDispatch()

  const fetchFactory = useCallback(async () => {
    const f = user.roles.find(r => r.place.type === Places.FACTORY)?.place
    if (!f) return

    try {
      const res = await axios.get<{ place: Place }>(`/places/${f.id}`)
      dispatch(setFactory(res.data.place))

      return res.data.place
    } catch (error) {
      console.log(error)
    }
  }, [dispatch, user.roles])

  useEffect(() => {
    fetchFactory()
  }, [factory.id, fetchFactory])

  return { factory, fetchFactory }
}
