import axios from 'api/axios'
import { Posts } from 'enums/Posts'
import { usePostRange } from 'hooks/usePostRange'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Post } from 'types/Post'
import { QuillEditorComponent } from '../../../components/QuillEditorComponent'

export const NoticeAddPage = () => {
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const { range, renderPostRange } = usePostRange()

  const submitNotice = async () => {
    try {
      const res = await axios.post<{ post: Post }>('/post', {
        title,
        body,
        type: Posts.NOTICE,
        range,
      })

      navigate(`/setting/notice/${res.data.post.id}`)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="flex-1 p-5 bg-white mxl:p-0">
      <div className="flex items-center justify-between mxl:flex-col mxl:items-start h-12">
        <h2 className="text-2xl">공지사항 작성</h2>
        {renderPostRange()}
      </div>

      <div className="mt-5">
        <input
          type="text"
          className="w-full px-2 leading-10 border border-gray-300 rounded"
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder="제목을 입력해 주세요"
        />
      </div>

      <div className="mt-5 h-[55vh] md:h-[66vh]">
        <QuillEditorComponent type={Posts.NOTICE} body={body} setBody={setBody} />
      </div>

      <div className="flex items-center justify-center gap-3 mt-20 mxl:flex-col">
        <button
          className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-blue-600 text-white hover:bg-blue-800"
          onClick={() => submitNotice()}>
          완료
        </button>
        <button
          className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-slate-200 text-gray-500 hover:bg-gray-400 hover:text-white"
          onClick={() => navigate(-1)}>
          취소
        </button>
      </div>
    </div>
  )
}
