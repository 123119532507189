import React from 'react'
import './Terms.css'

function PrivacyAgree() {
  return (
    <div className="terms_container">
      <article id="40f5119e-9178-462b-9150-a2046f06afbf" className="page sans">
        <header>
          <h1 className="page-title"> </h1>
        </header>
        <div className="page-body">
          <p id="9dfb4324-742f-4148-b088-7914fb38715a" className="">
            <strong>얼싱팩 앱(APP) 개인 정보 수집 및 이용동의</strong>
          </p>
          <p id="d555ed15-5a80-4d13-a2c1-2518daa7252e" className="">
            주식회사 샵플(이하 “회사”라 합니다)이 운영하는 리유저블 택배 파우치 – 얼싱팩 “웹사이트”
            와 “애플리케이션”(이하 “웹사이트” 와 “애플리케이션”을 “APP”이라고 합니다) 서비스 회원 및
            이용자를 위한 컨텐츠 제공을 위해 최소한의 개인정보를 수집하고 있습니다. 이에 개인정보의
            수집 및 이용에 관하여 아래와 같이 고지하오니 충분히 읽어 보신 후 동의하여 주시기
            바랍니다.
          </p>
          <p id="ad9c6158-da0d-41ff-9642-2a308d47f124" className="">
            <strong>1. 수집 및 이용목적</strong>
          </p>
          <ul id="5dbf380a-d3e3-4dc6-86d6-700d57b2fcaa" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>APP 회원가입, 서비스 이용시 본인의 확인</li>
          </ul>
          <p id="ac0d8233-36d1-43e2-aade-9c21d3838b8b" className="">
            · 각종고지 · 알림, 서비스 이용을 위한 기록 보존
          </p>
          <p id="dff822a3-f827-495d-ae79-35b98f297943" className="">
            <strong>2. 수집 및 이용하는 개인정보 항목</strong>
          </p>
          <p id="15abc661-6e00-42e0-800e-1c4fd3378e40" className="">
            APP 회원가입
          </p>
          <ul id="bff85e03-9fc4-40a7-b05e-2782d985aa19" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>성명, 연락처, 이메일</li>
          </ul>
          <p id="f0827b02-c4c7-48c2-a00e-966bfd8c9642" className="">
            APP 서비스 관련
          </p>
          <ul id="ce029f53-0549-4ee4-bcbd-e4390f0f006d" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>얼싱팩 서비스</li>
          </ul>
          <ul id="6507e61f-83a2-4ab0-a3ff-6b61510e0088" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>APP 접속 정보 및 서비스 이용정보</li>
          </ul>
          <p id="066a48fc-6d38-4633-a308-0f395927eaf0" className="">
            <strong>3. 수집방법</strong>
          </p>
          <p id="7426f0bc-d6c2-4cbe-a2c2-477cea27c011" className="">
            실명확인을 위하여 마련된 핸드폰에 직접 입력하는 방식
          </p>
          <ul id="bc7f0a05-bf60-41f6-8a47-c70b034e4fb3" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              APP에 마련된 회원가입란 및 보험계약 정보 입력란에 회원 본인이 직접 입력하는 방식
            </li>
          </ul>
          <ul id="38110a7d-a5e3-4818-b45c-875c498c787b" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>생성정보 수집 툴을 통한 수집</li>
          </ul>
          <p id="c19152c9-cf02-4374-913e-ff7d01ced996" className="">
            <strong>4. 보유 및 이용기간</strong>
          </p>
          <p id="b7a31be9-db96-42b9-8fd4-81974210b24e" className="">
            이용자가 회원 탈회를 요청하거나 개인정보의 수집·이용 등에 대한 동의를 철회할 때까지
            보유·이용합니다. 다만, 다음의 정보에 대해서는 아래의 이유로 예외로 합니다.
          </p>
          <ul id="198cabb8-8a86-4d82-8c6d-49c04f36d9d3" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              기타 법률에 의해 이용자의 개인정보를 보존해야 할 필요가 있는 경우에는 해당 법률의
              규정에 따릅니다.
            </li>
          </ul>
          <ul id="1ed95f57-a475-4bf1-9fe3-15f7142237bc" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              정보통신망 이용촉진 및 정보 등에 관한 법률에 따라 APP을 대통령령으로 정하는 기간동안
              로그인하지 아니하는 이용자의 개인정보를 보호하기 위하여 개인정보 파기 등 필요한 조치를
              취합니다. APP 회원은 1년간 미로그인시 회원정보 삭제 또는 분리보관 및 탈퇴 또는
              이용정지처리가 됩니다. 이용자의 요청에 따라 달리 정한 경우가 있을 경우, 달리 정한
              기간을 적용할 수 있습니다.
            </li>
          </ul>
        </div>
      </article>
    </div>
  )
}

export default PrivacyAgree
