import axios from 'api/axios'
import { Places } from 'enums/Places'
import { formatDateString, formatTimeString } from 'pages/RetrivalManager/CentersPage'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Place } from 'types/Place'
import { Transfer, Transfers } from 'types/Transfer'
import { User } from 'types/User'

export type TransferCircle = {
  id: number
  transferId: number
  title: string
  place?: Place
  user?: User
  at?: string
}

export function getTransferCircles(t: Transfer) {
  const startedCircle = {
    id: Math.random(),
    transferId: t.id,
    title:
      t.type === Transfers.ISSUING
        ? '출고'
        : t.type === Transfers.EARTHING
        ? '고객'
        : t.type === Transfers.RETRIEVING
        ? '회수'
        : t.type === Transfers.MOVING
        ? '이동'
        : '입고',
    place: t.from,
    user: t.giver,
    at: t.createdAt,
  }

  const completedCircle = {
    id: Math.random(),
    transferId: t.id,
    title:
      t.type === Transfers.ISSUING
        ? '파트너'
        : t.type === Transfers.EARTHING
        ? '접수'
        : t.type === Transfers.RETRIEVING
        ? '세척입고'
        : t.type === Transfers.MOVING
        ? '도착'
        : '도착',
    place: t.to,
    user: t.receiver,
    at: t.updatedAt,
  }

  return [startedCircle, completedCircle]
}

export default function EarthingpackDetailsPage() {
  const [circles, setCircles] = useState<TransferCircle[]>([])
  const { id } = useParams()

  const fetchTransfers = useCallback(async () => {
    if (!id) return
    try {
      const res = await axios.get<{ transfers: Transfer[] }>(`/transfer/pack/${id}`)
      const _circles = res.data.transfers.map(t => getTransferCircles(t)).flat()
      setCircles(_circles)
    } catch (error) {
      console.log(error)
    }
  }, [id])

  useEffect(() => {
    fetchTransfers()
  }, [fetchTransfers])

  return (
    <div className="w-full p-5">
      <div className="flex flex-wrap gap-5">
        {circles.map(c => {
          const circleBg = getCircleBg(c)
          return (
            <div
              className={`grid content-center text-center w-48 h-48 rounded-full ${circleBg}`}
              key={c.id}>
              <div className="text-sm">{c.title}</div>

              <div className="mt-1 text-lg font-semibold">
                {c.place && c.user
                  ? `${c.user.name}(${c.place.name})`
                  : c.user
                  ? c.user.name
                  : c.place?.name}
              </div>

              <div className="text-gray-700 text-xs mt-1">{formatDateString(c.at)}</div>
              <div className="text-gray-700 text-xs">{formatTimeString(c.at)}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export function getCircleBg(c: TransferCircle) {
  return c.title === '이동'
    ? 'bg-gradient-to-r from-sky-100 to-sky-200'
    : c.title === '회수'
    ? 'bg-gradient-to-r from-sky-100 to-sky-200'
    : c.title === '출고' && c.place?.type === Places.FACTORY
    ? 'bg-gradient-to-r from-sky-100 to-sky-200'
    : c.title === '세척입고'
    ? 'bg-gradient-to-r from-sky-200 to-sky-300'
    : c.title === '도착'
    ? 'bg-gradient-to-r from-gray-100 to-gray-200'
    : c.title === '출고'
    ? 'bg-gradient-to-r from-gray-100 to-gray-200 border border-gray-200'
    : c.title === '파트너'
    ? 'bg-gradient-to-r from-lime-100 to-lime-200'
    : c.title === '고객'
    ? 'bg-gradient-to-r from-lime-200 to-lime-300'
    : c.title === '접수'
    ? 'bg-gradient-to-r from-lime-300 to-lime-400'
    : 'bg-white'
}
