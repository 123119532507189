import { formatDateString } from 'pages/RetrivalManager/CentersPage'

type PointEvent = {
  title: string
  createdAt: string
  point: number
  type: 'save' | 'use'
}
interface Props {
  data: PointEvent[]
}

export const ClientPointTableComponent = ({ data }: Props) => {
  return (
    <div className="mt-5 overflow-hidden">
      {data?.length === 0 ? (
        <div className="flex flex-1 h-52 items-center justify-center bg-gray-200">
          <p> 내역이 없습니다. </p>
        </div>
      ) : (
        <table className="w-full border rounded mt-2">
          <thead className="border-t border-b">
            <tr className="bg-gray-100">
              <th className="p-2 text-center">사용처</th>
              <th className="p-2 text-center">구분</th>
              <th className="p-2 text-center">포인트</th>
              <th className="p-2 text-center">날짜</th>
            </tr>
          </thead>
          <tbody className="w-full text-center">
            {data.length === 0 && (
              <tr className="">
                <td colSpan={4} className="p-2 text-center">
                  포인트 내역이 없습니다
                </td>
              </tr>
            )}

            {data.map(item => (
              <tr key={item.createdAt} className="h-12 odd:bg-white even:bg-slate-50">
                <td className="p-2 text-center">{item.title}</td>
                <td className="p-2 text-center">{item.type === 'save' ? '적립' : '사용'}</td>
                <td
                  className={
                    item.type === 'save'
                      ? 'text-green-500 p-2 text-center"'
                      : 'text-gray-600 p-2 text-center"'
                  }>
                  {item.type === 'save' ? '+' : '-'}
                  {item.point}
                </td>
                <td className="p-2 text-center">{formatDateString(item.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}
