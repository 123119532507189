import { formatDateString, formatPhoneNumber } from 'pages/RetrivalManager/CentersPage'
import React from 'react'
import { Reward } from 'types/Post'

interface Props {
  text: string
  reward?: Reward
}

export const UserListComponent = ({ text, reward }: Props) => {
  return (
    <div className="flex-1 bg-white whitespace-nowrap scrollbar-hide rounded overflow-hidden mxl:overflow-auto">
      <h2 className="text-2xl py-5">{text}</h2>
      <div className=" overflow-hidden rounded border border-gray-300">
        <table className="w-full">
          <thead className="bg-slate-200 w-full text-xl">
            <tr className="leading-9 cursor-default">
              <th className="leading-[50px] text-xl">고객명</th>
              <th className=" leading-[50px] text-xl border-l border-gray-300">전화번호</th>
              <th className=" leading-[50px] text-xl border-l border-gray-300">신청일</th>
            </tr>
          </thead>
          <tbody className="w-full overflow-auto text-center">
            {reward ? (
              reward?.coupons.map(c => (
                <tr
                  key={c.id}
                  className="leading-[50px] odd:bg-white even:bg-slate-50 cursor-pointer border-t border-gray-300">
                  <td className="">{c.user.name || '-'}</td>
                  <td className="border-l border-gray-300">
                    {formatPhoneNumber(c.user.phoneNumber)}
                  </td>
                  <td className="border-l border-gray-300">{formatDateString(c.createdAt)}</td>
                </tr>
              ))
            ) : (
              <tr className="leading-9 odd:bg-white even:bg-slate-50 cursor-pointer border-t border-gray-300">
                <td>-</td>
                <td className="border-l border-gray-300">-</td>
                <td className="border-l border-gray-300">-</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
