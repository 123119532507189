import { IconType } from 'react-icons'
import { Link, useLocation } from 'react-router-dom'

type SideBarItemType = {
  title: string
  route: string
  Icon: IconType
}

export const SideBarItem = ({ title, route, Icon }: SideBarItemType) => {
  const location = useLocation()
  const isRoute = location.pathname === route

  return (
    <li
      className={`flex items-center hover:bg-slate-800 hover:text-white ${
        isRoute && 'bg-slate-900 text-white'
      }`}>
      <Link to={route} className="flex items-center p-4 w-full gap-4 text-lg">
        <Icon />
        <span>{title}</span>
      </Link>
    </li>
  )
}
