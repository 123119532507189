import axios from 'api/axios'
import { useHQ } from 'hooks/useHQ'
import { beep } from 'lib/helpers'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAppSelector } from 'store'
import { Pack } from 'types/Pack'
import { Transfer, Transfers, TransferStatus } from 'types/Transfer'

export function AdminReceiveScanner() {
  const [packs, setPacks] = useState<Pack[]>([])
  const inputRef = useRef<HTMLInputElement>(null)
  const [movings, setMovings] = useState<Transfer[]>([])
  const [orphanMoving, setOrphanMoving] = useState<Transfer>()
  const user = useAppSelector(s => s.user)
  const { hq } = useHQ()
  const allMovings = orphanMoving ? [...movings, orphanMoving] : movings

  const totalMovingPacks = useMemo(() => {
    return movings
      .map(m => (m.status === TransferStatus.COMPLETED ? m.missingPacks : m.packs))
      .flat()
  }, [movings])

  const orphanPacks = useMemo(
    () => packs.filter(p => !totalMovingPacks.find(_p => _p.id === p.id)),
    [packs, totalMovingPacks],
  )

  useEffect(() => {
    if (!hq) return
    if (!orphanPacks.length) return

    return setOrphanMoving(om => {
      return om
        ? {
            ...om,
            packs: orphanPacks,
            giver: user,
          }
        : {
            id: -1,
            createdAt: '',
            to: hq,
            giver: user,
            packs: orphanPacks,
            missingPacks: [],
            status: TransferStatus.STARTED,
            type: Transfers.MOVING,
          }
    })
  }, [hq, orphanPacks, user])

  const getMoving = useCallback(async () => {
    if (!inputRef.current) return
    const serialNumber = inputRef.current.value

    if (packs.find(p => p.serialNumber === serialNumber)) throw new Error('이미 스캔한 팩입니다.')
    if (totalMovingPacks.find(p => p.serialNumber === serialNumber)) return

    try {
      const res = await axios.post<{ ok: boolean; moving: Transfer }>('transfer/moving', {
        serialNumber,
      })

      if (res.data.ok) return setMovings(mm => [...mm, res.data.moving])
    } catch (error) {
      console.log(error)
      throw new Error('서버에서 입고 정보를 불러오지 못했습니다.')
    }
  }, [packs, totalMovingPacks])

  const checkPack = useCallback(async () => {
    if (!inputRef.current) return
    const serialNumber = inputRef.current.value

    try {
      const res = await axios.post<{
        ok: boolean
        pack: Pack
        message?: string
        missingMoving?: Transfer
      }>('packs/check', { serialNumber })

      const { ok, message, pack, missingMoving } = res.data
      if (!ok) throw new Error(message)

      setPacks(pp => [...pp, pack])
      if (missingMoving && !movings.find(m => m.id === missingMoving.id)) {
        setMovings(mm => [...mm, missingMoving])
      }

      return pack
    } catch (error) {
      throw new Error('서버에서 팩 정보를 불러오지 못했습니다.')
    }
  }, [movings])

  const handleScan = useCallback(async () => {
    if (!inputRef.current) return

    try {
      await getMoving()
      await checkPack()
      beep()
    } catch (error) {
      beep(false)
      if (error instanceof Error) alert(error.message)
    } finally {
      inputRef.current.value = ''
    }
  }, [checkPack, getMoving])

  const reset = useCallback(() => {
    setMovings([])
    setPacks([])
    setOrphanMoving(undefined)
  }, [])

  const handleSubmit = useCallback(async () => {
    try {
      await axios.post<{ movings: Transfer[] }>('/transfer/complete-movings', {
        packs,
        orphanPacks,
        movings,
      })

      reset()
    } catch (error) {
      console.log(error)
    }
  }, [reset, movings, orphanPacks, packs])

  return (
    <div className="w-full">
      {!packs.length && (
        <div className="w-full py-10 flex items-center justify-center bg-gray-100 mt-5 text-3xl font-bold text-gray-500 rounded">
          입고스캔하세요
        </div>
      )}

      <input
        autoFocus
        ref={inputRef}
        type="text"
        onBlur={() => inputRef.current?.focus()}
        className="w-0 h-0 noKorean"
        onKeyPress={e => {
          if (e.key === 'Enter') handleScan()
        }}
      />

      {packs.length > 0 && (
        <div className="flex gap-2 justify-center my-4">
          <button
            className="px-4 py-2 bg-teal-500 text-white text-lg rounded"
            onClick={handleSubmit}>
            완료
          </button>

          <button className="px-4 py-2 bg-gray-400 text-white text-lg rounded" onClick={reset}>
            취소
          </button>
        </div>
      )}

      {allMovings.map(moving => (
        <div
          className=" border border-solid border-gray-300 rounded-md overflow-hidden mt-5"
          key={moving.id}>
          <div className="leading-10 text-2xl">
            <div className=" bg-slate-200 border-gray-300 p-5 text-center">
              {moving?.from ? moving.from.name : '출처없음'}{' '}
              <span className="font-bold">
                {
                  (moving?.status === TransferStatus.COMPLETED
                    ? moving.missingPacks
                    : moving?.packs || orphanPacks
                  ).length
                }
              </span>
              개
              {moving?.status === TransferStatus.COMPLETED && (
                <span className="ml-4 text-base text-red-400">누락분</span>
              )}
            </div>
          </div>

          <ul className="text-lg px-4 py-2">
            {(moving?.status === TransferStatus.COMPLETED
              ? moving.missingPacks
              : moving?.packs || packs
            ).map(p => (
              <li className="leading-7 text-center" key={p.id}>
                <span
                  className={`text-base ${
                    packs.find(_p => _p.id === p.id)
                      ? 'font-bold text-emerald-600'
                      : 'text-gray-300'
                  }`}>
                  {p.serialNumber}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}
