import axios from 'api/axios'
import { useEffect } from 'react'

const useAxios = () => {
  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      response => response,
      async error => {
        const prevRequest = error.config

        if (error.response.status === 401 && !prevRequest.sent) {
          prevRequest.sent = true

          const res = await axios.get<{ accessToken: string }>('/auth/refresh-token', {
            withCredentials: true,
          })

          // const accessToken = res.data.accessToken
          const { accessToken } = res.data

          axios.defaults.headers.common.authorization = `Bearer ${accessToken}`
          prevRequest.headers.authorization = `Bearer ${accessToken}`

          return axios(prevRequest)
        }

        throw error
      },
    )

    return () => axios.interceptors.response.eject(responseInterceptor)
  }, [])
}

export default useAxios
