import React from 'react'

function LocationAgree() {
  return (
    <div className="terms_container">
      <article id="40f5119e-9178-462b-9150-a2046f06afbf" className="page sans">
        <header>
          <h1 className="page-title"> </h1>
        </header>
        <div className="page-body">
          <p id="a9cfbd26-f3c3-4e89-b238-c98b6cc84f35" className="">
            <strong>얼싱팩 앱(APP) 위치정보 이용약관</strong>
          </p>
          <p id="7093e8a9-20e6-4250-912a-16a800d00b2c" className="">
            <strong>제1조 (목적)</strong>
          </p>
          <p id="0f4b898d-8cce-40cc-9b83-a99cd3a4db98" className="">
            본 약관은 주식회사 샵플(이하 “회사”라 합니다)이 운영하는 리유저블 택배파우치 – 얼싱팩
            “웹사이트” 와 “애플리케이션”(이하 “웹사이트” 와 “애플리케이션”을 “APP”이라고 합니다)
            위치정보사업 또는 위치기반서비스사업과 관련하여 회사와 개인위치정보주체와의 권리, 의무
            및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
          </p>
          <p id="07045eb9-1c1e-44e9-9f7b-bbc72366528a" className="">
            <strong>제2조 (약관 외 준칙)</strong>
          </p>
          <p id="32e002f8-b4af-4ac2-b3d6-6194883f4ae3" className="">
            본 약관에 명시되지 않은 사항은 위치정보의 보호 및 이용 등에 관한 법률, 정보통신망
            이용촉진 및 정보보호 등에 관한 법률, 전기통신기본법, 전기통신사업법 등 관계법령과 회사의
            이용약관 및 개인정보처리방침, 회사가 별도로 정한 지침 등에 의합니다.
          </p>
          <p id="70ed061a-dffa-4f20-bc0a-e449a1aa9301" className="">
            <strong>제3조 (서비스 내용)</strong>
          </p>
          <p id="a1374977-2935-4081-95dc-daf39e6b12b6" className="">
            회사는 직접 위치정보를 수집하거나 위치정보사업자로부터 위치정보를 전달받아 아래와 같은
            위치기반서비스를 제공합니다.
          </p>
          <ol
            type="1"
            id="8184539f-3e34-434a-bc56-1a54b6a6d392"
            className="numbered-list"
            start={1}>
            <li>
              얼싱팩 서비스: 게시물에 포함된 개인위치정보주체 또는 이동성 있는 기기의 위치정보가
              게시물과 함께 저장됩니다.
            </li>
          </ol>
          <ol
            type="1"
            id="4bf2ba88-cd94-46cc-b4aa-1447fe076a35"
            className="numbered-list"
            start={2}>
            <li>
              위치정보를 활용한 얼싱팩 반납센터 찾기: 현재 위치를 활용하여 얼싱팩 반납센터를
              찾아주거나 반납센터를 추천하여 줍니다.
            </li>
          </ol>
          <ol
            type="1"
            id="b108aa69-8fd4-481a-81db-c0e2ba05babd"
            className="numbered-list"
            start={3}>
            <li>
              얼싱팩 회수: 위치정보를 활용하여 반납센터에서 회수 매니저가 얼싱팩을 회수합니다.
            </li>
          </ol>
          <ol
            type="1"
            id="d46a35d2-eb20-4751-b644-3e04e086b7fd"
            className="numbered-list"
            start={4}>
            <li>
              이용자 보호 및 부정 이용 방지: 개인위치정보주체 또는 이동성 있는 기기의 위치를
              이용하여 권한 없는 자의 비정상적인 서비스 이용 시도 등을 차단합니다.
            </li>
          </ol>
          <ol
            type="1"
            id="930c53e1-d358-40a6-986b-e0ca65d019dc"
            className="numbered-list"
            start={5}>
            <li>
              위치정보 공유: 개인위치정보주체 또는 이동성 있는 기기의 위치정보를 얼싱팩 이용 및
              얼싱팩 반납 회수 서비스를 목적으로 개인위치정보주체가 지정한 에게 공유합니다.
            </li>
          </ol>
          <p id="d3b497de-9d57-4032-8f5a-8b5008393b9d" className="">
            <strong>제4조 (개인위치정보주체의 권리)</strong>
          </p>
          <p id="6989db2e-c518-4f41-9a0b-06392f2d12a2" className="">
            ① 개인위치정보주체는 개인위치정보 수집 범위 및 이용약관의 내용 중 일부 또는
            개인위치정보의 이용ㆍ제공 목적, 제공받는 자의 범위 및 위치기반서비스의 일부에 대하여
            동의를 유보할 수 있습니다.
          </p>
          <p id="105edcab-ec13-4d77-8bf1-f86862634e6a" className="">
            ② 개인위치정보주체는 개인위치정보의 수집ㆍ이용ㆍ제공에 대한 동의의 전부 또는 일부를
            철회할 수 있습니다.
          </p>
          <p id="52b9c92d-1ff1-4d42-985c-543f0a189de0" className="">
            ③ 개인위치정보주체는 언제든지 개인위치정보의 수집ㆍ이용ㆍ제공의 일시적인 중지를 요구할
            수 있습니다. 이 경우 회사는 요구를 거절하지 아니하며, 이를 위한 기술적 수단을 갖추고
            있습니다
          </p>
          <p id="2a4ef896-b67c-497c-af5f-0292018972cf" className="">
            ④ 개인위치정보주체는 회사에 대하여 아래 자료의 열람 또는 고지를 요구할 수 있고, 당해
            자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우 회사는 정당한 이유
            없이 요구를 거절하지 아니합니다.
          </p>
          <ol
            type="1"
            id="b2eb83a7-9d76-4210-ae09-31e428c965a3"
            className="numbered-list"
            start={1}>
            <li>개인위치정보주체에 대한 위치정보 수집ㆍ이용ㆍ제공사실 확인자료</li>
          </ol>
          <ol
            type="1"
            id="87bfd3c7-77d2-4c52-a0f3-3020700ba0fd"
            className="numbered-list"
            start={2}>
            <li>
              개인위치정보주체의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른
              법령의 규정에 의하여 제3자에게 제공된 이유 및 내용
            </li>
          </ol>
          <p id="d8451e41-dc9c-4268-98e7-54a414a897ef" className="">
            ⑤ 회사는 개인위치정보주체가 동의의 전부 또는 일부를 철회한 경우에는 지체 없이 수집된
            개인위치정보 및 위치정보 수집ㆍ이용ㆍ제공사실 확인자료를 파기합니다. 단, 동의의 일부를
            철회하는 경우에는 철회하는 부분의 개인위치정보 및 위치정보 수집ㆍ이용ㆍ제공사실
            확인자료에 한합니다.
          </p>
          <p id="bc8a40e4-db66-4b86-ae3c-c6a3e49e4fb0" className="">
            ⑥ 개인위치정보주체는 제1항 내지 제4항의 권리행사를 위하여 본 약관 제10조의 연락처를
            이용하여 회사에 요구할 수 있습니다.
          </p>
          <p id="96f13fb5-bd1b-498e-9c0a-5e3809b53b77" className="">
            <strong>제5조 (위치정보 이용ㆍ제공사실 확인자료 보유근거 및 보유기간)</strong>
          </p>
          <p id="3727d5c4-6f4a-4eef-9152-bec679ffa414" className="">
            회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여 개인위치정보주체에
            대한 위치정보 수집ㆍ이용ㆍ제공사실 확인자료를 위치정보시스템에 자동으로 기록하며, 6개월
            이상 보관합니다.
          </p>
          <p id="2596d47b-f384-4b87-be75-e63a4d9eb6b4" className="">
            <strong>제6조 (서비스의 변경 및 중지)</strong>
          </p>
          <p id="fdb5c88a-bc4f-4639-b39e-3751501a24c0" className="">
            ① 회사는 위치정보사업자의 정책변경 등과 같이 회사의 제반 사정 또는 법률상의 장애 등으로
            서비스를 유지할 수 없는 경우, 서비스의 전부 또는 일부를 제한, 변경하거나 중지할 수
            있습니다.
          </p>
          <p id="07ed1f80-718c-4370-aea8-c7acf03cf16b" className="">
            ② 제1항에 의한 서비스 중단의 경우에는 회사는 사전에 인터넷 등에 공지하거나
            개인위치정보주체에게 통지합니다.
          </p>
          <p id="d9cf4202-7e91-4401-acf2-b2214c7569e8" className="">
            <strong>제7조 (개인위치정보 제3자 제공 시 즉시 통보)</strong>
          </p>
          <p id="aa38be2a-b04c-4ad2-8146-d6dee49dde06" className="">
            ① 회사는 개인위치정보주체의 동의 없이 당해 개인위치정보주체의 개인위치정보를 제3자에게
            제공하지 아니하며, 제3자 제공 서비스를 제공하는 경우에는 제공받는 자 및 제공목적을
            사전에 개인위치정보주체에게 고지하고 동의를 받습니다.
          </p>
          <p id="4b1a1e20-e2ff-4646-94d3-bff01d3e817a" className="">
            ② 회사는 개인위치정보를 개인위치정보주체가 지정하는 제3자에게 제공하는 경우에는
            개인위치정보를 수집한 당해 통신단말장치로 매회 개인위치정보주체에게 제공받는 자,
            제공일시 및 제공목적을 즉시 통보합니다.
          </p>
          <p id="7c76cfcc-b6eb-467f-8f63-88c542a6a1f5" className="">
            ③ 다만, 아래에 해당하는 경우에는 개인위치정보주체가 미리 특정하여 지정한 통신단말장치
            또는 전자우편주소 등으로 통보합니다.
          </p>
          <ol
            type="1"
            id="29aa512a-4852-4c6a-9922-1174101dafe4"
            className="numbered-list"
            start={1}>
            <li>
              개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지
              아니한 경우
            </li>
          </ol>
          <ol
            type="1"
            id="99573fee-72fa-40b8-84be-29669786ccf1"
            className="numbered-list"
            start={2}>
            <li>
              개인위치정보주체가 개인위치정보를 수집한 당해 통신단말장치 외의 통신단말장치 또는
              전자우편주소 등으로 통보할 것을 미리 요청한 경우
            </li>
          </ol>
          <p id="c46b53a9-70be-45d6-8a43-9d93f8b4e490" className="">
            <strong>제8조 (손해배상)</strong>
          </p>
          <p id="d95b3f92-1f61-44a4-b62a-ef5c83fef109" className="">
            개인위치정보주체는 회사의 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 26조의
            규정을 위반한 행위로 손해를 입은 경우에 회사에 대하여 손해배상을 청구할 수 있습니다. 이
            경우 회사는 고의 또는 과실이 없음을 입증하지 아니하면 책임을 면할 수 없습니다.
          </p>
          <p id="af563dcc-3295-45ef-8119-d8db4922b6d0" className="">
            <strong>제9조 (분쟁의 조정)</strong>
          </p>
          <p id="0d2ed5de-bf49-46f3-b8bc-52b27387e914" className="">
            ① 회사는 위치정보와 관련된 분쟁에 대하여 개인위치정보주체와 협의가 이루어지지 아니하거나
            협의를 할 수 없는 경우에는 방송통신위원회에 재정을 신청할 수 있습니다.
          </p>
          <p id="b6820a90-5fd5-43f1-953f-c13078afd399" className="">
            ② 회사 또는 개인위치정보주체는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지
            아니하거나 협의를 할 수 없는 경우에는 개인정보보호법에 따라 개인정보분쟁조정위원회에
            조정을 신청할 수 있습니다.
          </p>
          <p id="618228a5-ea48-4a42-96d9-90f9d44dac79" className="">
            <strong>제10조 (사업자 정보)</strong>
          </p>
          <p id="413c7910-fcdb-435f-ba1b-217b5b15f0e8" className="">
            회사의 상호, 주소, 전화번호 그 밖의 연락처는 다음과 같습니다.
          </p>
          <p id="536f19dd-1099-4663-91e1-1cf118db474f" className="">
            상호: 주식회사 샵플
          </p>
          <p id="c4e563fd-aa4f-49f6-9dd1-1a2b81172b93" className="">
            주소:  경기도 성남시 수정구 성남대로 1182  6층
          </p>
          <p id="4efd2325-c43c-41d1-95a0-916de01cf51c" className="">
            전화번호: 070-8670-2758
          </p>
          <p id="84c61697-6a10-435c-9b53-3ff8cf2b1fb6" className="">
            <strong>부칙</strong>
          </p>
          <p id="f353f770-8fc6-4ef5-b55e-4f4dba980cb6" className="">
            <strong>제1조 시행일</strong>
          </p>
          <p id="23ed2396-81f0-4e1c-83ca-889ae573d6ba" className="">
            2022년 9월 29일부터 시행됩니다.
          </p>
          <p id="159439c1-cf90-4d38-ac30-e0557ab7dca7" className="">
            <strong>제2조 위치정보관리 책임자 정보</strong>
          </p>
          <p id="224e5091-1772-4b8f-a40c-46070e91568b" className="">
            회사는 다음과 같이 위치정보 관리책임자를 지정하여 이용자들이 서비스 이용과정에서 발생한
            민원사항 처리를 비롯하여 개인위치정보주체의 권리 보호를 위해 힘쓰고 있습니다.
          </p>
          <p id="33a2a6c5-672f-4529-84c9-39744d6fe0c7" className="">
            위치정보 관리책임자: (개인)정보보호 담당 부서 임원(개인정보 보호책임자 겸직)
          </p>
          <p id="9e836336-9dd4-41da-a895-2d6c9e8c45e6" className="">
            전화번호: 070-8670-2758
          </p>
        </div>
      </article>
    </div>
  )
}

export default LocationAgree
