import axios from 'api/axios'
import { usePostRange } from 'hooks/usePostRange'
import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useEffect, useState } from 'react'
import { IoMdList } from 'react-icons/io'
import { MdDelete } from 'react-icons/md'
import { RiEditFill } from 'react-icons/ri'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from 'store'
import { Post } from 'types/Post'

export const NoticeDetailsPage = () => {
  const navigate = useNavigate()
  const { noticeId } = useParams()
  const [notice, setNotice] = useState<Post>()
  const { updateRange, renderPostRange } = usePostRange({ readOnly: true })
  const roles = useAppSelector(s => s.user.roles.find(r => r.type === 'admin'))

  const deleteNotice = async () => {
    if (!window.confirm('삭제하시겠습니까?')) return

    try {
      await axios.delete(`/post/${noticeId}`)

      navigate('/setting')
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!noticeId) return

    const fetchNotice = async () => {
      const res = await axios.get<{ post: Post }>(`post/${noticeId}`)

      setNotice(res.data.post)
      updateRange(res.data.post.range)
    }

    fetchNotice()
  }, [noticeId, updateRange])

  if (!notice) return null // fallback

  return (
    <div className="flex flex-col flex-1 w-full h-full p-5 mxl:p-5">
      <div className="flex flex-col pb-2 text-lg border-b-2 border-gray-300 border-solid mxl:pb-5">
        <div className="flex items-center justify-between mxl:flex-col mxl:items-start h-12">
          <h2 className="text-2xl">{notice.title}</h2>
          {roles && renderPostRange()}
        </div>
        <div className="flex justify-between w-full mt-2">
          <span className="text-gray-500 ">{formatDateString(notice.createdAt)}</span>
          <div className="flex">
            {roles && (
              <>
                <button
                  className=" flex items-center text-blue-600 border border-blue-600 hover:bg-blue-800 hover:border-blue-700 hover:text-white px-2 py-1 rounded"
                  onClick={() => {
                    navigate('edit')
                  }}>
                  <RiEditFill />
                  수정
                </button>
                <button
                  className="flex items-center text-red-600 border  border-red-600 hover:bg-red-600 hover:border-red-600 hover:text-white px-2 py-1 rounded ml-2"
                  onClick={deleteNotice}>
                  <MdDelete />
                  삭제
                </button>
              </>
            )}

            <button
              className="flex items-center border rounded border-gray-400 text-gray-500 px-2 py-1 ml-2 hover:text-white hover:bg-gray-400"
              onClick={() => {
                roles ? navigate('/setting') : navigate('/setting/notice')
              }}>
              <IoMdList />
              목록보기
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-5 py-5">
        <div
          className="overflow-auto bg-white web_inner_html"
          dangerouslySetInnerHTML={{ __html: notice.body }}></div>
      </div>
    </div>
  )
}
