import axios from 'api/axios'
import { Roles } from 'enums/Roles'
import { useCallback, useEffect, useState } from 'react'
import { IoMdList } from 'react-icons/io'
import { RiEditFill } from 'react-icons/ri'
import { useNavigate, useParams } from 'react-router-dom'
import { Place } from 'types/Place'
import { formatDateString } from './CentersPage'

export const FactoryCentersDetailsPage = () => {
  const { centerId } = useParams()
  const [center, setCenter] = useState<Place>()
  const navigate = useNavigate()

  const fetchCenter = useCallback(async () => {
    try {
      const res = await axios.get<{ place: Place }>(`/places/${centerId}`)
      setCenter(res.data.place)
    } catch (error) {
      console.log(error)
    }
  }, [centerId])

  useEffect(() => {
    fetchCenter()
  }, [fetchCenter])

  if (!center) return null

  return (
    <div className="flex-1 p-5">
      <div className="">
        <div className=" flex items-center justify-between h-12 mb-2">
          <h2 className="text-2xl">반납센터</h2>
          <div className="flex">
            <button
              className=" flex items-center text-blue-600 border border-blue-600 hover:bg-blue-800 hover:border-blue-700 hover:text-white px-2 py-1 rounded"
              onClick={() => {
                navigate('edit')
              }}>
              <RiEditFill />
              수정
            </button>

            <button
              className="flex items-center border rounded border-gray-400 text-gray-500 px-2 py-1 ml-2 hover:text-white hover:bg-gray-400"
              onClick={() => {
                navigate('/centers')
              }}>
              <IoMdList />
              목록보기
            </button>
          </div>
        </div>
        <div className="flex w-full py-5 border-y border-gray-300 h-28">
          <dl className="flex flex-col justify-center pr-5">
            <dt className="font-bold text-cent">반납센터명</dt>
            <dd className="">{center.name ? center.name : '-'}</dd>
          </dl>
          <dl className="flex flex-col justify-center px-5">
            <dt className="font-bold text-cent">전화번호</dt>
            <dd className="">
              {center.phoneNumber
                ? center.phoneNumber.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                : '-'}
            </dd>
          </dl>
          <dl className="flex flex-col justify-center px-5">
            <dt className="font-bold">주소</dt>
            <dd className="">
              {center.address.address
                ? `${center.address.address} ${center.address.extraAddress}`
                : '-'}
            </dd>
            <dd>
              {center.address.roadAddress
                ? `${center.address.roadAddress} ${center.address.extraAddress}`
                : '-'}
            </dd>
          </dl>
          <dl className="flex flex-col justify-center px-5">
            <dt className="font-bold">담당자</dt>
            <dd className="">
              {center.roles ? center.roles.find(r => r.type === Roles.CHIEF)?.user?.name : '-'}
            </dd>
          </dl>
          <dl className="flex flex-col justify-center px-5">
            <dt className="font-bold">이메일</dt>
            <dd className="">{center.email ? center.email : '-'}</dd>
          </dl>
        </div>
      </div>

      {center.retriever && (
        <div className="mt-2">
          <h2 className="py-5 text-2xl">회수매니저</h2>
          <ul
            className="grid grid-cols-4 gap-4 mt-5"
            onClick={() => navigate(`/retrievers/${center.retriever?.id}`)}>
            <li className="border-gray-300 border-2 border-solid rounded-md px-5 py-3 hover:border-blue-600">
              <div className="p-1">
                <span className="font-bold">이름 : </span>
                {center.retriever.name ? center.retriever.name : '-'}
              </div>
              <div className="p-1">
                <span className="font-bold">전화번호 : </span>
                {center.retriever.phoneNumber
                  ? center.retriever.phoneNumber.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                  : '-'}
              </div>
              <div className="p-1">
                <span className="font-bold">가입일 : </span>
                {formatDateString(center.retriever.createdAt)}
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}
