import axios from 'api/axios'
import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Post } from 'types/Post'

export const PostWebViewPage = () => {
  const { postId } = useParams()

  // /web_view/:postId?userId=123&foo=bar&point=10
  // const [searchParams] = useSearchParams()
  // console.log(searchParams.get('userId')) // 123
  // console.log(searchParams.get('foo')) // bar
  // console.log(searchParams.get('point')) // 10

  const [post, setPost] = useState<Post>()

  useEffect(() => {
    const fatchPost = async () => {
      try {
        const res = await axios.get<{ post: Post }>(`/post/${postId}`)
        setPost(res.data.post)
      } catch (error) {
        console.log(error)
      }
    }
    fatchPost()
  }, [postId])

  if (!post) return null

  return (
    <div className="w-full h-full">
      {post.title && (
        <div className="flex-1 px-4 pb-4 mt-1 border-b-2 border-gray-300 border-solid">
          {post.title && <h2 className="text-2xl font-bold">{post?.title ? post.title : '-'}</h2>}
          <div className="mt-1 text-lg text-gray-500">{formatDateString(post.createdAt)}</div>
        </div>
      )}

      <div
        className={`overflow-auto bg-white mobile_inner_html ${post.title ? 'py-5' : ''}`}
        dangerouslySetInnerHTML={post && { __html: post?.body }}
      />
    </div>
  )
}
