import axios from 'api/axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { storeUser } from 'store/userSlice'
import { User } from 'types/User'

export const LoginPage = () => {
  const navigate = useNavigate()
  const [identifier, setIdentifier] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const dispatch = useAppDispatch()

  const loginValidation = async () => {
    if (identifier === '' || password === '') return alert('아이디와 비밀번호를 확인해주세요')

    try {
      const res = await axios.post<{ user: User; accessToken: string }>(
        '/auth/local/login',
        {
          identifier: identifier,
          password: password,
        },
        { withCredentials: true },
      )

      const { accessToken, user } = res.data

      dispatch(
        storeUser({
          id: user.id,
          name: user.name,
          roles: user.roles,
        }),
      )

      axios.defaults.headers.common.authorization = `Bearer ${accessToken}`

      // 어딘가에 accesToken, refreshToken
      navigate('/')
    } catch (error) {
      console.log('sign in error', error)

      alert('로그인 실패했습니다')
    } finally {
      setPassword('')
      setIdentifier('')
    }
  }

  return (
    <div className="flex items-center justify-center w-full h-screen ">
      <div className="bg-white p-10 w-[400px] rounded-2xl  flex flex-col gap-8  border border-solid border-gray-300">
        <h2 className="text-2xl font-bold">로그인</h2>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <label>아이디</label>
            <input
              type="text"
              className="w-full px-2 leading-10 border border-gray-300 border-solid rounded-md"
              placeholder="아이디"
              value={identifier}
              onChange={e => setIdentifier(e.target.value)}
              onKeyPress={e => {
                if (e.key === 'Enter') loginValidation()
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="" className="">
              비밀번호
            </label>
            <input
              type="password"
              className="w-full px-2 leading-10 border border-gray-300 border-solid rounded-md"
              placeholder="비밀번호"
              value={password}
              onChange={e => setPassword(e.target.value)}
              onKeyPress={e => {
                if (e.key === 'Enter') loginValidation()
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <button className="  bg-[#bbc4ce] rounded-full py-3 w-full" onClick={loginValidation}>
            로그인
          </button>
        </div>
      </div>
    </div>
  )
}
