import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Pack } from '../types/Pack'

const initialState: Pack = {
  id: -1,
  serialNumber: '',
  status: 'cleaning',
}

const packSlice = createSlice({
  name: 'pack',
  initialState,
  reducers: {
    clearPack: state => {
      Object.assign(state, initialState)
    },
    storePack: (state, action: PayloadAction<Partial<Pack>>) => {
      const pack = action.payload
      if (pack.id) state.id = pack.id
      if (pack.serialNumber) state.serialNumber = pack.serialNumber
    },
  },
  extraReducers: _ => {}, // async reducers here
})

// export action creators
export const { clearPack, storePack } = packSlice.actions

// export selectors
export default packSlice
