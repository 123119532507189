import { Pack } from './Pack'
import { Place } from './Place'
import { User } from './User'

export enum Transfers {
  EARTHING = 'earthing',
  RETRIEVING = 'retrieving',
  MOVING = 'moving',
  ISSUING = 'issuing',
}

export enum TransferStatus {
  STARTED = 'started',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
}

export type Transfer = {
  id: number
  createdAt: string
  updatedAt?: string
  packs: Pack[]
  missingPacks: Pack[]
  status: TransferStatus
  type: Transfers
  giver: User
  receiver?: User
  from?: Place
  to: Place
}
