import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useNavigate } from 'react-router-dom'
import { Post } from 'types/Post'

interface Props {
  events: Post[]
}

export const EventTableBoxComponent = ({ events }: Props) => {
  const navigate = useNavigate()

  return (
    <div className="flex-1 overflow-auto whitespace-nowrap scrollbar-hide">
      <div className=" overflow-hidden rounded">
        <table className="w-full border rounded mt-2">
          <thead className="border-t border-b">
            <tr className="bg-gray-100">
              <th className="p-2 text-center">배너 사진</th>
              <th className="p-2 text-center">등록일</th>
            </tr>
          </thead>
          <tbody className="w-full overflow-auto text-center">
            {events.map(event => (
              <tr
                className="leading-9 odd:bg-white even:bg-slate-50 cursor-pointer"
                key={event.id}
                onClick={() => navigate(`event/${event.id}`)}>
                <td className="w-9/12">
                  {event.bannerUrl && (
                    <img
                      className="m-auto object-contain h-32"
                      src={event.bannerUrl}
                      alt="bannerUrl"
                    />
                  )}
                </td>
                <td>{formatDateString(event.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
