import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'api/axios'
import { Place } from 'types/Place'
import { Roles } from 'enums/Roles'
import { formatDateString, formatPhoneNumber } from 'pages/RetrivalManager/CentersPage'

export default function PartnerPage() {
  const navigate = useNavigate()
  return (
    <div className="w-full p-5">
      <div className="flex items-center justify-between">
        <h2 className="">파트너</h2>

        <button
          className="rounded text-white bg-teal-500 px-4 py-2"
          onClick={() => navigate(`/partner/add`)}>
          새 파트너 추가
        </button>
      </div>

      <PartnerList />
    </div>
  )
}

export const PartnerList = () => {
  const [partners, setPartners] = useState<Place[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const res = await axios.get<{ places: Place[] }>('/places?type=partner')
        const { places: partners } = res.data
        setPartners(partners)
      } catch (error) {
        console.log('data error', error)
      }
    }
    fetchPartners()
  }, [])

  const openPartnerDetails = (partnerId: number) => {
    navigate(`/partner/${partnerId}`)
  }

  return (
    <table className="w-full border rounded mt-2 text-center">
      <thead className="border-t border-b">
        <tr className="bg-gray-100">
          <th className="p-2">파트너</th>
          <th className="p-2">대표자</th>
          <th className="p-2">전화번호</th>
          <th className="p-2">팩</th>
          <th className="p-2">가입일</th>
          <th className="p-2">액션</th>
        </tr>
      </thead>

      <tbody className="w-full text-center">
        {partners.length === 0 && (
          <tr className="">
            <td className="p-2" colSpan={4}>
              등록된 파트너가 없습니다
            </td>
          </tr>
        )}

        {partners.map(partner => (
          <tr
            className="hover:bg-gray-100 even:bg-gray-50"
            key={partner.id}
            onClick={() => openPartnerDetails(partner.id)}>
            <td className="p-2">{partner.name || '-'}</td>
            <td className="p-2">
              {partner.roles.find(r => r.type === Roles.CHIEF)?.user.name || '-'}
            </td>
            <td className="p-2">{formatPhoneNumber(partner.phoneNumber)}</td>
            <td className="p-2">{partner.packs.length}</td>
            <td className="p-2">{formatDateString(partner.createdAt)}</td>
            <td className="p-2">
              <button
                onClick={e => {
                  e.stopPropagation()
                  navigate('/earthingpack/scan?mode=release&partnerId=' + partner.id)
                }}
                className={`text-white px-2 rounded bg-teal-500`}>
                출고하기
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
