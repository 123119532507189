import { SubHeaderComponent } from 'components/SubHeaderComponent'

export const DataPage = () => {
  return (
    <div className="flex-1 flex flex-col p-5 bg-white">
      <SubHeaderComponent text="데이터 관리" />
      {/* <DataTableBox openModal={handleClick} datas={datas} /> */}
    </div>
  )
}
