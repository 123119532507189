import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Places } from 'enums/Places'
import { Roles } from 'enums/Roles'
import { User } from 'types/User'
import { RootState } from './reducer'

const initialState: User = {
  id: -1,
  createdAt: '',
  identifier: '',
  name: '',
  phoneNumber: '',
  profileImageUrl: '',
  roles: [],
  agreement: {
    terms: false,
    privacy: false,
    location: false,
    marketing: false,
  },
  centers: [],
  coupons: [],
  packs: [],
  points: -1,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: state => {
      Object.assign(state, initialState)
    },
    storeUser: (state, action: PayloadAction<Partial<User>>) => {
      const user = action.payload

      if (user.id) state.id = user.id
      if (user.name) state.name = user.name
      if (user.roles && user.roles?.length > 0) state.roles = user.roles
      if (user.centers) state.centers = user.centers
    },
  },
  extraReducers: _ => {}, // async reducers here
})

// export action creators
export const { clearUser, storeUser } = userSlice.actions

export const selectIsLoggedIn = (state: RootState) => state.user.id > 0
export const selectIsAdmin = (state: RootState) =>
  state.user.id > 0 && state.user.roles.filter(r => r.type === Roles.ADMIN).length > 0
export const selectIsChief = (state: RootState) =>
  state.user.id > 0 && state.user.roles.filter(r => r.type === Roles.CHIEF).length > 0
export const selectIsMember = (state: RootState) =>
  state.user.id > 0 && state.user.roles.filter(r => r.type === Roles.MEMBER).length > 0
export const selectIsFactoryWorker = (state: RootState) =>
  state.user.id > 0 && state.user.roles.filter(r => r.place?.type === Places.FACTORY).length > 0
export const selectIsHQWorker = (state: RootState) =>
  state.user.id > 0 && state.user.roles.filter(r => r.place?.type === Places.HQ).length > 0

// export selectors
export default userSlice
