import { ScanMode } from 'pages/Factory/FactoryPackScanPage'

export type ScanModeButtonProps = {
  mode: ScanMode
  selectedMode: ScanMode
  label: string
  onClick: () => void
}
export function ScanModeButton({ mode, selectedMode, label, onClick }: ScanModeButtonProps) {
  return (
    <button
      className={` px-14 py-5 text-3xl m2xl:p-0 m2xl:py-6 m2xl:px-5 rounded-full ${
        mode === selectedMode
          ? 'bg-blue-600 text-white rounded-full hover:bg-blue-800'
          : 'bg-slate-200 hover:bg-slate-300'
      }`}
      onClick={onClick}>
      {label}
    </button>
  )
}
