import axios from 'api/axios'
import { Posts } from 'enums/Posts'
import { useState } from 'react'
import { makeId } from 'lib/makeId'

export function BannerInputPreviewComponent({
  type,
  bannerUrl,
  setBannerUrl,
}: {
  type: Posts
  bannerUrl: string | undefined
  setBannerUrl: React.Dispatch<React.SetStateAction<string | undefined>>
}) {
  const [mouseOver, setMouseOver] = useState(false)

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const file = e.target.files[0]

    if (!file) return
    const prefix = type
    const fileName = makeId(10) + '.' + file.name.split('.')[1]
    try {
      const presignedUrl = await axios.post('/upload/presigned-url', { prefix, fileName })

      await fetch(
        new Request(presignedUrl.data.url, {
          method: 'PUT',
          body: file,
          headers: new Headers({
            'Content-Type': file.type,
          }),
        }),
      )

      setBannerUrl(
        `https://earthingpack-manager.s3.ap-northeast-2.amazonaws.com/${prefix}/${fileName}`,
      )
    } catch (error) {}
  }

  if (!bannerUrl)
    return (
      <div className="py-5">
        <label
          htmlFor="bannerImg"
          className=" bg-blue-500 text-white px-10 py-2 rounded cursor-pointer hover:bg-blue-600">
          배너 이미지 추가
        </label>
        <input
          id="bannerImg"
          className=" hidden"
          type="file"
          onChange={handleFileChange}
          accept="image/*"
        />
      </div>
    )

  return (
    <div
      className="relative"
      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}>
      <img className="h-60 object-contain m-auto mt-2" src={bannerUrl} alt="배너" />

      {mouseOver && (
        <div className="absolute w-full h-full bg-black bg-opacity-30 inset-0 flex items-center justify-center">
          <button
            onClick={() => {
              setBannerUrl(undefined)
              setMouseOver(false)
            }}
            className="px-3 py-2 bg-white text-gray-500 rounded hover:bg-blue-500 hover:text-white">
            이미지 삭제
          </button>
        </div>
      )}
    </div>
  )
}
