import axios from 'api/axios'
import { CertificateImageModal } from 'components/CertificateImageModal'
import { PostCodeComponent } from 'components/PostCodeComponent'
import { Roles } from 'enums/Roles'
import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Place } from 'types/Place'

export const CenterUpdatePage = () => {
  const { centerId } = useParams()
  const navigate = useNavigate()
  const [center, setCenter] = useState<Place>()
  const [address, setAddress] = useState('')
  const [roadAddress, setRoadAddress] = useState('')
  const [extraAddress, setExtraAddress] = useState('')
  const [email, setEmail] = useState('')
  const [bank, setBank] = useState('')
  const [ownerName, setOwnerName] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [factories, setFactories] = useState<Place[]>([])
  const [factory, setFactory] = useState<Place>()

  const updateCenterData = useCallback(async () => {
    try {
      await axios.patch<{ place: Place }>(`/places/${centerId}`, {
        address: { address, roadAddress, extraAddress },
        bankAccount: { bank, accountNumber, ownerName },
        email,
        factory,
      })
      navigate(`/center/${centerId}`)
    } catch (error) {
      console.log(error)
    }
  }, [
    accountNumber,
    address,
    bank,
    centerId,
    email,
    extraAddress,
    factory,
    navigate,
    ownerName,
    roadAddress,
  ])

  const deleteCenterData = async () => {
    if (
      !window.confirm(
        '정말로 삭제 하시겠습니까?\n삭제시 해당 반납센터의 보유 팩을 확인 할 수 없습니다. ',
      )
    )
      return
    try {
      await axios.delete(`/places/${centerId}`)

      navigate('/center')
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const centerCenter = async () => {
      try {
        const res = await axios.get<{ place: Place }>(`/places/${centerId}`)
        const { place } = res.data
        setCenter(place)
        setAddress(place.address.address)
        setRoadAddress(place.address.roadAddress)
        setExtraAddress(place.address.extraAddress)
        setEmail(place.email || '')
        setBank(place.bankAccount?.bank || '')
        setAccountNumber(place.bankAccount?.accountNumber || '')
        setOwnerName(place.bankAccount?.ownerName || '')
        setFactory(place.factory)
      } catch (error) {
        console.log(error)
      }
    }
    centerCenter()
  }, [centerId])

  useEffect(() => {
    const fetchFactories = async () => {
      try {
        const res = await axios.get<{ places: Place[] }>('/places?type=factory')
        setFactories(res.data.places)
      } catch (error) {
        console.log(error)
      }
    }
    fetchFactories()
  }, [])

  if (!center) return null

  return (
    <div className="flex p-5 flex-1 justify-center gap-10">
      <div className="flex gap-5 flex-col mxl:border-0 mxl:px-0 w-full">
        <h2 className="flex justify-between items-center border-b border-solid border-gray-300 h-12 mxl:p-2 text-2xl">
          수정하기
        </h2>
        <div className="border border-solid border-gray-300">
          <dl className="flex flex-wrap">
            <dl className="flex w-full h-[244px]">
              <dl className="flex-col w-5/6 flex-wrap border-b border-r border-solid border-gray-300">
                <dl className="flex">
                  <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/5 flex items-center border-solid border-gray-300 mxl:w-[35%] font-bold leading-10">
                    반납센터명
                  </dt>
                  <dd className=" py-2 px-5 w-4/5 flex items-center mxl:w-[65%] leading-10 border-l border-gray-300">
                    {center?.name ? center.name : '-'}
                  </dd>
                </dl>

                <dl className="flex">
                  <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/5 border-t border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10">
                    대표명
                  </dt>
                  <dd className=" py-2 px-5 w-4/5 border-t border-l border-solid border-gray-300 flex items-center mxl:w-[65%] leading-10">
                    {center?.roles.find(r => r.type === Roles.CHIEF)?.user?.name}
                  </dd>
                </dl>

                <dl className="flex">
                  <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/5 border-t border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10">
                    전화번호
                  </dt>
                  <dd className=" py-2 px-5 border-t w-4/5 border-l border-solid border-gray-300 flex items-center mxl:w-[65%]">
                    {center?.phoneNumber
                      ? center.phoneNumber.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                      : '-'}
                  </dd>
                </dl>

                <dl className="flex">
                  <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/5 border-t border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold">
                    주소
                  </dt>
                  <dd className=" py-2 px-5 border-t w-4/5 border-l border-solid border-gray-300 flex mxl:w-[65%] gap-2 flex-col">
                    <span>{`${center?.address.address} ${center?.address.extraAddress}`}</span>
                    <span>{`${center?.address.roadAddress} ${center?.address.extraAddress}`}</span>
                  </dd>
                </dl>
              </dl>

              <dl className="flex flex-col w-1/6 border-b border-solid border-gray-300 overflow-hidden">
                <dd
                  className=" mxl:w-[65%] h-full cursor-zoom-in"
                  onClick={() => setIsOpenModal(true)}>
                  <img
                    src={center?.certificate?.imageUrl}
                    alt=""
                    className=" object-contain w-full h-full"
                  />
                </dd>
              </dl>
            </dl>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 flex items-center mxl:w-[35%] font-bold leading-10 border-r border-solid border-gray-300">
              가입일
            </dt>
            <dd className=" py-2 px-5 w-5/6 flex items-center mxl:w-[65%]">
              {formatDateString(center?.createdAt)}
            </dd>
            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10 border-r">
              수정일
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 flex items-center mxl:w-[65%]">
              {formatDateString(center?.updatedAt)}
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10 border-r">
              이메일
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 flex mxl:w-[65%] gap-2 flex-col">
              <input
                type="text"
                className=" leading-10 border border-solid border-gray-300 w-full px-2"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10 border-r">
              은행
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 flex mxl:w-[65%] gap-2 flex-col">
              <input
                type="text"
                className=" leading-10 border border-solid border-gray-300 w-full px-2"
                value={bank}
                onChange={e => setBank(e.target.value)}
              />
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10 border-r">
              예금주
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 flex mxl:w-[65%] gap-2 flex-col">
              <input
                type="text"
                className=" leading-10 border border-solid border-gray-300 w-full px-2"
                value={accountNumber}
                onChange={e => setAccountNumber(e.target.value)}
              />
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10 border-r">
              계좌번호
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 flex mxl:w-[65%] gap-2 flex-col">
              <input
                type="text"
                className=" leading-10 border border-solid border-gray-300 w-full px-2"
                value={ownerName}
                onChange={e => setOwnerName(e.target.value)}
              />
            </dd>
          </dl>
        </div>

        <div className=" grid grid-cols-4 gap-4">
          {factories.map(f => {
            const selected = f.id === factory?.id
            return (
              <div
                className={`mt-5 border-2 border-solid rounded-md px-5 py-3 
                     ${selected ? 'border-blue-500' : 'border-gray-300'}`}
                key={f.id}
                onClick={() => {
                  selected ? setFactory(undefined) : setFactory(f)
                }}>
                <div className="text-xl font-bold p-1">{f.name}</div>
                <div className=" px-1">
                  {f.phoneNumber
                    ? f.phoneNumber.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                    : '-'}
                </div>
                <div className=" px-1">
                  {f.address.address ? `${f.address.address} ${f.address.extraAddress}` : '-'}
                </div>
              </div>
            )
          })}
        </div>

        <div className="flex items-center justify-center gap-3 mt-5 mxl:flex-col">
          <button
            className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-blue-600 text-white hover:bg-blue-800"
            onClick={() => updateCenterData()}>
            수정
          </button>
          <button
            className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-slate-200 text-gray-500 hover:bg-gray-400 hover:text-white"
            onClick={() => navigate(-1)}>
            취소
          </button>
          <button
            className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-slate-200 text-gray-500 hover:bg-red-600 hover:text-white"
            onClick={() => deleteCenterData()}>
            삭제
          </button>
        </div>
      </div>

      {isPopupOpen && (
        <PostCodeComponent
          setAddress={setAddress}
          setRoadAdress={setRoadAddress}
          setIsPopupOpen={setIsPopupOpen}
        />
      )}
      {isOpenModal && (
        <CertificateImageModal
          imageUrl={center?.certificate?.imageUrl}
          setIsOpenModal={setIsOpenModal}
        />
      )}
    </div>
  )
}
