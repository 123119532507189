import FactoryOrderList from 'components/FactoryOrderList'
import FactoryReleaseList from 'components/FactoryReleaseList'

export const FactoryReleasePage = () => {
  return (
    <div className="w-full h-full">
      <div className="p-5">
        <FactoryOrderList needFilter />
      </div>

      <hr className="mt-5" />

      <div className="p-5">
        <FactoryReleaseList />
      </div>
    </div>
  )
}
