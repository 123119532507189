import axios from 'api/axios'
import { useEffect, useState } from 'react'
import { useAppDispatch } from 'store'
import { storeUser } from 'store/userSlice'
import { User } from 'types/User'

const useGetMe = () => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getMe = async () => {
      try {
        const res = await axios.get<{ user: User; accessToken: string }>('/auth/me', {
          withCredentials: true,
        })

        const { user, accessToken } = res.data

        dispatch(
          storeUser({
            id: user.id,
            name: user.name,
            roles: user.roles,
          }),
        )

        axios.defaults.headers.common.authorization = `Bearer ${accessToken}`

        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    }

    getMe()
  }, [dispatch])

  return isLoading
}

export default useGetMe
