import axios from 'api/axios'
import { AxiosError } from 'axios'
import { beep } from 'lib/helpers'
import { useCallback, useRef, useState } from 'react'
import { Pack } from 'types/Pack'
import { IoIosCloseCircleOutline } from 'react-icons/io'

export function AdminRegisterScanner() {
  const [packs, setPacks] = useState<Pack[]>([])
  const inputRef = useRef<HTMLInputElement>(null)

  const addPack = useCallback(async () => {
    if (!inputRef.current) return

    const serialNumber = inputRef.current.value
    if (!serialNumber) return

    // check if korean
    const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
    if (korean.test(serialNumber)) {
      inputRef.current.value = ''
      beep(false)
      return alert('언어를 영어로 전환 후 바코드를 스캔하세요.')
    }

    if (serialNumber.length !== 7) {
      beep(false)
      inputRef.current.value = ''
      return alert(`잘못된 시리얼넘버입니다: ${serialNumber}`)
    }

    try {
      const res = await axios.post<{ pack: Pack }>('/packs', {
        serialNumber: serialNumber,
        status: 'ready',
      })

      const { pack } = res.data
      inputRef.current.value = ''
      setPacks(statePacks => [pack, ...statePacks])

      beep()
    } catch (error) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError
        console.log({ axiosError })

        beep(false)
        inputRef.current.value = ''
        return alert(`이미 등록된 시리얼넘버입니다.: ${serialNumber}`)
      }
    }
  }, [])

  const deletePack = async (pack: Pack) => {
    if (!window.confirm(`시리얼번호 ${pack.serialNumber}를  삭제할까요?`)) return
    try {
      const res = await axios.delete<{ message: string; deleteId: number }>(`/packs/${pack.id}`)
      const { message } = res.data
      if (message === 'ok') setPacks(packs.filter(statePack => statePack.id !== pack.id))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="">
      {!packs.length && (
        <div className="flex items-center justify-center w-full py-10 mt-5 text-3xl font-bold text-gray-500 bg-gray-100 rounded">
          등록스캔하세요
        </div>
      )}

      <input
        autoFocus
        ref={inputRef}
        type="text"
        onBlur={() => inputRef.current?.focus()}
        className="w-0 h-0 noKorean leading-none"
        onKeyPress={e => {
          if (e.key === 'Enter') addPack()
        }}
      />

      {packs.length > 0 && (
        <div className="flex items-center justify-center gap-10">
          <div className="text-3xl ">
            현재 <span className="font-bold text-blue-700">{packs.length}</span>개 스캔됨
          </div>
        </div>
      )}

      <ul className="flex flex-col gap-4 mt-5">
        {packs.map(pack => (
          <li
            key={pack.id}
            className="flex items-center justify-between p-4 border border-gray-300 border-solid rounded-md ">
            <span className="py-2 text-xl ">
              시리얼 넘버 : <strong>{pack.serialNumber}</strong>
            </span>

            <div className="flex items-center gap-6">
              <span
                className={`rounded-md  text-xl cursor-default ${
                  pack.id ? 'text-blue-500' : 'bg-gray-300'
                }`}>
                {pack.id ? '준비됨' : '대기중'}
              </span>
              <button
                className="py-2 text-3xl text-red-700 rounded-md "
                onClick={() => deletePack(pack)}>
                <IoIosCloseCircleOutline />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
