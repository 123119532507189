import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'api/axios'
import { Place } from 'types/Place'
import { Roles } from 'enums/Roles'
import { formatDateString, formatPhoneNumber } from 'pages/RetrivalManager/CentersPage'

type CenterStatus = 'waiting' | 'done' | 'none'

export const CenterPage = () => {
  const navigate = useNavigate()
  const [centers, setCenters] = useState<Place[]>([])
  const [checked, setChecked] = useState('none')
  const [searchParams, seteSearchParams] = useSearchParams()

  // local state
  const [status, setStatus] = useState<'waiting' | 'done' | 'none'>('none')

  useEffect(() => {
    const _status = searchParams.get('status')

    setStatus((_status as CenterStatus) || 'none')
  }, [searchParams])

  // derived state
  const filteredCenters =
    status === 'none'
      ? centers
      : status === 'done'
      ? centers.filter(c => c.isValid)
      : centers.filter(c => !c.isValid)

  const fatchCenters = async () => {
    try {
      const res = await axios.get<{ places: Place[] }>('/places?type=center')
      const { places: centers } = res.data
      setCenters(centers)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fatchCenters()
  }, [])

  const handleCenterStatus = (status: 'waiting' | 'done' | 'none') => {
    status === 'none' ? searchParams.delete('status') : searchParams.set('status', status)
    seteSearchParams(searchParams)

    setChecked(status)
  }

  return (
    <div className="w-full p-5">
      <div className="flex items-center justify-between">
        <h2 className="">반납센터</h2>

        <div className="flex gap-2 ml-2">
          <input
            checked={checked === 'none'}
            onChange={() => handleCenterStatus('none')}
            name="centerStatus"
            type="radio"
            id="centerAll"
          />
          <label htmlFor="centerAll">전체</label>

          <input
            checked={checked === 'waiting'}
            onChange={() => handleCenterStatus('waiting')}
            name="centerStatus"
            type="radio"
            id="centerWaite"
          />
          <label htmlFor="centerWaite">승인 대기</label>

          <input
            checked={checked === 'done'}
            onChange={() => handleCenterStatus('done')}
            name="centerStatus"
            type="radio"
            id="centerComplete"
          />
          <label htmlFor="centerComplete">승인 완료</label>
        </div>
      </div>

      <table className="w-full border rounded mt-2 text-center">
        <thead className="border-t border-b">
          <tr className="bg-gray-100">
            <th className="p-2">반납센터</th>
            <th className="p-2">대표자</th>
            <th className="p-2">전화번호</th>
            <th className="p-2">담당자</th>
            <th className="p-2">팩</th>
            <th className="p-2">가입</th>
            <th className="p-2">관할</th>
            <th className="p-2">승인</th>
          </tr>
        </thead>

        <tbody className="">
          {filteredCenters.length === 0 && (
            <tr className="">
              <td className="p-2" colSpan={8}>
                반납센터가 없습니다
              </td>
            </tr>
          )}

          {filteredCenters.map(center => (
            <tr
              className="hover:bg-gray-100 even:bg-gray-50"
              onClick={() => navigate(`${center.id}`)}
              key={center.id}>
              <td className="p-2">{center.name}</td>
              <td className="p-2">{center.roles.find(r => r.type === Roles.CHIEF)?.user.name}</td>
              <td className="p-2">{formatPhoneNumber(center.phoneNumber)}</td>
              <td className="p-2">{center.retriever?.name || '-'}</td>
              <td className="p-2">{center.packs.length}</td>
              <td className="p-2">{formatDateString(center.createdAt)}</td>
              <td>{center.factory ? center.factory.name : '없음'}</td>
              <td className={center.isValid ? 'text-blue-500' : 'text-red-500'}>
                {center.isValid ? '승인완료' : '승인대기'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
