import axios from 'api/axios'
import { QuillEditorComponent } from 'components/QuillEditorComponent'
import { Posts } from 'enums/Posts'
import { usePostRange } from 'hooks/usePostRange'
import { useEffect, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import { useNavigate, useParams } from 'react-router-dom'
import { Post } from 'types/Post'

export const FaqUpdatePage = () => {
  const { faqId } = useParams()
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')

  const { range, updateRange, renderPostRange } = usePostRange()

  const updateFaq = async () => {
    try {
      const res = await axios.patch<{ post: Post }>(`/post/${faqId}`, {
        post: { title, body, range },
      })

      navigate(`/setting/faq/${res.data.post.id}`)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const fatchFaq = async () => {
      try {
        const res = await axios.get<{ post: Post }>(`/post/${faqId}`)
        if (!res.data.post.title) return
        setTitle(res.data.post.title)
        setBody(res.data.post.body)
        updateRange(res.data.post.range)
      } catch (error) {
        console.log(error)
      }
    }
    fatchFaq()
  }, [faqId, updateRange])

  return (
    <div className="flex-1 p-5 bg-white mxl:p-0">
      <div className="flex items-center justify-between gap-2 mxl:flex-col mxl:items-start">
        <h2 className="text-2xl h-12 flex items-center">FAQ 수정</h2>
        {renderPostRange()}
      </div>

      <div className="mt-5">
        <input
          type="text"
          className="w-full px-2 leading-10 border border-gray-300 rounded"
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder="제목을 입력해 주세요"
        />
      </div>

      <div className="mt-5 h-[55vh] md:h-[66vh]">
        <QuillEditorComponent type={Posts.FAQ} body={body} setBody={setBody} />
      </div>

      <div className="flex items-center justify-center gap-3 mt-20 mxl:flex-col">
        <button
          className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-blue-600 text-white hover:bg-blue-800"
          onClick={() => updateFaq()}>
          수정
        </button>
        <button
          className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-slate-200 text-gray-500 hover:bg-gray-400 hover:text-white"
          onClick={() => navigate(-1)}>
          취소
        </button>
      </div>
    </div>
  )
}
