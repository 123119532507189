import React from 'react'
import './Terms.css'

export default function PrivacyPolicy() {
  return (
    <div className="terms_container">
      <article id="40f5119e-9178-462b-9150-a2046f06afbf" className="page sans">
        <header>
          <h1 className="page-title"> </h1>
        </header>
        <div className="page-body">
          <p id="895dada3-9bdf-4b09-8445-5fd688fdc222" className="">
            <strong>얼싱팩 앱(APP) 개인정보처리방침</strong>
          </p>
          <p id="66d00904-5440-4a02-9fa9-a56955d5760b" className="">
            주식회사 샵플(이하 ‘회사’라 합니다)는 개인정보보호법 및 정보통신망 이용촉진 및 정보보호
            등에 관한 법률에 따라 회사가 운영하는 리유저블 택배파우치 – 얼싱팩 “웹사이트” 와
            “애플리케이션”(이하 “웹사이트” 와 “애플리케이션”을 “APP”이라고 합니다) 이용자의 개인정보
            및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과
            같은 처리방침을 두고 있습니다.
          </p>
          <p id="f0385a3c-5e1e-48e1-8c9d-b192a5bdd7da" className="">
            회사는 개인정보처리방침의 지속적인 개선을 위하여 개인정보처리방침을 개정하는데 필요한
            절차를 정하고 있습니다. 그리고 본 처리방침은 수시로 내용이 변경될 수 있으니 정기적으로
            방문하여 확인하시기 바랍니다.
          </p>
          <p id="209658f5-185c-4680-8492-0a8f627e9a05" className="">
            <strong>1. 총칙</strong>
          </p>
          <p id="65a9de69-707e-49d1-b69a-2283670fa60c" className="">
            ① &quot;개인정보&quot;라 함은 생존하고 있는 개인에 관한 정보로서 성명, 주민등록번호 및
            영상 등을 통하여 개인을 알아볼 수 있는 정보(해당 정보만으로는 특정 개인을 알아볼 수
            없더라도 다른 정보와 쉽게 결합하여 알아볼 수 있는 것을 포함한다)를 말합니다.
          </p>
          <p id="67c824e3-9911-4991-92be-c5cfbf77fcc9" className="">
            ② 주식회사 샵플이 운영하는 리유저블 택배 파우치 – 얼싱팩 서비스(이하 “서비스”라
            함)은(는) 회원 및 이용자님의 개인정보보호를 매우 중요시하며, 『정보통신망 이용촉진 및
            정보보호 등에 관한 법률』, 『개인정보 보호법』상의 개인정보보호규정을 준수하고 있습니다.
            또한 “서비스”는 개인정보 처리방침을 통하여 회원 및 이용자님께서 제공하시는 개인정보가
            어떤 용도와 방식으로 이용되고 있으며 개인정보를 효과적이고 적극적으로 보호하기 위해 항상
            노력하고 있음에 대해 알려드립니다.
          </p>
          <p id="5db12adc-6ff7-46cc-a5dc-083c400af7e9" className="">
            ③ ”서비스”는 개인정보 처리방침을 서비스 내 공개함으로써 회원 및 이용자님께서 언제나
            용이하게 보실 수 있도록 조치하고 있습니다.
          </p>
          <p id="2f44b743-e7af-4f19-aa68-a46f85f6a443" className="">
            ④ ”서비스”는 개인정보처리방침의 지속적인 개선을 위하여 개인정보처리방침을 개정하는데
            필요한 절차를 정하고 있습니다.
          </p>
          <p id="ed57cfd5-ab99-40ee-86c9-b04a30c25a44" className="">
            ⑤ ”서비스”의 개인정보처리방침이 변경되는 경우에는 변경되는 개인정보처리방침을 시행하기
            7일전부터 “서비스”내 공지사항”등을 통하여 변경이유 및 내용을 APP에 공지합니다.
          </p>
          <p id="96efb1cb-32af-44ac-a840-25b0f0b50704" className="">
            <strong>2. 개인정보의 수집, 이용목적, 항목 및 보유기간</strong>
          </p>
          <p id="42ab9bcb-3dc2-470a-9cd6-81599ec6f510" className="">
            ① ”서비스”는 본질적인 서비스 제공을 위한 ‘필수동의’와 회원 및 이용자 각각의 기호와
            필요에 맞는 서비스를 제공하기 위한 ‘선택동의’로 구분되어 다음의 정보를 수집/이용하고
            있습니다. 선택동의를 하지 않은 경우에도 서비스 이용 제한은 없습니다.
          </p>
          <p id="311b8de3-ac22-4ff3-bea8-59f65fcd6863" className="">
            ② ”서비스”는 회원 및 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및
            민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는
            수집하지 않습니다. 다만, 서비스 제공을 위해 일부 민감정보를 필요 최소한으로 수집, 처리할
            필요가 있는 경우 관련 법령의 제한에 따라 회원 및 이용자의 동의 등 필요한 조치를 거쳐 그
            개인정보를 수집, 처리할 수 있습니다.
          </p>
          <p id="a2036e8f-1132-4f83-a463-7b73699d9ed9" className="">
            ③ “서비스”에서 회원 및 이용자님의 컴퓨터 또는 이동통신단말장치(스마트폰 등)에 저장된
            정보 및 설치된 기능(이하 ‘단말기 정보 등’이라 합니다)에 접근하여 개인정보를 수집할 경우,
            사전에 ‘단말기 정보 등’에 접근한다는 것을 고지하고, 회원 및 이용자님이 개인정보
            수집/이용 동의를 한 범위내에서 해당 개인정보를 수집합니다.
          </p>
          <p id="50f70414-66cc-424a-8e41-663ec6367ca4" className="">
            ④ ”서비스”에서는 법령에 따라 동의없이 수집할 수 있는 자동생성 정보(로그기록, 결제기록 등
            서비스 계약 이행과정에서 자동적으로 생성되는 정보), 얼싱팩 이용 정산에 필요한 정보 등을
            필수동의 사항에 기재된 목적범위내에서 수집하여 이용할 수 있습니다. 또한 회원 및
            이용자님이 선택동의에 동의할 경우 해당 목적범위내에서도 이용할 수 있습니다.
          </p>
          <p id="fd909823-17fd-45c9-b296-e13e4631106a" className="">
            ⑤ “서비스”에서는 수집한 개인정보를 개인을 알아볼 수 없는 통계자료 등으로 가공하여 이용
            또는 제공할 수 있습니다.
          </p>
          <p id="2baf090a-c2fd-46d9-902a-8855346a3741" className="">
            <strong>3. 개인정보 수집 방법 및 동의 방법</strong>
          </p>
          <p id="33ffe852-2881-420f-b37f-3b776cdb008f" className="">
            ① “서비스”는 회원 및 이용자님의 ‘개인정보 수집 및 이용’에 대한 별도의 동의획득 절차를
            두고, 회원 및 이용자님께서 이에 대해 &#x27;동의한다&#x27; 또는 &#x27;동의하지
            않는다&#x27; 버튼을 클릭할 수 있는 절차를 마련하고 있습니다.’ 동의한다&#x27; 버튼을
            클릭하면 개인정보 수집 및 이용에 대해 동의한 것으로 봅니다.
          </p>
          <p id="164c9667-84c4-44c7-893d-a98a8e3625d9" className="">
            ② 회원 및 이용자님은 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있으며, 동의를
            거부할 경우 받는 별도의 불이익은 없습니다. 단, 필수동의 사항에 동의를 거부할 경우,
            서비스 이용이 불가능하거나, 서비스 이용 목적에 따른 서비스 제공에 제한이 따르게 됩니다.
          </p>
          <p id="d76a8afd-c478-4e96-abf3-0d840c247572" className="">
            ③ “서비스”는 컴퓨터 또는 스마트폰용 애플리케이션을 통한 방법 등으로 개인정보를
            수집합니다.
          </p>
          <p id="e072eca6-91d8-42a9-89c4-24d9cac3f47d" className="">
            <strong>4. 개인정보 처리 업무의 위탁</strong>
          </p>
          <p id="ff41b3cf-bc53-447e-a18c-50cbba05ea19" className="">
            ① “서비스”는 업무위탁계약서 등을 통하여 개인정보보호 관련 법규의 준수, 개인정보에 관한
            비밀유지, 제3자 제공에 대한 금지, 사고시의 책임부담, 위탁기간, 처리 종료 후의 개인정보의
            반환 또는 파기 의무 등을 규정하고, 이를 준수하도록 관리하고 있습니다.
          </p>
          <p id="9a499912-460a-4fbb-a1e0-37503ad6781c" className="">
            ② ”서비스” 제공을 위해 개인정보를 위탁하는 업체 리스트 위탁 업체 리스트는 해당 서비스
            변경 및 계약기간에 따라 변경될 수 있으며 변경 시 공지사항을 통해 사전 공지합니다.
          </p>
          <p id="cbd60808-41b4-4656-bedb-fc6e4554129e" className="">
            <strong>5. 수집한 개인정보의 제3자 제공</strong>
          </p>
          <p id="356c3b7a-12be-4644-9eb0-c256e3b9bda6" className="">
            ① “서비스”는 회원 및 이용자님의 개인정보를 서비스이용약관, 본 개인정보처리방침의
            &#x27;개인정보의 수집, 이용 목적, 항목 및 보유기간&#x27;에서 고지한 범위 내에서
            사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기업 · 기관에 제공하지 않습니다.
            특히, 다음의 경우에는 주의를 기울여 개인정보를 이용 및 제공할 수 있습니다.
          </p>
          <ol
            type="1"
            id="2aadefa1-dec1-47d9-8567-1ded58553a0c"
            className="numbered-list"
            start={1}>
            <li>
              제휴관계 보다 나은 “서비스” 제공을 위하여 회원 및 이용자님의 개인정보를 제휴사에게
              제공하거나 또는 제휴사와 공유할 수 있습니다. 개인정보를 제공하거나 공유할 경우에는
              사전에 회원 및 이용자님께 제휴사가 누구인지, 제공 또는 공유되는 개인정보항목이
              무엇인지, 왜 그러한 개인정보가 제공되거나 공유되어야 하는지, 그리고 언제까지 어떻게
              보호 · 관리되는지에 대해 개별적으로 서면 또는 전자우편 등을 통해 고지하여 동의를
              구하는 절차를 거치게 되며, 회원 및 이용자님께서 동의하지 않는 경우에는 제휴사에게
              제공하거나 제휴사와 공유하지 않습니다. 제휴관계에 변화가 있거나 제휴관계가 종결될 때도
              같은 절차에 의하여 고지하거나 동의를 구합니다.
            </li>
          </ol>
          <ol
            type="1"
            id="3ddac5db-97e8-4899-97cc-94dcdbaf950e"
            className="numbered-list"
            start={2}>
            <li>
              매각 · 인수합병 등 영업의 전부 또는 일부를 양도하거나, 합병 · 상속 등으로
              서비스제공자의 권리 · 의무를 이전 승계하는 경우 개인정보보호 관련 회원 및 이용자님의
              권리를 보장하기 위하여 반드시 그 사실을 APP에 통지합니다.
            </li>
          </ol>
          <ol
            type="1"
            id="f98caecc-a66d-469a-b8d6-9a7cfa789e3b"
            className="numbered-list"
            start={3}>
            <li>
              법령에 의해 필요하거나 허용되는 경우 “서비스”의 개인정보를 수집 시 회원 및 이용자에게
              고지한 범위 또는 서비스이용약관에 명시한 범위를 넘어 이용하거나 제3자에게 제공하지
              않습니다. 다만, 회원 및 이용자의 동의가 있거나 다음에 해당하는 경우에는 예외로 합니다.
            </li>
          </ol>
          <ul id="78845da3-3574-49ed-abc4-d058da644260" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              “서비스”의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적/기술적인 사유로
              통상의 동의를 받는 것이 현저히 곤란한 경우
            </li>
          </ul>
          <ul id="46b628b7-e66a-458e-a4a5-1f3fb3971132" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              “서비스” 제공에 따른 정산을 위하여 필요한 경우 - 통신비밀보호법, 국세기본법,
              정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법, 금융실명거래 및
              비밀보장에 관한 법률, 신용정보의 이용 및 보호에 관한 법률, 전기통신기본법,
              전기통신사업법, 지방세법, 소비자기본법, 한국은행법, 형사소송법 등 다른 법률에 특별한
              규정이 있는 경우. 단, &#x27;법률에 특별한 규정이 있는 경우&#x27;로 행정목적이나
              수사목적으로 행정관청 또는 수사기관이 요구해 온 경우라도 무조건 회원 및 이용자의
              개인정보를 제공하지 않으며, 법률에 규정된 바에 따라 영장 또는 기관장의 직인이 날인된
              서면에 의한 경우 등 적법한 절차에 따라 제공합니다.
            </li>
          </ul>
          <p id="fc1bd97e-e54a-4a8a-9d99-50d4e6042a3b" className="">
            <strong>6. 개인정보의 보관기간 및 이용기간</strong>
          </p>
          <p id="a40caaef-497c-4c0e-a183-0d838d7671f5" className="">
            회사는 회원 및 이용자님의 “서비스” 가입일로부터 회원 및 이용자님께 “서비스”를 제공하는
            기간 동안에 한하여 회원 및 이용자님의 개인정보를 보유 및 이용하게 됩니다. 회원 탈퇴를
            요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집/이용목적을
            달성하거나 보유/이용기간이 종료한 경우, 사업폐지 등의 사유발생시 당해 개인정보를
            지체없이 파기합니다. 단, 정산, 소송이나 분쟁 등 기타 필요한 경우를 대비하여 일정기간
            보유하여야 할 필요가 있을 경우 개인정보는 일정기간 보유합니다. 또한 상법, 국세기본법,
            통신비밀보호법, 전자상거래 등에서 소비자보호에 관한 법률, 신용정보의 이용 및 보호에 관한
            법률 등 관계법령에 따라 보존할 필요가 있는 경우 회사는 관계법령에서 정한 기간 동안
            회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며
            보존기간은 다음과 같습니다.
          </p>
          <ul id="6e729e1b-3ff0-4845-9cb8-d06f029ce31b" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              표시/광고에 관한 기록: 6개월(전자상거래등에서의 소비자보호에 관한 법률)
            </li>
          </ul>
          <ul id="a584d408-3fda-4fb2-8bd0-cf307d2328fc" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              계약 또는 청약철회 등에 관한 기록: 5년(전자상거래등에서의 소비자보호에 관한 법률)
            </li>
          </ul>
          <ul id="5e077796-1e76-460f-a28c-66e73112a992" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              대금결제 및 재화 등의 공급에 관한 기록: 5년(전자상거래등에서의 소비자보호에 관한 법률)
            </li>
          </ul>
          <ul id="4a02b624-06a5-480f-9195-f402af50a298" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래등에서의 소비자보호에 관한
              법률)
            </li>
          </ul>
          <ul id="1807db21-100d-4fe0-bdf4-94dc6bb66948" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              신용정보의 수집/처리 및 이용 등에 관한 기록: 3년(신용정보의 이용 및 보호에 관한 법률)
            </li>
          </ul>
          <p id="19cc63c7-fed6-4b0a-a33d-52e7e6e4b723" className="">
            <strong>7. 개인정보의 파기절차 및 방법</strong>
          </p>
          <p id="0a8417e0-e4e0-4ea5-83d0-787f9343e870" className="">
            ① “서비스”는 수집한 개인정보의 이용목적이 달성된 후에는 보관기간 및 이용기간에 따라 해당
            정보를 지체 없이 복구 불가능하도록 파기합니다. 파기절차, 방법, 시점은 다음과 같습니다.
          </p>
          <ol
            type="1"
            id="5b95587d-c400-41cc-8eec-15d1bf189744"
            className="numbered-list"
            start={1}>
            <li>
              파기절차 및 시점 회원 및 이용자가 “서비스” 가입 등을 위해 기재한 개인정보는 “서비스”
              해지 등 이용목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 방침에 의해
              삭제되거나 파기합니다. 일반적으로 채권-채무 관계가 없는 경우, “서비스” 가입시 수집되어
              전자적 파일형태로 관리하는 개인정보는 회원 탈퇴 시점에 바로 삭제됩니다. 단, 회원 및
              이용자에게 미리 고지하고 개별 동의를 받은 경우는 동의한 내용에 따른 보유기간 동안 보유
              후 삭제되며, 관련 법령의 규정에 의하여 개인정보를 보유해야 할 필요가 있는 경우에는
              해당 법령에서 정한 기간 동안 보유한 후 해당 기간이 지나면 회원의 모든 데이터를
              삭제합니다. 단, 회원 및 이용자님이 이동통신단말장치(스마트폰 등)에서 Application을
              삭제하더라도 동의 철회(회원탈퇴) 요청을 하지 않을 경우 해당 개인정보는 여전히
              “서비스”에 남아있으므로, 개인정보 파기를 원하시면 반드시 동의 철회(회원탈퇴) 요청을
              하셔야 합니다.
            </li>
          </ol>
          <ol
            type="1"
            id="c5bb447d-fc05-4c76-ad23-8905adbbbb6a"
            className="numbered-list"
            start={2}>
            <li>
              파기방법 가입신청서 등 서면양식에 기재하거나, 종이에 출력된 개인정보는 분쇄기로 분쇄
              또는 소각하거나 화학약품 처리를 하여 용해하여 파기하고, 전자적 파일형태로 저장된
              개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
            </li>
          </ol>
          <ol
            type="1"
            id="6f702743-76e1-488d-b81d-2751a523f452"
            className="numbered-list"
            start={3}>
            <li>
              특정 기간 서비스 미이용자의 개인정보 분리보관 또는 파기 “서비스”는 다른 법령 또는
              이용자의 요청에 따라 달리 정한 경우가 아닌 한 다음과 같은 기준에 따라 서비스를
              이용하지 않는 회원 및 이용자님의 개인정보를 분리보관 하거나 파기합니다. (1년 간 서비스
              접근 로그가 없는 경우) “서비스”는 상기 분리보관 또는 파기일의 30일전까지 서비스에서
              수집한 연락처로 개인정보의 분리보관 또는 파기사실을 통지합니다. 단, 서비스에서
              연락처를 수집하지 않는 경우에는 본 개인정보 처리방침 내용의 공개로 통지를 갈음합니다.
            </li>
          </ol>
          <p id="d3b6e2ad-020a-4278-bc41-88166c559fa3" className="">
            <strong>8. 회원 및 이용자의 권리, 의무와 행사 방법</strong>
          </p>
          <p id="288e081f-7123-4329-8bb2-925b225b885d" className="">
            <strong>① 회원 및 이용자의 개인정보 보호 관련 권리</strong>
          </p>
          <p id="502d660b-6682-496b-a498-5f458bcf4270" className="">
            회원 및 이용자는 언제든지 등록되어 있는 회원 및 이용자의 개인정보를 열람하거나 정정하실
            수 있습니다. 개인정보 열람 및 정정을 하고자 할 경우에는 &#x27;회원정보변경’을 클릭하여
            직접 열람 또는 정정하실 수 있습니다. “서비스”는 회원 및 이용자가 개인정보에 대한 열람 ·
            증명 또는 정정을 요구하는 경우에는 회원 및 이용자의 요구에 성실하게 대응하고, 해당
            개인정보에 오류가 있거나 보존기간을 경과한 것이 판명되는 등 정정ㆍ삭제를 할 필요가
            있다고 인정되는 경우에는 지체 없이 정정 · 삭제를 합니다. 또한 회원가입 등을 통해
            개인정보의 수집, 이용, 제공에 대해 동의하신 내용을 언제든지 철회하실 수 있습니다. 동의
            철회(회원탈퇴)를 하고자 할 경우에는 &#x27;회원가입해지&#x27;를 클릭하여 직접 신청하실 수
            있습니다. 단, 회원 및 이용자님이 이동통신단말장치(스마트폰 등)에서 Application을
            삭제하더라도 동의 철회(회원탈퇴) 요청을 하지 않을 경우 해당 개인정보는 여전히 “서비스”에
            남아있으므로, 개인정보 파기를 원하시면 반드시 동의 철회(회원탈퇴) 요청을 하셔야 합니다.
          </p>
          <p id="1cfeb8b0-6dce-4146-84e2-8e2a5cbe0bfd" className="">
            <strong>② 회원 및 이용자의 권리행사 방법</strong>
          </p>
          <p id="73530734-b76e-4bb4-884e-e00f3f693035" className="">
            회원 및 이용자는 대상 서비스의 성격에 따라 다음 중 한 가지 방법으로 위와 같은 권리를
            행사할 수 있습니다. 대상 서비스 또는 개인정보의 성격에 따라, 또는 회원 및 이용자 정보
            보호의 필요성에 따라 일부 방법은 제한될 수 있으며, “서비스”는 위와 같은 권리를 행사하는
            자가 회원 및 이용자 본인 또는 회원 및 이용자의 적법한 대리인인지 여부를
            신분증명문서(주민등록증, 여권, 운전면허증(신형)) 및 권리증명문서(위임장, 본인 및
            대리인의 인감증명서 등)를 통해 확인할 수 있습니다. 해당 서비스 내 회원정보변경 또는
            정보확인 메뉴를 클릭하거나 공지된 연락처로 서면, 전화, 전자우편 발송하여 문의 “서비스”는
            개인정보의 전부 또는 일부에 대하여 열람ㆍ증명 또는 정정요청을 거절할 정당한 이유가 있는
            경우에는 회원 및 이용자에게 이를 통지하고 그 이유를 설명합니다.
          </p>
          <p id="39f70722-e7c6-4ded-beb1-d15d19553a67" className="">
            <strong>③ 회원 및 이용자의 개인정보 보호 관련 의무</strong>
          </p>
          <p id="9d7bb4b4-32ca-4cf4-b4d1-869bf09acfa6" className="">
            회원 및 이용자님의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해
            주시기 바랍니다. 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 회원 및 이용자님께
            있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다. 회원 및
            이용자님은 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을
            의무도 가지고 있습니다. 패스워드를 포함한 회원 및 이용자님의 개인정보가 유출되지 않도록
            조심하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이
            같은 책임을 다하지 못하고 타인의 정보를 훼손할 시에는 『정보통신망 이용촉진 및 정보보호
            등에 관한 법률』등에 의해 처벌받을 수 있습니다.
          </p>
          <p id="b7624a36-0258-4fd0-ab58-77dc2421c1ef" className="">
            <strong>9. 아동의 개인정보보호 및 법정대리인의 권리</strong>
          </p>
          <p id="93337844-421d-4916-96eb-56be2a4d2049" className="">
            본 “서비스”는 미성년자에 대해 서비스를 제공하지 않습니다.
          </p>
          <p id="90818ced-dd5d-4bf7-93ef-68aa8d0bb56d" className="">
            <strong>10. 개인정보 자동수집장치의 설치/운영 및 그 거부에 대한 사항</strong>
          </p>
          <p id="805fb6e6-9e80-4714-939e-740adc98fdd3" className="">
            본 “서비스”는 개인정보 자동 수집 장치를 사용하지 않습니다.
          </p>
          <p id="a0e157cf-d390-4546-84ff-1b055e1ddeea" className="">
            <strong>11. 개인정보보호를 위한 기술적 및 관리적 대책</strong>
          </p>
          <p id="67f54ec6-c9ae-4cd8-9205-fdabfa649ab6" className="">
            ① “서비스”에서는 회원 및 이용자님의 개인정보를 보호하기 위해 기술적 대책과 관리적 대책을
            마련하고 있으며, 이를 적용하고 있습니다.
          </p>
          <ol
            type="1"
            id="3e0c01ec-07e0-40f0-a837-cea92ec21646"
            className="numbered-list"
            start={1}>
            <li>
              기술적 대책 “서비스”는 회원 및 이용자님의 개인정보를 처리함에 있어 개인정보가 분실,
              도난, 유출, 위조, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적
              대책을 강구하고 있습니다.
            </li>
          </ol>
          <ul id="971aaf8e-5e20-43a7-86ee-841f61f1c13f" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              회원 및 이용자님의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를
              암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해
              보호되고 있습니다.
            </li>
          </ul>
          <ul id="5927c7b2-357b-4a58-b497-b8a583e63933" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              ”서비스”는 백신프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를
              취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스러운 바이러스가 출현할
              경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
            </li>
          </ul>
          <ul id="2c143175-4980-4ca6-835d-5f0e6885fdc7" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              ”서비스”는 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는
              보안장치(SSL 또는 SET)를 채택하고 있습니다.
            </li>
          </ul>
          <ul id="47ac49dc-8c48-414e-8359-c33897c1d469" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을
              이용하여 보안에 만전을 기하고 있습니다.
            </li>
          </ul>
          <ol
            type="1"
            id="fb668683-0645-4896-a526-5213c6151253"
            className="numbered-list"
            start={1}>
            <li>
              관리적 대책 “서비스”는 개인정보의 안전한 보호를 위하여 주요 시스템 및 설비에 대하여
              외부 전문기관으로부터 정보보호관리체계 인증 등 객관적인 인증을 받아 운영되고 있습니다.
              “서비스”는 회원 및 이용자의 개인정보에 대한 접근 및 관리에 필요한 절차 등을 마련하여
              소속 직원으로 하여금 이를 숙지하고 준수하도록 하고 있습니다. “서비스”는 회원 및
              이용자님의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다. 그 최소한의
              인원에 해당하는 자는 다음과 같습니다.
            </li>
          </ol>
          <ul id="c6b8ad91-bad1-495d-adbe-6185da206f90" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자
            </li>
          </ul>
          <ul id="fc1e228d-5e8a-43a0-8a69-9440333f7d23" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              개인정보보호책임자 및 담당자 등 개인정보보호업무를 수행하는 자
            </li>
          </ul>
          <ul id="0e22fd07-198e-4fb4-a1d9-394ae9ea9bf4" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>기타 업무상 개인정보의 처리가 불가피한 자</li>
          </ul>
          <ul id="5e22f5fa-2403-47ed-b340-a49a1db7b5d5" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              ”서비스”는 컴퓨터를 이용하여 회원 및 이용자의 개인정보를 처리하는 경우 개인정보에 대한
              접근권한을 가진 담당자를 지정하여 식별부호(ID) 및 비밀번호를 부여하고, 해당 비밀번호를
              정기적으로 갱신하고 있습니다.
            </li>
          </ul>
          <ul id="4795ac71-4ab8-443d-986e-b0a17b0f5096" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              서비스이용계약체결 또는 서비스제공을 위하여 회원 및 이용자의 신용카드번호,
              은행결제계좌 등 대금결재에 관한 정보를 수집하거나 회원 및 이용자에게 제공하는 경우
              당해 회원 및 이용자가 본인임을 확인하기 위하여 필요한 조치를 취하고 있습니다.
            </li>
          </ul>
          <ul id="a640df9c-4fc0-4d4f-9e0d-c35ebcfd901c" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              회사는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해
              책임을 지지 않습니다. 회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 ID 와
              패스워드를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.
            </li>
          </ul>
          <ul id="8cbdb8cd-c70d-4c7d-8ba6-2f5b04b8e743" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              또한, 다른 사람이 추측할 수 있는 쉬운 비밀번호는 사용을 피하시기를 권장하며,
              정기적으로 비밀번호를 변경하시는 것이 바람직합니다.
            </li>
          </ul>
          <ul id="296dba43-5957-4763-b253-9b76d4e5e53a" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 위조,
              변조, 훼손이 유발될 경우 “서비스”는 즉각 회원 및 이용자님께 사실을 알리고 적절한
              대책과 보상을 강구할 것입니다.
            </li>
          </ul>
          <p id="2840b64f-b488-4d3e-8b2e-977b47e61fa3" className="">
            <strong>12. 광고성 정보 전송</strong>
          </p>
          <p id="3c2a9c57-7c10-422b-9a3c-68a39f189817" className="">
            “서비스”는 회원 및 이용자에게 영리목적의 광고성 정보를 전송하지 않습니다.
          </p>
          <p id="3215165d-010c-47f6-acd8-8c74107170e3" className="">
            <strong>13. 의견 수렴 및 불만 처리</strong>
          </p>
          <p id="845882de-0d78-4244-a70c-777adea364ef" className="">
            회사는 회원 및 이용자의 의견을 매우 소중하게 생각합니다. 회원 및 이용자께서 문의사항이
            있을 경우 이메일을 통하여 문의하시면 신속 · 정확한 답변을 드리겠습니다. 이메일 연락처는
            다음과 같습니다.
          </p>
          <ul id="cbdf62c2-4b87-441c-adbc-5df51331b1b3" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>이메일: earth@earthingpack.com</li>
          </ul>
          <p id="57159d99-52c9-4d66-87fe-6e1a84b53434" className="">
            <strong>14. 회원 및 이용자 개인정보의 국외 이전 해당 사항 없음</strong>
          </p>
          <p id="5e9a9477-f015-4a3b-a4af-4b66689dd4e7" className="">
            <strong>15. 개인정보보호 책임자 및 담당자</strong>
          </p>
          <p id="9e8ee42b-1b1c-4431-b810-fce19adb2d00" className="">
            회사는 회원 및 이용자님이 안전하게 서비스를 이용할 수 있도록 개인정보 보호에 최선을
            다하고 있습니다. 개인정보를 보호하는데 있어 회원 및 이용자님께 고지한 사항들에 반하는
            사고가 발생할 시에 개인정보보호책임자가 모든 책임을 집니다. 회원 및 이용자님의
            개인정보를 처리하는 책임자 및 담당자는 다음과 같으며 개인정보 관련 문의사항에 신속하고
            성실하게 답변해드리고 있습니다.
          </p>
          <ul id="ad40b2a8-8f88-43cb-a184-dcd8ea10bcbb" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>개인정보보호 책임자: 강은주</li>
          </ul>
          <ul id="bea30e4e-ff9e-4c37-9e11-4a5f6fb70a64" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>개인정보보호 담당자: 강은주</li>
          </ul>
          <ul id="3ce2550b-4432-4e7b-97ab-9ec05d36ca01" className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>이메일: earth@sharple.net</li>
          </ul>
          <p id="9fbf0b01-1e89-4277-aca3-6de150ac3f62" className="">
            <strong>16. 개인정보처리방침의 고지 또는 통지방법</strong>
          </p>
          <p id="6723a9eb-705a-4a74-8019-d14681ac2ea2" className="">
            ① ”서비스”가 개인정보 수집 시 고지한 범위 또는 서비스이용약관, 본 개인정보처리방침에
            명시한 범위를 넘어 회원 및 이용자의 개인정보를 이용하거나 제3자에게 제공하기 위해 회원
            및 이용자의 동의를 얻고자 하는 때에는 미리 회원 및 이용자에게 개별적으로 “서비스” 화면,
            서면, 전자우편, 전화 등으로 해당사항을 고지합니다. 타인에게 회원 및 이용자의 개인정보의
            수집·보관·처리·이용·제공·관리·파기 등을 위탁하는 경우에는 서비스이용약관,
            개인정보처리방침 등을 통하여 그 사실을 회원 및 이용자에게 고지합니다.
          </p>
          <p id="c72ab19a-acf5-4ef3-8189-5022d51dfa3e" className="">
            ② ”서비스”가 영업의 전부 또는 일부를 양도하거나 합병ㆍ상속 등으로 그 권리ㆍ의무를
            이전하는 경우 스마트폰 애플리케이션 첫 화면에서 식별할 수 있도록 표기하여 30일 이상 그
            사실을 공지합니다.
          </p>
          <p id="763995b2-c8d6-4613-bfbd-6b11c8e3dbc0" className="">
            <strong>17. 개인정보처리방침의 변경</strong>
          </p>
          <p id="4a754c44-f779-4df0-8094-ddaea5d11949" className="">
            현 개인정보처리방침은 2022년 9월 29일부터 적용됩니다.
          </p>
          <table id="0ad7d687-20d1-4c34-88ec-e2a2aacb4dea" className="simple-table">
            <tbody></tbody>
          </table>
          <table id="4015a8c7-b476-433d-af5e-13c1fa0e1845" className="simple-table">
            <tbody></tbody>
          </table>
        </div>
      </article>
    </div>
  )
}
