import axios from 'api/axios'
import { useFactory } from 'hooks/useFactory'
import { beep, getPackStatusLabelText } from 'lib/helpers'
import { ScanMode } from 'pages/Factory/FactoryPackScanPage'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Pack } from 'types/Pack'
import FactoryOrderList from './FactoryOrderList'
import { Order } from './Order'

function IssueScanner({ mode }: { mode: ScanMode }) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [packs, setPacks] = useState<Pack[]>([])
  const { factory, fetchFactory } = useFactory()
  const [order, setOrder] = useState<Order>()
  const [searchParams] = useSearchParams()
  const isReady = packs.length === order?.quantity
  const navigate = useNavigate()

  useEffect(() => {
    if (mode === 'release') {
      fetchFactory()
    }
  }, [fetchFactory, mode])

  const fetchOrder = async (id: string) => {
    try {
      const res = await axios.get<{ order: Order }>(`/order/${id}`)
      setOrder(res.data.order)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const orderId = searchParams.get('orderId')
    if (!orderId) return setOrder(undefined)

    fetchOrder(orderId)
  }, [searchParams])

  const addPack = useCallback(async () => {
    if (!order) return
    if (!inputRef.current) return
    if (packs.length >= order.quantity) return

    try {
      const serialNumber = inputRef.current.value
      const pack = factory.packs.find(p => p.serialNumber === serialNumber)

      if (!pack) throw new Error('보유하고 있지 않은 팩입니다.')
      if (pack.status !== 'ready')
        throw new Error(
          "'준비됨' 상태의 팩만 출고할 수 있습니다. 준비완료 스캔을 먼저 진행해주세요",
        )
      if (packs.find(p => p.serialNumber === serialNumber))
        throw new Error('중복스캔입니다: ' + serialNumber)

      setPacks(pp => [...pp, pack])
      beep()
    } catch (error) {
      beep(false)
      if (error instanceof Error) alert(error.message)
    } finally {
      inputRef.current.value = ''
    }
  }, [factory.packs, order, packs])

  const completeOrder = async (id: number, packs: Pack[]) => {
    try {
      await axios.post('/order/complete', { id, packs })
      setOrder(undefined)
      setPacks([])
      navigate('/scan?mode=release')
    } catch (error) {
      console.log(error)
    }
  }

  if (mode !== 'release') return null

  return (
    <div className="">
      <input
        autoFocus
        ref={inputRef}
        type="text"
        onBlur={() => inputRef.current?.focus()}
        className="w-0 h-0 noKorean"
        onKeyPress={e => {
          if (e.key === 'Enter') addPack()
        }}
      />

      {!order && (
        <div className="text-center">
          <h3 className="">출고주문을 선택하세요</h3>
          <FactoryOrderList />
        </div>
      )}

      {order && (
        <div className="w-80 min-w-fit mx-auto">
          <div className="flex justify-between">
            <h2 className="text-center w-full">
              {isReady
                ? '스캔완료: 검토 후 출고하세요'
                : packs.length > 0
                ? `스캔 ${packs.length}개`
                : '출고 스캔하세요'}
            </h2>
          </div>

          <div className="border rounded mt-3">
            <div className="p-4">
              <div className="flex justify-between items-center">
                <h3 className="">주문정보</h3>
              </div>

              <ul className="mt-1">
                <li className="">목적지: {order.to.name}</li>
                <li className="">
                  주소:{' '}
                  {order.to.address.address
                    ? order.to.address.address
                    : order.to.address.roadAddress}
                </li>
                <li className="">팩: {order.quantity}개</li>
                <li className="">
                  의뢰인: {order.orderer.name}({order.orderer.phoneNumber})
                </li>
                <li className="">메시지: {order.message}</li>
              </ul>

              {packs.length > 0 && (
                <div>
                  <h3 className="mt-4">팩 목록({packs.length}개)</h3>
                  <ul className="mt-1">
                    {packs.map(p => (
                      <li className="" key={p.id}>
                        {p.serialNumber} {getPackStatusLabelText(p)}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="flex justify-center mt-5 gap-1">
                {isReady && (
                  <button
                    className="rounded bg-gray-400 text-white px-4 text-sm"
                    onClick={() => {
                      setPacks([])
                      setOrder(undefined)
                      navigate('/scan?mode=release')
                    }}>
                    취소
                  </button>
                )}

                {isReady && (
                  <button
                    disabled={!isReady}
                    onClick={() => completeOrder(order.id, packs)}
                    className={`rounded bg-red-500 text-white px-4 text-sm ${
                      !isReady && 'opacity-40'
                    }`}>
                    출고하기
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-center py-4"></div>
        </div>
      )}
    </div>
  )
}

export default IssueScanner
