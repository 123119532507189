import axios from 'api/axios'
import { SubHeaderComponent } from 'components/SubHeaderComponent'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Post } from 'types/Post'
import { PointTableBoxComponent } from './PointTableBoxComponent'

export const PointPage = () => {
  const navigate = useNavigate()
  const [points, setPoints] = useState<Post[]>([])

  const openAddPointPage = () => navigate('point/add')

  useEffect(() => {
    const fatchPoint = async () => {
      try {
        const res = await axios.get<{ posts: Post[] }>('/post?type=point')
        setPoints(res.data.posts)
      } catch (error) {
        console.log(error)
      }
    }
    fatchPoint()
  }, [])

  return (
    <div className="flex-1 bg-white">
      <SubHeaderComponent text="포인트 교환 관리" openAddPage={openAddPointPage} />

      <PointTableBoxComponent points={points} />
    </div>
  )
}
