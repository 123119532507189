import axios from 'api/axios'
import { useFactory } from 'hooks/useFactory'
import { getRetrievingStatusLabelText } from 'lib/helpers'
import { FilterRadio } from 'pages/Earthingpack/StatusRadio'
import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useCallback, useEffect, useState } from 'react'
import { RiBarcodeFill } from 'react-icons/ri'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Transfer, TransferStatus } from 'types/Transfer'

export const FactoryReceivePage = () => {
  const navigate = useNavigate()
  const { factory } = useFactory()
  const [retrievings, setRetrievings] = useState<Transfer[]>([])
  const [searchParams] = useSearchParams()
  const [status, setStatus] = useState<string>()
  const packsCount = retrievings
    .filter(r => r.status === TransferStatus.COMPLETED)
    .map(r => r.packs)
    .flat().length

  const missingPacksCount = retrievings
    .filter(r => r.status === TransferStatus.COMPLETED)
    .map(r => r.missingPacks)
    .flat().length

  const retrievingPacksCount = retrievings
    .filter(r => r.status === TransferStatus.STARTED)
    .map(r => r.packs)
    .flat().length

  const filteredRetrievings = !status
    ? retrievings
    : status === 'missing'
    ? retrievings.filter(r => r.missingPacks.length)
    : retrievings.filter(r => r.status === status && !r.missingPacks.length)

  const fetchRetrievings = useCallback(async () => {
    try {
      const res = await axios.get<{ transfers: Transfer[] }>(
        `/transfer?type=retrieving&factoryId=${factory.id}`,
      )
      setRetrievings(res.data.transfers)
    } catch (error) {
      console.log(error)
    }
  }, [factory.id])

  useEffect(() => {
    fetchRetrievings()
  }, [fetchRetrievings])

  useEffect(() => {
    setStatus(searchParams.get('status') ?? undefined)
  }, [searchParams])

  return (
    <div className="w-full px-0 py-5 sm:p-5">
      <div className="mx-2 sm:mx-0 md:flex md:items-center">
        <div className="flex-1 flex items-center gap-2">
          <h2 className="">입고내역</h2>

          {retrievingPacksCount > 0 && (
            <div className="px-2 rounded-full border border-gray-500 text-gray-500">
              회수중 {retrievingPacksCount}개
            </div>
          )}

          {packsCount > 0 && (
            <div className="px-2 rounded-full border border-teal-500 text-teal-500">
              입고완료 {packsCount}개
            </div>
          )}

          {missingPacksCount > 0 && (
            <div className="px-2 rounded-full border border-red-500 text-red-500">
              회수 후 미입고 {missingPacksCount}개
            </div>
          )}
        </div>

        <div className="mt-2 md:mt-0 flex justify-between items-center gap-2">
          <div className="flex gap-2">
            <FilterRadio filter={undefined} />
            <FilterRadio filter={TransferStatus.STARTED} />
            <FilterRadio filter={'missing'} />
            <FilterRadio filter={TransferStatus.COMPLETED} />
          </div>

          <button
            className="flex gap-1 items-center rounded text-white bg-teal-500 px-2 md:px-4 py-1 text-sm"
            onClick={() => navigate('/scan?mode=receive')}>
            <RiBarcodeFill className="text-sm hidden md:block" /> 입고스캔
          </button>
        </div>
      </div>

      <table className="w-full border rounded mt-4 sm:mt-2 text-center">
        <thead className="border-t border-b">
          <tr className="bg-gray-100">
            <th className="p-2">반납센터</th>
            <th className="p-2">수량</th>
            <th className="p-2">미입고</th>
            <th className="p-2">회수매니저</th>
            <th className="p-2 hidden md:block">일시</th>
            <th className="p-2">상태</th>
          </tr>
        </thead>

        <tbody className="">
          {filteredRetrievings.length === 0 && (
            <tr className="">
              <td colSpan={6} className="p-2 text-center">
                입고내역이 없습니다
              </td>
            </tr>
          )}

          {filteredRetrievings.map(r => {
            const hasMissingPacks = r.missingPacks.length > 0
            const missingPacksLabel = hasMissingPacks ? r.missingPacks.length : '-'
            const missingPacksLabelColor = hasMissingPacks
              ? 'text-red-500 font-bold '
              : 'text-gray-500'
            return (
              <tr className="hover:bg-gray-100 hover:cursor-pointer even:bg-gray-50" key={r.id}>
                <td className="p-2">{r.from?.name}</td>
                <td className="p-2">{r.packs.length}</td>
                <td className={`p-2 ${missingPacksLabelColor}`}>{missingPacksLabel}</td>
                <td className="p-2">{r.giver.name}</td>
                <td className="p-2 hidden md:block">{formatDateString(r.createdAt)}</td>
                <td className="p-2">{getRetrievingStatusLabelText(r)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
