import { Address } from './Address'
import { BussinessHour } from './BussinessHours'
import { Certificate } from './Certificate'
import { Pack } from './Pack'
import { Role } from './Role'
import { User } from './User'

export type Place = {
  id: number
  isValid: boolean
  name: string
  type: string
  phoneNumber: string
  email: string
  address: Address
  certificate?: Certificate
  businessHours: BussinessHour[]
  roles: Role[]
  createdAt: string
  updatedAt: string
  bankAccount?: BankAccount
  factory?: Place
  centers: Place[]
  retriever?: User
  packs: Pack[]
}

export const initialFactory: Place = {
  id: -1,
  isValid: false,
  name: '',
  type: '',
  phoneNumber: '',
  email: '',
  address: { address: '', roadAddress: '', extraAddress: '' },
  businessHours: [],
  roles: [],
  createdAt: '',
  updatedAt: '',
  centers: [],
  packs: [],
}

export type BankAccount = {
  bank: string
  accountNumber: string
  ownerName: string
}
