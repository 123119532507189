import axios from 'api/axios'
import { PostCodeComponent } from 'components/PostCodeComponent'
import { Roles } from 'enums/Roles'
import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Place } from 'types/Place'

export const FactoryAdminUpdatePage = () => {
  const { factoryId } = useParams()
  const navigate = useNavigate()
  const [factory, setFactory] = useState<Place>()
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [address, setAddress] = useState('')
  const [roadAddress, setRoadAddress] = useState('')
  const [extraAddress, setExtraAddress] = useState('')
  const [email, setEmail] = useState('')
  const [ownerName, setOwnerName] = useState('')

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const updateFactoryData = useCallback(async () => {
    try {
      await axios.put<{ place: Place }>(`/places/${factoryId}`, {
        name,
        phoneNumber,
        ownerName,
        address: { address, roadAddress, extraAddress },
        email,
      })
      navigate(`/factory/${factoryId}`)
    } catch (error) {
      console.log(error)
    }
  }, [address, email, extraAddress, factoryId, name, navigate, ownerName, phoneNumber, roadAddress])

  const deleteFactoryData = async () => {
    try {
      await axios.delete(`/places/${factoryId}`)
      navigate('/factory')
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const fatchFactoryData = async () => {
      try {
        const res = await axios.get<{ place: Place }>(`/places/${factoryId}`)
        const { place } = res.data

        setFactory(place)
        setName(place.name)

        setPhoneNumber(place.phoneNumber)
        setAddress(place.address.address)
        setRoadAddress(place.address.roadAddress)
        setExtraAddress(place.address.extraAddress)
        setOwnerName(place.roles.find(r => r.type === Roles.CHIEF)?.user?.name || '')
        setEmail(place.email || '')
      } catch (error) {
        console.log(error)
      }
    }
    fatchFactoryData()
  }, [factoryId])

  return (
    <div className="flex p-5 flex-1 justify-center gap-10 ">
      <div className="flex gap-5 flex-col mxl:border-0 mxl:px-0 w-full">
        <h2 className="flex justify-between items-center border-b border-solid border-gray-300 h-12 mxl:p-2 text-2xl">
          수정하기
        </h2>

        <div className="border border-solid border-gray-300">
          <dl className="flex flex-wrap">
            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 flex items-center border-r border-solid border-gray-300 mxl:w-[35%] font-bold">
              세척센터명
            </dt>
            <dd className=" py-2 px-5 w-5/6 flex items-center mxl:w-[65%]">
              <input
                type="text"
                className="leading-10 border border-solid border-gray-300 w-full px-2"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="세척센터명"
              />
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold">
              대표명
            </dt>
            <dd className=" py-2 px-5 w-5/6 border-t border-solid border-gray-300 flex items-center mxl:w-[65%] leading-10">
              <input
                type="text"
                className="leading-10 border border-solid border-gray-300 w-full px-2"
                value={ownerName}
                onChange={e => setOwnerName(e.target.value)}
                placeholder="대표명"
              />
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold">
              전화번호
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 border-solid border-gray-300 flex items-center mxl:w-[65%]">
              <input
                type="text"
                className=" leading-10 border border-solid border-gray-300 w-full px-2"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                placeholder="전화번호"
              />
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold">
              주소
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 border-solid border-gray-300 flex mxl:w-[65%] gap-2 flex-col">
              <div className="flex gap-2">
                <input
                  type="text"
                  className=" leading-10 border border-solid border-gray-300 w-full mxl:w-1/2 px-2"
                  value={address}
                  onChange={() => {}}
                  onClick={() => setIsPopupOpen(true)}
                  placeholder="주소"
                />
                <button
                  className="w-1/5 bg-gray-100 border border-gray-300 border-solid mxl:w-1/2 hover:bg-gray-300"
                  onClick={() => setIsPopupOpen(true)}>
                  주소찾기
                </button>
              </div>
              <input
                type="text"
                className=" leading-10 border border-solid border-gray-300 w-full px-2"
                value={extraAddress}
                onChange={e => setExtraAddress(e.target.value)}
                placeholder="상세주소"
              />
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold">
              가입일
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 border-solid border-gray-300 flex items-center mxl:w-[65%]">
              {factory ? formatDateString(factory.createdAt) : '-'}
            </dd>
            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold">
              수정일
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 border-solid border-gray-300 flex items-center mxl:w-[65%]">
              {factory ? formatDateString(factory.updatedAt) : '-'}
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold">
              이메일
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 border-solid border-gray-300 flex mxl:w-[65%] gap-2 flex-col">
              <input
                type="text"
                className=" leading-10 border border-solid border-gray-300 w-full px-2"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="이메일"
              />
            </dd>
          </dl>
        </div>
        <div className="flex items-center justify-center gap-3 mt-5 mxl:flex-col">
          <button
            className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-blue-600 text-white hover:bg-blue-800"
            onClick={() => updateFactoryData()}>
            수정
          </button>
          <button
            className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-slate-200 text-gray-500 hover:bg-gray-400 hover:text-white"
            onClick={() => navigate(-1)}>
            취소
          </button>
          <button
            className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-slate-200 text-gray-500 hover:bg-red-600 hover:text-white"
            onClick={() => deleteFactoryData()}>
            삭제
          </button>
        </div>
      </div>
      {isPopupOpen && (
        <PostCodeComponent
          setAddress={setAddress}
          setRoadAdress={setRoadAddress}
          setIsPopupOpen={setIsPopupOpen}
        />
      )}
    </div>
  )
}
