import React from 'react'
import { MdImageNotSupported } from 'react-icons/md'

interface Props {
  imageUrl?: string
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const CertificateImageModal = ({ imageUrl, setIsOpenModal }: Props) => {
  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 z-10 flex justify-center items-center">
      <div
        className="bg-black bg-opacity-40 absolute top-0 left-0 right-0 bottom-0"
        onClick={() => setIsOpenModal(false)}></div>
      <div className=" z-20 bg-white overflow-hidden rounded-md h-full mxl:h-auto mxl:w-full">
        {imageUrl ? (
          <img src={imageUrl} alt="certificateImage" className=" object-contain w-full h-full" />
        ) : (
          <div className=" text-gray-400 text-3xl flex justify-center gap-8">
            <span className="text-xl">
              <MdImageNotSupported />
              이미지가 없습니다
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
