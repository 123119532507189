import axios from 'api/axios'
import { Roles } from 'enums/Roles'
import { useHQ } from 'hooks/useHQ'
import { beep } from 'lib/helpers'
import { PartnerList } from 'pages/Partner/PartnerPage'
import { formatPhoneNumber } from 'pages/RetrivalManager/CentersPage'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Pack } from 'types/Pack'
import { Place } from 'types/Place'

export function AdminReleaseScanner() {
  const [packs, setPacks] = useState<Pack[]>([])
  const inputRef = useRef<HTMLInputElement>(null)
  const [partner, setPartner] = useState<Place>()
  const [searchParams, setSearchParams] = useSearchParams()
  const { hq, fetchHQ } = useHQ()
  const hqSerialNumbers = hq?.packs.map(pack => pack.serialNumber) || []
  const isReady = packs.length > 0

  const getPartner = useCallback(async (id: string) => {
    const res = await axios.get(`/places/${id}`)
    setPartner(res.data.place)
  }, [])

  useEffect(() => {
    fetchHQ()
  }, [fetchHQ])

  useEffect(() => {
    const partnerId = searchParams.get('partnerId')
    if (!partnerId) return
    getPartner(partnerId)
  }, [getPartner, searchParams])

  const addPack = () => {
    if (!inputRef.current) return
    try {
      const serialNumber = inputRef.current.value
      const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
      if (korean.test(serialNumber)) throw new Error('언어를 영어로 전환 후 바코드를 스캔하세요.')

      if (!hqSerialNumbers.includes(serialNumber))
        throw new Error('보유하고 있지 않은 얼싱팩 입니다.')

      if (packs.find(p => p.serialNumber === serialNumber))
        throw new Error('이미 스캔한 얼싱팩 입니다.')

      const pack = hq?.packs.find(p => p.serialNumber === serialNumber)

      if (!pack) return

      setPacks(p => [...p, pack])

      beep()
    } catch (error) {
      beep(false)
      if (error instanceof Error) alert(error.message)
    } finally {
      inputRef.current.value = ''
    }
  }

  const submitIssue = async () => {
    if (!partner) return
    try {
      await axios.post('/transfer/complete-issueing', { packs, partnerId: partner.id })
      setPartner(undefined)
      setPacks([])
      searchParams.delete('partnerId')
      setSearchParams(searchParams)
      fetchHQ()
    } catch (error) {
      console.log(error)
    }
  }

  const reset = () => {
    searchParams.delete('partnerId')
    setSearchParams(searchParams)
    setPartner(undefined)
    setPacks([])
  }

  return (
    <div className="w-full ">
      {!partner ? (
        <div>
          <div className="w-full py-10 flex items-center justify-center bg-gray-100 mt-5 text-3xl font-bold text-gray-500 rounded -mb-2">
            출고 대상 파트너를 선택 후 스캔하세요
          </div>
          <PartnerList />
        </div>
      ) : (
        <div className="items-center justify-center flex flex-1 flex-col">
          {partner && (
            <div className="">
              {packs.length === 0 && (
                <div className="pt-5">
                  <h3 className="">출고스캔하세요</h3>
                </div>
              )}

              <input
                autoFocus
                ref={inputRef}
                type="text"
                onBlur={() => inputRef.current?.focus()}
                className="w-0 h-0 noKorean"
                onKeyPress={e => {
                  if (e.key === 'Enter') addPack()
                }}
              />

              {packs.length > 0 && (
                <div className="pt-5">
                  <h3 className="">{`목적지와 팩(${packs.length}개)을 검토하신 후 출고하세요`}</h3>
                </div>
              )}

              <ul className=" mt-4 bg-gray-200 py-4 px-6 rounded">
                <li className="">
                  목적지: <span className="font-bold text-lg">{partner.name}</span>
                </li>

                <li className="mt-2">
                  주소:{' '}
                  {partner.address.address ? partner.address.address : partner.address.roadAddress}
                  {partner.address.extraAddress}
                </li>

                <li className="">
                  담당자:{' '}
                  {partner.roles.find(r => r.type === Roles.CHIEF)?.user.name || '모르겠어요'}
                </li>

                <li className="">전화번호: {formatPhoneNumber(partner.phoneNumber)}</li>
              </ul>
            </div>
          )}

          <div className="mt-3 flex gap-2">
            <button
              disabled={!isReady}
              onClick={() => submitIssue()}
              className={`rounded py-2 bg-red-500 text-white px-5 text-sm ${
                !isReady && 'opacity-40'
              }`}>
              출고하기
            </button>

            <button
              onClick={() => reset()}
              className={`rounded py-2 bg-gray-400 text-white px-5 text-sm `}>
              취소
            </button>
          </div>

          {packs.length > 0 && (
            <div className="border-2 py-4 w-48 mt-3 text-center rounded">
              <h3 className="mb-2 font-bold">팩 목록({packs.length}개)</h3>
              <ul className="mt-1">
                {packs.map(p => (
                  <li className="mb-1" key={p.id}>
                    {p.serialNumber}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
