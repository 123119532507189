import axios from 'api/axios'
import { SubHeaderComponent } from 'components/SubHeaderComponent'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Post } from 'types/Post'
import { EventTableBoxComponent } from './EventTableBoxComponent'

export const EventPage = () => {
  const navigate = useNavigate()
  const [events, setEvents] = useState<Post[]>([])

  const openAddEventPage = () => navigate('event/add')

  useEffect(() => {
    const fatchEvent = async () => {
      try {
        const res = await axios.get<{ posts: Post[] }>('/post?type=event')
        setEvents(res.data.posts)
      } catch (error) {
        console.log(error)
      }
    }
    fatchEvent()
  }, [])

  return (
    <div className="flex-1 bg-white">
      <SubHeaderComponent text="이벤트 게시물 관리" openAddPage={openAddEventPage} />

      <EventTableBoxComponent events={events} />
    </div>
  )
}
