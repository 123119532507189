import axios from 'api/axios'
import { SubHeaderComponent } from 'components/SubHeaderComponent'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NoticeTableBoxComponent } from './NoticeTableBoxComponent'
import { useAppDispatch, useAppSelector } from 'store'
import { Post } from 'types/Post'

export const NoticePage = () => {
  const navigate = useNavigate()
  const [notices, setNotices] = useState<Post[]>([])
  const user = useAppSelector(s => s.user)

  const openAddNoticePage = () => {
    navigate('notice/add')
  }

  useEffect(() => {
    const fatchNotice = async () => {
      try {
        const res = await axios.get<{ posts: Post[] }>('/post?type=notice')
        const { posts } = res.data
        setNotices(posts)
      } catch (error) {
        console.log(error)
      }
    }
    fatchNotice()
  }, [])

  return user.roles[0].type === 'admin' ? (
    <>
      <SubHeaderComponent text="공지사항 관리" openAddPage={openAddNoticePage} />
      <NoticeTableBoxComponent notices={notices} />
    </>
  ) : (
    <div className="flex-1 py-8 px-5">
      <div className="flex items-center justify-between mxl:px-5 mxl:py-0 mxl:flex-col mxl:items-start gap-2 h-12 mb-2 mxl:h-[92px]">
        <h2 className="text-2xl">공지사항</h2>
        <div className="flex gap-4 mxl:w-full"></div>
      </div>
      <NoticeTableBoxComponent
        notices={notices.filter(post => post.range.findIndex(range => range === 'factory') !== -1)}
      />
    </div>
  )
}
