import axios from 'api/axios'
import { useHQ } from 'hooks/useHQ'
import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useCallback, useEffect, useState } from 'react'
import { Transfer, Transfers } from 'types/Transfer'

function AdminIssueList() {
  const [issueList, setIssueList] = useState<Transfer[]>([])
  const { hq } = useHQ()
  const [placesList, setPlacesList] = useState<any[]>([])

  const [idFilter, setIdFilter] = useState(-1)

  let filteredTransfer = idFilter < 0 ? issueList : issueList.filter(t => t.to.id === idFilter)

  useEffect(() => {
    if (issueList.length === 0) return
    const placesToList = issueList.map(i => ({ id: i.to.id, name: i.to.name }))

    const filterPlace = placesToList.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i)
    setPlacesList(filterPlace)
  }, [issueList])

  const fetchIssues = useCallback(async () => {
    if (!hq) return

    const res = await axios.get<{ transfers: Transfer[] }>(
      `/transfer?type=${Transfers.ISSUING}&hqId=${hq.id}`,
    )

    setIssueList(res.data.transfers.filter(t => t.type === Transfers.ISSUING))
  }, [hq])

  useEffect(() => {
    fetchIssues()
  }, [fetchIssues])

  return (
    <div className="w-full p-5">
      <div className="flex-1 flex justify-between items-center">
        <h2>출고 내역</h2>

        <div className="flex gap-2 items-center">
          <select
            className="border border-gray-300 px-0.5 py-2 rounded ml-2"
            onChange={e => {
              setIdFilter(Number(e.target.value))
            }}>
            <option value={-1}>목적지 필터</option>
            {placesList.map(p => (
              <option value={p.id} key={p.id}>
                {p.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <table className="w-full border rounded mt-2">
        <thead className="border-t border-b">
          <tr className="bg-gray-100">
            <th className="p-2 text-center">목적지</th>
            <th className="p-2 text-center">수량</th>
            <th className="p-2 text-center">수령인</th>
            <th className="p-2 text-center">일시</th>
          </tr>
        </thead>

        <tbody className="w-full">
          {issueList.length === 0 && (
            <tr className="">
              <td colSpan={6} className="p-2 text-center">
                출고내역이 없습니다
              </td>
            </tr>
          )}

          {filteredTransfer.map(transfer => {
            return (
              <tr
                className="hover:bg-gray-100 hover:cursor-pointer even:bg-gray-50"
                key={transfer.id}>
                <td className="p-2 text-center">{transfer.to?.name}</td>
                <td className="p-2 text-center">{transfer.packs.length}</td>
                <td className="p-2 text-center">{transfer.receiver?.name || '미정'}</td>
                <td className="p-2 text-center">{formatDateString(transfer.createdAt)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default AdminIssueList
