import axios from 'api/axios'
import { useFactory } from 'hooks/useFactory'
import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Order } from './Order'
import { OrderStatus } from './OrderStatus'

export default function FactoryOrderList({ needFilter }: { needFilter?: boolean }) {
  const [orders, setOrders] = useState<Order[]>([])
  const [filter, setFilter] = useState<OrderStatus | undefined>(OrderStatus.PENDING)
  const filteredOrders = filter ? orders.filter(o => o.status === filter) : orders
  const { factory } = useFactory()

  const navigate = useNavigate()

  const fetchOrders = useCallback(async () => {
    try {
      const res = await axios.get<{ orders: Order[] }>('/order')
      setOrders(res.data.orders.filter(order => order.from.id === factory.id))
    } catch (error) {
      console.log(error)
    }
  }, [factory.id])

  useEffect(() => {
    fetchOrders()
  }, [fetchOrders])

  return (
    <div className="">
      {needFilter && (
        <div className="flex items-center justify-between">
          <h2>출고주문</h2>

          <div className="flex gap-2">
            <div className="">
              <input
                type="radio"
                name="orderStatus"
                id="all"
                checked={!filter}
                onChange={() => setFilter(undefined)}
              />
              <label className="hover:cursor-pointer ml-1" htmlFor="all">
                전체
              </label>
            </div>

            <div className="">
              <input
                type="radio"
                name="orderStatus"
                id="completed"
                checked={filter === OrderStatus.COMPLETED}
                onChange={() => setFilter(OrderStatus.COMPLETED)}
              />
              <label className="hover:cursor-pointer ml-1" htmlFor="completed">
                출고완료
              </label>
            </div>

            <div className="">
              <input
                type="radio"
                name="orderStatus"
                id="pending"
                checked={filter === OrderStatus.PENDING}
                onChange={() => setFilter(OrderStatus.PENDING)}
              />
              <label className="hover:cursor-pointer ml-1" htmlFor="pending">
                출고대기
              </label>
            </div>
          </div>
        </div>
      )}

      <table className="w-full border rounded mt-2">
        <thead className="border-t border-b">
          <tr className="bg-gray-100">
            <th className="p-2 text-center">목적지</th>
            <th className="p-2 text-center">수량</th>
            <th className="p-2 text-center">의뢰인</th>
            <th className="p-2 text-center">일시</th>
            <th className="p-2 text-center">상태</th>
            <th className="p-2 text-center">액션</th>
          </tr>
        </thead>

        <tbody className="">
          {filteredOrders.length === 0 && (
            <tr className="">
              <td colSpan={6} className="p-2 text-center">
                출고주문이 없습니다
              </td>
            </tr>
          )}

          {filteredOrders.map(o => {
            const isPending = o.status === OrderStatus.PENDING
            return (
              <tr className="hover:bg-gray-100 hover:cursor-pointer even:bg-gray-50" key={o.id}>
                <td className="p-2 text-center">{o.to.name}</td>
                <td className="p-2 text-center">{o.quantity}</td>
                <td className="p-2 text-center">{o.orderer.name}</td>
                <td className="p-2 text-center">{formatDateString(o.createdAt)}</td>
                <td className="p-2 text-center">{getOrderLabelText(o)}</td>
                <td className="p-2 text-center">
                  {isPending && (
                    <button
                      disabled={!isPending}
                      onClick={() => navigate(`/scan?mode=release&orderId=${o.id}`)}
                      className={`text-white px-2 rounded ${
                        isPending ? 'bg-teal-500' : 'bg-gray-500'
                      }`}>
                      출고하기
                    </button>
                  )}

                  {!isPending && <span className="text-gray-400">-</span>}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export function getOrderLabelText(o: Order) {
  return o.status === OrderStatus.PENDING ? '출고대기' : '출고완료'
}
