import axios from 'api/axios'
import { usePostRange } from 'hooks/usePostRange'
import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import React, { useEffect, useState } from 'react'
import { IoMdList } from 'react-icons/io'
import { MdDelete } from 'react-icons/md'
import { RiEditFill } from 'react-icons/ri'
import { useNavigate, useParams } from 'react-router-dom'
import { Post } from 'types/Post'

export const InfoDetailsPage = () => {
  const { infoId } = useParams()
  const navigate = useNavigate()
  const [info, setInfo] = useState<Post>()
  const { updateRange, renderPostRange } = usePostRange({ readOnly: true })

  const deleteInfo = async () => {
    if (!window.confirm('삭제하시겠습니까?')) return
    try {
      await axios.delete(`/post/${infoId}`)
      navigate('/setting')
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!infoId) return

    const fetchInfo = async () => {
      const res = await axios.get<{ post: Post }>(`post/${infoId}`)

      setInfo(res.data.post)
      updateRange(res.data.post.range)
    }

    fetchInfo()
  }, [infoId, updateRange])

  if (!info) return null // fallback

  return (
    <div className="flex-1 p-5 bg-white mxl:p-5">
      <div className="flex flex-col pb-2 text-lg border-b-2 border-gray-300 border-solid mxl:pb-5">
        <div className="flex items-center justify-between mxl:flex-col mxl:items-start h-12">
          <h2 className="text-2xl">{info.title}</h2>
          {renderPostRange()}
        </div>
        <div className="flex justify-between w-full mt-2">
          <span className="text-gray-500 ">{formatDateString(info.createdAt)}</span>

          <div className="flex">
            <button
              className=" flex items-center text-blue-600 border border-blue-600 hover:bg-blue-800 hover:border-blue-700 hover:text-white px-2 py-1 rounded"
              onClick={() => {
                navigate('edit')
              }}>
              <RiEditFill />
              수정
            </button>
            <button
              className="flex items-center text-red-600 border  border-red-600 hover:bg-red-600 hover:border-red-600 hover:text-white px-2 py-1 rounded ml-2"
              onClick={deleteInfo}>
              <MdDelete />
              삭제
            </button>

            <button
              className="flex items-center border rounded border-gray-400 text-gray-500 px-2 py-1 ml-2 hover:text-white hover:bg-gray-400"
              onClick={() => {
                navigate('/setting')
              }}>
              <IoMdList />
              목록보기
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-5 py-5">
        <div
          className="overflow-auto bg-white web_inner_html"
          dangerouslySetInnerHTML={{ __html: info.body }}></div>
      </div>
    </div>
  )
}
