import axios from 'api/axios'
import { UserListComponent } from 'components/UserListComponent'
import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useEffect, useState } from 'react'
import { IoMdList } from 'react-icons/io'
import { MdDelete } from 'react-icons/md'
import { RiEditFill } from 'react-icons/ri'
import { useNavigate, useParams } from 'react-router-dom'
import { Post, Reward } from 'types/Post'

export const PointDetailsPage = () => {
  const navigate = useNavigate()
  const { pointId } = useParams()
  const [point, setPoint] = useState<Post>()
  const [reward, setReward] = useState<Reward>()

  const deletePost = async () => {
    if (!window.confirm('삭제하시겠습니까?')) return

    try {
      await axios.delete(`/post/${pointId}`)
    } catch (error) {
      console.log(error)
    } finally {
      navigate('/setting')
    }
  }

  useEffect(() => {
    if (!pointId) return

    const fetchPost = async () => {
      const res = await axios.get<{ post: Post }>(`post/${pointId}`)
      setPoint(res.data.post)

      const res2 = await axios.get<{ reward: Reward }>(`reward/${res.data.post.reward.id}`)
      setReward(res2.data.reward)
    }

    fetchPost()
  }, [pointId])

  if (!point) return null // fallback

  return (
    <div className="flex flex-col flex-1 w-full h-full p-5 mxl:p-5">
      <div className="flex text-lg border-b-2 border-solid border-gray-300 flex-col pb-2">
        <div className="flex flex-col flex-1 gap-2 overflow-hidden items-start">
          <img className="h-60 object-contain m-auto" src={point.bannerUrl} alt="bannerImg" />

          <div className="flex justify-between mt-2 w-full">
            <div className="text-gray-500">
              <span>사용 포인트 : {point?.point ? point.point : '-'}</span>
              <span className=" ml-4">{formatDateString(point.createdAt)}</span>
            </div>

            <div className="flex">
              <button
                className=" flex items-center text-blue-600 border border-blue-600 hover:bg-blue-800 hover:border-blue-700 hover:text-white px-2 py-1 rounded"
                onClick={() => {
                  navigate('edit')
                }}>
                <RiEditFill />
                수정
              </button>
              <button
                className="flex items-center text-red-600 border  border-red-600 hover:bg-red-600 hover:border-red-600 hover:text-white px-2 py-1 rounded ml-2"
                onClick={deletePost}>
                <MdDelete />
                삭제
              </button>

              <button
                className="flex items-center border rounded border-gray-400 text-gray-500 px-2 py-1 ml-2 hover:text-white hover:bg-gray-400"
                onClick={() => {
                  navigate('/setting')
                }}>
                <IoMdList />
                목록보기
              </button>
            </div>
          </div>

          <div className="">{point.reward.title}</div>
        </div>
      </div>
      <div className="flex flex-col gap-5 py-5">
        <div
          className="overflow-auto bg-white web_inner_html"
          dangerouslySetInnerHTML={{ __html: point.body }}></div>
      </div>

      <UserListComponent text="포인트 신청 명단" reward={reward} />
    </div>
  )
}
