import { AiFillHome } from 'react-icons/ai'
import {
  RiBuilding3Fill,
  RiBuilding4Fill,
  RiFileListFill,
  RiRedPacketFill,
  RiSettings4Fill,
  RiStoreFill,
  RiTruckFill,
  RiUser3Fill,
  RiNotification2Fill,
} from 'react-icons/ri'
import { useAppSelector } from 'store'
import { selectIsAdmin, selectIsChief, selectIsFactoryWorker } from 'store/userSlice'
import { SideBarItem } from './SideBarItem'

export const SideBar = () => {
  const isAdmin = useAppSelector(selectIsAdmin)
  const isChief = useAppSelector(selectIsChief)
  const isFactoryWorker = useAppSelector(selectIsFactoryWorker)
  const isFactoryChief = isChief && isFactoryWorker

  return (
    <div className="bg-slate-700 text-white w-[12vw] min-w-[255px] mxl:w-full sticky left-0 top-[56px] h-[calc(100vh-56px)] mxl:h-auto mxl:p-0 overflow-x-auto whitespace-nowrap scrollbar-hide z-10 mxl:top-14">
      <ul className="flex flex-col mxl:flex-row">
        {isAdmin && (
          <>
            <SideBarItem route="/" title="대시보드" Icon={AiFillHome} />
            <SideBarItem route="/factory" title="세척센터" Icon={RiBuilding3Fill} />
            <SideBarItem route="/center" title="반납센터" Icon={RiStoreFill} />
            <SideBarItem route="/partner" title="파트너" Icon={RiBuilding4Fill} />
            <SideBarItem route="/client" title="고객" Icon={RiUser3Fill} />
            <SideBarItem route="/earthingpack" title="얼싱팩" Icon={RiRedPacketFill} />
            <SideBarItem route="/setting" title="게시물" Icon={RiSettings4Fill} />
            <SideBarItem route="/order" title="주문" Icon={RiSettings4Fill} />
            <SideBarItem route="/earthingpack/scan" title="스캔" Icon={RiSettings4Fill} />
            <SideBarItem route="/earthingpack/recive" title="입고내역" Icon={RiFileListFill} />
            <SideBarItem route="/earthingpack/release" title="출고내역" Icon={RiFileListFill} />
          </>
        )}

        {isFactoryChief && (
          <>
            <SideBarItem route="/" title="대시보드" Icon={AiFillHome} />
            <SideBarItem route="/centers" title="반납센터" Icon={RiStoreFill} />
            <SideBarItem route="/retrievers" title="회수매니저" Icon={RiTruckFill} />
            <SideBarItem route="/earthing" title="얼싱팩" Icon={RiRedPacketFill} />
            <SideBarItem route="/receive" title="입고" Icon={RiFileListFill} />
            <SideBarItem route="/release" title="출고" Icon={RiFileListFill} />
            <SideBarItem route="/scan" title="스캔" Icon={RiFileListFill} />
            <SideBarItem route="/setting/notice" title="공지사항" Icon={RiNotification2Fill} />
          </>
        )}
      </ul>
    </div>
  )
}
