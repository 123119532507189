import axios from 'api/axios'
import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useEffect, useState } from 'react'
import { IoMdList } from 'react-icons/io'
import { MdDelete } from 'react-icons/md'
import { RiEditFill } from 'react-icons/ri'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Post } from 'types/Post'

export const EventDetailsPage = () => {
  const navigate = useNavigate()
  const { eventId } = useParams()
  const [event, setEvent] = useState<Post>()

  const deleteEvent = async () => {
    if (!window.confirm('삭제하시겠습니까?')) return

    try {
      await axios.delete(`/post/${eventId}`)
      navigate('/setting/event')
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!eventId) return

    const fetchEvent = async () => {
      const res = await axios.get<{ post: Post }>(`post/${eventId}`)

      setEvent(res.data.post)
    }

    fetchEvent()
  }, [eventId])

  if (!event) return null // fallback

  return (
    <div className="flex flex-col flex-1 w-full h-full p-5 mxl:p-0">
      <div className="flex pb-2 text-lg border-b-2 border-gray-300 border-solid flex-col">
        <div className="flex flex-col flex-1 gap-2 overflow-hidden items-start">
          <img className="h-60 object-contain m-auto" src={event.bannerUrl} alt="bannerImg" />
        </div>
        <div className="flex justify-between mt-2 w-full mxl:px-3 mxl:flex-col">
          <div className="text-gray-500 mxl:flex mxl:flex-col">
            <span className="web_inner_html">
              연결된 링크 : {event?.url ? <Link to={`/${event.url}`}>{event.url}</Link> : ''}
            </span>
            <span className=" ml-4 mxl:m-0">{formatDateString(event.createdAt)}</span>
          </div>

          <div className="flex">
            <button
              className=" flex items-center text-blue-600 border border-blue-600 hover:bg-blue-800 hover:border-blue-700 hover:text-white px-2 py-1 rounded"
              onClick={() => {
                navigate('edit')
              }}>
              <RiEditFill />
              수정
            </button>
            <button
              className="flex items-center text-red-600 border  border-red-600 hover:bg-red-600 hover:border-red-600 hover:text-white px-2 py-1 rounded ml-2"
              onClick={deleteEvent}>
              <MdDelete />
              삭제
            </button>

            <button
              className="flex items-center border rounded border-gray-400 text-gray-500 px-2 py-1 ml-2 hover:text-white hover:bg-gray-400"
              onClick={() => {
                navigate('/setting')
              }}>
              <IoMdList />
              목록보기
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-5 py-5">
        <div
          className="overflow-auto bg-white web_inner_html"
          dangerouslySetInnerHTML={{ __html: event.body }}></div>
      </div>
    </div>
  )
}
