import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialFactory, Place } from 'types/Place'

const factorySlice = createSlice({
  name: 'factory',
  initialState: initialFactory,
  reducers: {
    resetFactory: state => {
      Object.assign(state, initialFactory)
    },
    setFactory: (state, action: PayloadAction<Partial<Place>>) => {
      Object.assign(state, action.payload)
    },
  },
  extraReducers: _ => {}, // async reducers here
})

// export action creators
export const { resetFactory, setFactory } = factorySlice.actions

// export selectors
export default factorySlice
