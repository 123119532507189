import axios from 'api/axios'
import { Roles } from 'enums/Roles'
import { useState } from 'react'
import { useAppSelector } from 'store'
import { selectIsChief, selectIsFactoryWorker } from 'store/userSlice'

export const RetrieverAddPage = () => {
  const isChief = useAppSelector(selectIsChief)
  const isFactoryWorker = useAppSelector(selectIsFactoryWorker)
  const isFactoryChief = isChief && isFactoryWorker
  const user = useAppSelector(s => s.user)
  const factoryChiefRole = user.roles.find(r => r.type === Roles.CHIEF)

  const [code, setCode] = useState('')
  const requestInvitationCode = async () => {
    if (!factoryChiefRole) return

    const res = await axios.post<{ code: string }>('/invite/request', { role: factoryChiefRole })
    setCode(res.data.code)
  }

  if (!isFactoryChief) return <div className="">권한이 없어요</div>

  return (
    <div className="flex flex-col items-center justify-center flex-1 w-full">
      <div className="flex flex-col gap-10 px-32 py-24 text-center border border-gray-300 border-solid rounded-2xl mxl:border-none mxl:p-5">
        <h2 className="text-4xl mxl:text-3xl mxl:py-5">회수 매니저 초대</h2>

        {code && (
          <div className="">
            <div className="text-4xl font-bold">{code}</div>
            <span className="block m-2 text-gray-500">이 번호를 회수 매니저에게 알려주세요</span>
          </div>
        )}
        <div className="">
          <button
            className={`px-5 py-3 text-white rounded-md  ${
              code ? `bg-gray-400 hover:bg-blue-600` : `bg-blue-600 hover:bg-blue-800`
            }`}
            onClick={() => requestInvitationCode()}>
            초대코드 생성
          </button>
          {!code && (
            <span className="block m-2 text-gray-500">위 버튼을 눌러 초대코드를 생성하세요</span>
          )}
        </div>
      </div>
    </div>
  )
}
