import { Dispatch, SetStateAction } from 'react'
import DaumPostcode, { Address } from 'react-daum-postcode'
import { MdClose } from 'react-icons/md'

interface Props {
  setAddress: Dispatch<SetStateAction<string>>
  setRoadAdress: Dispatch<SetStateAction<string>>
  setIsPopupOpen: Dispatch<SetStateAction<boolean>>
}

export const PostCodeComponent = ({ setAddress, setRoadAdress, setIsPopupOpen }: Props) => {
  const onCompletePost = (data: Address) => {
    setAddress(data.address)
    setRoadAdress(data.roadAddress)
    setIsPopupOpen(false)
  }

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-10"
      onClick={() => setIsPopupOpen(false)}>
      <div className="container flex flex-col items-center justify-center gap-4 ">
        <div className=" w-[600px]">
          <button
            className="float-right text-4xl text-white bg-red-500 rounded-md mxl:hidden"
            onClick={() => setIsPopupOpen(false)}>
            <MdClose />
          </button>
        </div>
        <DaumPostcode
          onComplete={onCompletePost}
          onClose={() => setIsPopupOpen(false)}
          style={{ maxWidth: '600px', height: '600px', overflow: 'hidden', borderRadius: '3px' }}
        />
      </div>
    </div>
  )
}
