import { SubHeaderComponent } from 'components/SubHeaderComponent'
import React from 'react'

export const ManagerPage = () => {
  return (
    <div className="flex-1 p-5  ">
      <SubHeaderComponent text="" />
      {/* <DataTableBox openModal={handleClick} datas={datas} /> */}
    </div>
  )
}
