import { PAGENATION_PER_PAGE, PAGENATION_RANGE } from 'lib/constants'
import { useSearchParams } from 'react-router-dom'

export function Pagenator({ total }: { total: number }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const per = searchParams.has('perPage')
    ? Number(searchParams.get('perPage'))
    : PAGENATION_PER_PAGE
  const lastPage = Math.ceil(total / per)

  const range = searchParams.has('range') ? Number(searchParams.get('range')) : PAGENATION_RANGE
  const current = searchParams.has('page') ? Number(searchParams.get('page')) : 1

  const size = Math.min(range, lastPage)
  const delta = Math.ceil(size / 2)
  const isFirstRange = current > delta
  const isLastRange = current + size >= lastPage
  const isFirst = !!total && current === 1
  const isLast = !!total && current === lastPage

  const setQuery = (pageNumber: number) => {
    pageNumber > 1 ? searchParams.set('page', String(pageNumber)) : searchParams.delete('page')
    setSearchParams(searchParams)
  }

  if (lastPage < 2) return null

  return (
    <div className="flex justify-center">
      <button
        disabled={isFirst}
        className={`p-2 ${isFirst ? 'text-gray-400' : 'text-black'}`}
        onClick={() => setQuery(1)}>
        first
      </button>

      <button
        disabled={isFirst}
        className={`p-2 ${isFirst ? 'text-gray-400' : 'text-black'}`}
        onClick={() => setQuery(current - 1)}>
        left
      </button>

      {Array(size)
        .fill(0)
        .map((_, i) => {
          const page = isLastRange
            ? lastPage - size + i + 1
            : isFirstRange
            ? i + 1
            : current - delta + i + 1

          const currentPageStyle = current === page ? 'bg-gray-300' : 0

          return (
            <button key={page} className={`p-2 ${currentPageStyle}`} onClick={() => setQuery(page)}>
              {page}
            </button>
          )
        })}

      <button
        disabled={isLast}
        className={`p-2 ${isLast ? 'text-gray-400' : 'text-black'}`}
        onClick={() => setQuery(current + 1)}>
        right
      </button>

      <button
        disabled={isLast}
        className={`p-2 ${isLast ? 'text-gray-400' : 'text-black'}`}
        onClick={() => setQuery(lastPage)}>
        last
      </button>
    </div>
  )
}
