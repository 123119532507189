import axios from 'api/axios'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store'
import { clearUser, selectIsLoggedIn } from 'store/userSlice'
import WebLogo from 'assets/svg/WebLogo.svg'

export const MainHeaderComponent = () => {
  const navigate = useNavigate()
  const user = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  return (
    <div className="flex justify-between border-b border-slate-700 border-solid bg-white items-center sticky top-0 h-[56px] px-5 mxl:px-2 mxl:py-2 mxl:h-14 z-10">
      {isLoggedIn ? (
        <>
          <div className=" w-[11vw] min-w-[180px]">
            <Link to="/">
              <img src={WebLogo} alt="" />
            </Link>
          </div>
          <div className="flex items-center gap-4">
            <span className=" text-lg ">{user.name}님</span>
            <button
              className=" bg-gray-400 text-white rounded-full w-20 h-7 mxl:y-auto mxl:px-2"
              onClick={async () => {
                await axios.get('/auth/local/logout', { withCredentials: true })
                dispatch(clearUser())
              }}>
              로그아웃
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="  w-[11vw] mxl:min-w-[140px]">
            <Link to="/">
              <img src={WebLogo} alt="earthing pack logo" />
            </Link>
          </div>

          <div className=" flex items-center gap-4">
            <span className=" text-lg">로그인 해주세요</span>
            <button
              className=" bg-blue-500 text-white rounded-full w-20 h-7 mxl:w-auto mxl:y-auto mxl:px-2"
              onClick={() => {
                navigate('/login')
              }}>
              로그인
            </button>
          </div>
        </>
      )}
    </div>
  )
}
