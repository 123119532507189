import { combineReducers } from '@reduxjs/toolkit'
import factorySlice from './factorySlice'
import packSlice from './packSlice'
import userSlice from './userSlice'

const rootReducer = combineReducers({
  user: userSlice.reducer,
  pack: packSlice.reducer,
  factory: factorySlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
