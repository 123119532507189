import { Routes, Route } from 'react-router-dom'

import { LoginPage } from 'pages/Login/LoginPage'
import { Layout } from 'Layout'
import useAxios from 'hooks/useAxios'
import useGetMe from 'hooks/useGetMe'
import { PostWebViewPage } from 'pages/WebView/PostWebViewPage'
import Terms from 'webView/Terms'
import PrivacyPolicy from 'webView/PrivacyPolicy'
import PrivacyAgree from 'webView/PrivacyAgree'
import LocationAgree from 'webView/LocationAgree'

const App = () => {
  useAxios()
  const isLoading = useGetMe()
  if (isLoading) return null

  return (
    <div className="max-w-[1920px] mx-auto h-full">
      <Routes>
        <Route path="web_view/:postId" element={<PostWebViewPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="web_view/terms" element={<Terms />} />
        <Route path="web_view/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="web_view/privacy-agree" element={<PrivacyAgree />} />
        <Route path="web_view/location-agree" element={<LocationAgree />} />
        <Route path="*" element={<Layout />} />
      </Routes>
    </div>
  )
}

export default App
