import axios from 'api/axios'
import { CertificateImageModal } from 'components/CertificateImageModal'
import dayjs from 'dayjs'
import { Roles } from 'enums/Roles'
import { fatchRestCenter as formatRestDays, formatBusinessHour } from 'lib/helpers'
import {
  formatAddress,
  formatDateString,
  formatPhoneNumber,
} from 'pages/RetrivalManager/CentersPage'
import { useEffect, useMemo, useState } from 'react'
import { IoMdList } from 'react-icons/io'
import { RiEditFill } from 'react-icons/ri'
import { useNavigate, useParams } from 'react-router-dom'
import { Place } from 'types/Place'

export const CenterDetailsPage = () => {
  const navigate = useNavigate()
  const { centerId } = useParams()
  const [center, setCenter] = useState<Place>()
  const [factories, setFactories] = useState<Place[]>([])
  const [factory, setFactory] = useState<Place>()
  // const [isActive, setIsActive] = useState(0)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const approveCenter = async () => {
    try {
      if (!factory) return

      const res = await axios.patch<{ place: Place }>(`/places/${centerId}`, {
        isValid: true,
        factory: factory,
      })

      setCenter(res.data.place)
    } catch (error) {
      console.log(error)
    }
  }
  const refuseCenter = () => {
    navigate('/center')
  }
  // useEffect(() => {

  //   fetchFactories()
  // }, [])

  useEffect(() => {
    if (!centerId) return
    const centerData = async () => {
      try {
        const res = await axios.get<{ place: Place }>(`places/${centerId}`)
        const { place } = res.data
        setCenter(place)
      } catch (error) {
        console.log(error)
      }
    }

    const fetchFactories = async () => {
      try {
        const res = await axios.get<{ places: Place[] }>('/places?type=factory')
        setFactories(res.data.places)
      } catch (error) {
        console.log(error)
      }
    }
    centerData()
    fetchFactories()
  }, [centerId])

  const businessHourStrings = useMemo(() => {
    if (center && !center.businessHours.length) return ''

    return !center
      ? '-'
      : `평일 ${formatBusinessHour(Number(center.businessHours[1].open))}~${formatBusinessHour(
          Number(center?.businessHours[1].close),
        )}, 주말 ${formatBusinessHour(Number(center.businessHours[0].open))}~${formatBusinessHour(
          Number(center?.businessHours[0].close),
        )} (쉬는날 : ${formatRestDays(center)})`
  }, [center])

  return (
    <div className="flex flex-col flex-1 w-full h-full p-5 mxl:p-0 mxl:py-2">
      {center?.isValid ? (
        <>
          <div className="">
            <div className="flex items-center justify-between gap-2 mb-2 h-12">
              <h2 className="text-2xl">{center.name ? center.name : '-'}</h2>
              <div className="flex">
                <button
                  className=" flex items-center text-blue-600 border border-blue-600 hover:bg-blue-800 hover:border-blue-800 hover:text-white px-2 py-1 rounded"
                  onClick={() => {
                    navigate('edit')
                  }}>
                  <RiEditFill />
                  수정
                </button>

                <button
                  className="flex items-center border rounded border-gray-400 text-gray-500 px-2 py-1 ml-2 hover:text-white hover:bg-gray-400"
                  onClick={() => {
                    navigate('/center')
                  }}>
                  <IoMdList />
                  목록보기
                </button>
              </div>
            </div>

            <div className="w-full py-5 border-y border-gray-300">
              <div className="grid grid-cols-7 gap-x-1 gap-y-2">
                <dl className="flex flex-col">
                  <dt className="font-bold">반납센터명</dt>
                  <dd className="">{center.name ? center.name : '-'}</dd>
                </dl>
                <dl className="flex flex-col ">
                  <dt className="font-bold">대표명</dt>
                  <dd className="">{center.roles[0].user.name || '-'}</dd>
                </dl>
                <dl className="flex flex-col ">
                  <dt className="font-bold">전화번호</dt>
                  <dd className="">{formatPhoneNumber(center.phoneNumber)}</dd>
                </dl>
                <dl className="flex flex-col">
                  <dt className="font-bold">주소</dt>
                  <dd className="">{formatAddress(center.address)}</dd>
                </dl>
                <dl className="flex flex-col pr-5">
                  <dt className="font-bold">승인여부</dt>
                  <dd className="">{center.isValid ? '승인완료' : '승인대기중'}</dd>
                </dl>
                <dl className="flex flex-col ">
                  <dt className="font-bold">가입일</dt>
                  <dd className="">{formatDateString(center.createdAt)}</dd>
                </dl>
                <dl className="flex flex-col ">
                  <dt className="font-bold">영업시간</dt>
                  <dd className="">{businessHourStrings}</dd>
                </dl>
                <dl className="flex flex-col pr-5">
                  <dt className="font-bold">사업자등록번호</dt>
                  <dd className="">
                    {center.certificate?.certNumber
                      ? center.certificate.certNumber.replace(/^(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`)
                      : '-'}
                  </dd>
                </dl>
                <dl className="flex flex-col ">
                  <dt className="font-bold">이메일</dt>
                  <dd className="">{center.email ? center.email : '-'}</dd>
                </dl>
                <dl className="flex flex-col ">
                  <dt className="font-bold">은행</dt>
                  <dd className="">{center.bankAccount ? center.bankAccount.bank : '-'}</dd>
                </dl>
                <dl className="flex flex-col ">
                  <dt className="font-bold">계좌번호</dt>
                  <dd className="">
                    {center.bankAccount ? center.bankAccount.accountNumber : '-'}
                  </dd>
                </dl>
                <dl className="flex flex-col ">
                  <dt className="font-bold">예금주</dt>
                  <dd className="">{center.bankAccount ? center.bankAccount.ownerName : '-'}</dd>
                </dl>
                <dl className="flex flex-col ">
                  <dt className="font-bold">회수매니저</dt>
                  <dd className="">{center.retriever ? center.retriever.name : '-'}</dd>
                </dl>
                <dl className="flex flex-col ">
                  <dt className="font-bold">관할센터</dt>
                  <dd className="">{center.factory ? center.factory.name : '-'}</dd>
                </dl>
              </div>
            </div>
            {/* <div className="flex flex-col gap-5 mxl:py-2 mt-5">
              <div className="relative flex items-center justify-center w-full gap-6">
                <button
                  className={`text-xl w-auto rounded-md py-3 px-5 cursor-pointer relative bottom-[-1px] left-[-2px] ${
                    isActive === 0
                      ? ` bg-blue-600 hover:bg-blue-800 text-white`
                      : ` bg-gray-300 hover:bg-gray-500 text-gray-500 hover:text-white`
                  }`}
                  onClick={() => setIsActive(0)}>
                  입고목록
                </button>
                <button
                  className={`text-xl w-auto rounded-md py-3 px-5 cursor-pointer relative bottom-[-1px] left-[-2px] ${
                    isActive === 1
                      ? ` bg-blue-600 text-white hover:bg-blue-800`
                      : ` bg-gray-300 text-gray-500 hover:text-white hover:bg-gray-500`
                  }`}
                  onClick={() => setIsActive(1)}>
                  출고목록
                </button>
              </div>
              <div className="flex-1 overflow-auto bg-white"></div>
            </div> */}
          </div>
        </>
      ) : (
        <div className="flex gap-5 flex-col mxl:border-0 mxl:px-0 w-full">
          <div className="flex justify-between items-center border-b border-solid border-gray-300 mb-5 mxl:p-2 h-12">
            <h2 className=" text-2xl">가입정보</h2>
            <span className=" text-gray-500">&#42; 가입 상세정보를 확인해주세요</span>
          </div>
          <div className="border border-solid border-gray-300 mb-5">
            <dl className="flex flex-wrap">
              <dl className="flex w-full h-[244px]">
                <dl className="flex-col w-5/6 flex-wrap border-r border-b border-solid border-gray-300">
                  <dl className="flex">
                    <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/5 flex items-center  mxl:w-[35%] font-bold leading-10">
                      반납센터명
                    </dt>
                    <dd className=" py-2 px-5 w-4/5 flex items-center mxl:w-[65%] leading-10 border-l border-solid border-gray-300">
                      {center?.name ? center.name : '-'}
                    </dd>
                  </dl>
                  <dl className="flex">
                    <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/5 border-t border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10">
                      대표명
                    </dt>
                    <dd className=" py-2 px-5 w-4/5 border-t border-l border-solid border-gray-300 flex items-center mxl:w-[65%] leading-10">
                      {center?.roles
                        ? center.roles.find(r => r.type === Roles.CHIEF)?.user?.name
                        : '-'}
                    </dd>
                  </dl>
                  <dl className="flex">
                    <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/5 border-t border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10">
                      전화번호
                    </dt>
                    <dd className=" py-2 px-5 w-4/5 border-t border-l border-solid border-gray-300 flex items-center mxl:w-[65%] leading-10">
                      {center?.phoneNumber
                        ? center.phoneNumber.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                        : '-'}
                    </dd>
                  </dl>
                  <dl className="flex">
                    <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/5 border-t border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10">
                      주소
                    </dt>
                    <dd className=" py-2 px-5 w-4/5 border-t border-l border-solid border-gray-300 flex mxl:w-[65%] gap-2 flex-col">
                      <div className="">
                        {center?.address.address
                          ? `${center.address.address} ${center.address.extraAddress}`
                          : '-'}
                      </div>
                      <div className="">
                        {center?.address.roadAddress
                          ? `${center.address.roadAddress} ${center.address.extraAddress}`
                          : '-'}
                      </div>
                    </dd>
                  </dl>
                </dl>
                <dl className="flex flex-col w-1/6 border-b border-solid border-gray-300 overflow-hidden">
                  <dd
                    className=" mxl:w-[65%] h-full cursor-zoom-in"
                    onClick={() => setIsOpenModal(true)}>
                    <img
                      src={center?.certificate?.imageUrl ? center.certificate.imageUrl : '-'}
                      alt="certificateImg"
                      className=" object-contain w-full h-full"
                    />
                  </dd>
                </dl>
              </dl>
              <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 flex items-center border-r border-solid border-gray-300 mxl:w-[35%] font-bold leading-10">
                사업자등록번호
              </dt>
              <dd className=" py-2 px-5 w-5/6 flex items-center mxl:w-[65%] leading-10">
                {center?.certificate?.certNumber
                  ? center.certificate.certNumber.replace(/^(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`)
                  : '-'}
              </dd>

              <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10">
                가입일
              </dt>
              <dd className=" py-2 px-5 border-t w-5/6 border-solid border-gray-300 flex items-center mxl:w-[65%] leading-10">
                {center?.createdAt ? dayjs(center.createdAt).format('YYYY. MM. DD') : '-'}
              </dd>
              <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10">
                수정일
              </dt>
              <dd className=" py-2 px-5 border-t w-5/6 border-solid border-gray-300 flex items-center mxl:w-[65%] leading-10">
                {center?.updatedAt ? dayjs(center.updatedAt).format('YYYY. MM. DD') : '-'}
              </dd>

              <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10">
                이메일
              </dt>
              <dd className=" py-2 px-5 border-t w-5/6 border-solid border-gray-300 flex mxl:w-[65%] leading-10">
                {center?.email ? center.email : '-'}
              </dd>
            </dl>
          </div>

          <div className="" id="factory-assign">
            <h2 className="text-2xl">관할센터 지정</h2>

            <div className=" grid grid-cols-4 gap-4">
              {factories.map(f => {
                const selected = f.id === factory?.id
                return (
                  <div
                    className={`mt-5 border-2 border-solid rounded-md px-5 py-3 
                     ${selected ? 'border-blue-500' : 'border-gray-300'}`}
                    key={f.id}
                    onClick={() => {
                      selected ? setFactory(undefined) : setFactory(f)
                    }}>
                    <div className="text-xl font-bold p-1">{f.name}</div>
                    <div className=" px-1">
                      {f.phoneNumber
                        ? f.phoneNumber.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                        : '-'}
                    </div>
                    <div className=" px-1">
                      {f.address.address ? `${f.address.address} ${f.address.extraAddress}` : '-'}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="flex items-center justify-center gap-3 mt-5 mxl:flex-col" id="buttons">
            <button
              disabled={!factory}
              className={`py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full  cursor-pointer ${
                factory
                  ? 'bg-blue-600 text-white hover:bg-blue-800'
                  : 'bg-slate-200 text-gray-500 hover:bg-slate-300 hover:text-white'
              }`}
              onClick={approveCenter}>
              승인
            </button>

            <button
              className=" py-[0.6rem] bg-slate-200 text-gray-500 rounded-md text-2xl min-w-[200px] mmd:min-w-full cursor-pointer hover:bg-slate-300 hover:text-white"
              onClick={refuseCenter}>
              거절
            </button>
          </div>
        </div>
      )}
      {isOpenModal && (
        <CertificateImageModal
          imageUrl={center?.certificate?.imageUrl ? center.certificate.imageUrl : undefined}
          setIsOpenModal={setIsOpenModal}
        />
      )}
    </div>
  )
}
