import axios from 'api/axios'
import { useCallback, useEffect, useState } from 'react'
import { Place } from 'types/Place'

export const useHQ = () => {
  const [hq, setHq] = useState<Place>()

  const fetchHQ = useCallback(async () => {
    try {
      const res = await axios.get<{ hq: Place }>(`/places/hq`)
      setHq(res.data.hq)
      return res.data.hq
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    fetchHQ()
  }, [fetchHQ, hq])

  return { hq, fetchHQ }
}
