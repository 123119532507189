import axios from 'api/axios'
import { useCallback, useEffect, useState } from 'react'
import { IoMdList } from 'react-icons/io'
import { useNavigate, useParams } from 'react-router-dom'
import { Place } from 'types/Place'
import { User } from 'types/User'
import { formatAddress, formatDateString, formatPhoneNumber } from './CentersPage'

export const RetrieverDetailsPage = () => {
  const { retrieverId } = useParams()
  const [retriever, setRetriever] = useState<User>()
  const [centers, setCenters] = useState<Place[]>([])
  const navigate = useNavigate()

  const fetchRetriever = useCallback(async () => {
    try {
      const res = await axios.get<{ user: User }>(`/users/${retrieverId}`)
      const retriever = res.data.user
      setRetriever(retriever)

      const res2 = await axios.get<{ centers: Place[] }>(`/places/by-retriever/${retriever.id}`)
      setCenters(res2.data.centers)
    } catch (error) {
      console.log(error)
    }
  }, [retrieverId])

  useEffect(() => {
    fetchRetriever()
  }, [fetchRetriever])

  if (!retriever) return null

  return (
    <div className="flex-1 p-5">
      <div className="">
        <div className=" flex justify-between items-center h-12 mb-2">
          <h2 className="text-2xl">회수매니저</h2>

          <button
            className="flex items-center border rounded border-gray-400 text-gray-500 px-2 py-1 ml-2 hover:text-white hover:bg-gray-400"
            onClick={() => {
              navigate('/retrievers')
            }}>
            <IoMdList />
            목록보기
          </button>
        </div>
        <div className="flex w-full py-5 border-y border-gray-300 h-28">
          <dl className="flex flex-col justify-center pr-5">
            <dt className="font-bold text-cent">이름</dt>
            <dd className="">{retriever.name ? retriever.name : '-'}</dd>
          </dl>
          <dl className="flex flex-col justify-center px-5">
            <dt className="font-bold text-cent">전화번호</dt>
            <dd className="">
              {retriever.phoneNumber
                ? retriever.phoneNumber.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                : '-'}
            </dd>
          </dl>
          <dl className="flex flex-col justify-center px-5">
            <dt className="font-bold">회수팩 수</dt>
            <dd className="">{retriever.packs ? retriever.packs.length : '-'}</dd>
          </dl>
          <dl className="flex flex-col justify-center px-5">
            <dt className="font-bold">가입일</dt>
            <dd className="">{formatDateString(retriever.createdAt)}</dd>
          </dl>
        </div>
      </div>

      <div className="mt-2">
        <h2 className="pt-5 text-2xl">반납센터</h2>
        <ul className="grid grid-cols-4 gap-4 mt-5">
          {centers.map(center => (
            <li
              className="border-gray-300 border-2 border-solid rounded-md px-5 py-3 hover:border-blue-600"
              key={center.id}
              onClick={() => {
                navigate(`/centers/${center.id}`)
              }}>
              <div className="p-1">
                <span className="font-bold">이름 : </span>
                {center.name ? center.name : '-'}
              </div>
              <div className="p-1">
                <span className="font-bold">전화번호 : </span>
                {formatPhoneNumber(center.phoneNumber)}
              </div>
              <div className="p-1">
                <span className="font-bold">주소 : </span>
                {formatAddress(center.address)}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
