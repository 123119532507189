import { getPackStatusLabelText } from 'lib/helpers'
import { FilterRadio } from 'pages/Earthingpack/StatusRadio'
import { getPackStatusTextColor, PackStatus } from 'pages/Factory/FactoryPackPage'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Pack } from 'types/Pack'

export default function PackListTable({ packs }: { packs?: Pack[] }) {
  const [status, setStatus] = useState<PackStatus>()
  const [searchParams] = useSearchParams()
  const filteredPacks = packs ? (status ? packs.filter(p => p.status === status) : packs) : []

  useEffect(() => {
    const status = searchParams.get('status') as PackStatus
    setStatus(status ?? undefined)
  }, [searchParams])

  return (
    <div className="w-full">
      <div className="flex gap-2 justify-end">
        <FilterRadio filter={undefined} />
        <FilterRadio filter={PackStatus.RECEIVED} />
        <FilterRadio filter={PackStatus.CLEANING} />
        <FilterRadio filter={PackStatus.REPAIRING} />
        <FilterRadio filter={PackStatus.DROPPED} />
        <FilterRadio filter={PackStatus.READY} />
      </div>

      <table className="w-full border rounded mt-2 text-center">
        <thead className="border-t border-b">
          <tr className="bg-gray-100">
            <th className="p-2">시리얼넘버</th>
            <th className="p-2">상태</th>
          </tr>
        </thead>

        <tbody className="">
          {filteredPacks.length === 0 && (
            <tr className="">
              <td className="p-2" colSpan={2}>
                보유 중인 얼싱팩이 없습니다
              </td>
            </tr>
          )}

          {filteredPacks.map(pack => {
            const statusLabelColor = getPackStatusTextColor(pack)
            return (
              <tr className="hover:bg-gray-100 even:bg-gray-50" key={pack.id}>
                <td className="p-2">{pack.serialNumber}</td>
                <td className={`p-2 ${statusLabelColor}`}>{getPackStatusLabelText(pack)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
