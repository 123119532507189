import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useNavigate } from 'react-router-dom'
import { Post } from 'types/Post'

interface Props {
  infos: Post[]
}

export const InfoTableBoxComponent = ({ infos }: Props) => {
  const navigate = useNavigate()

  return (
    <div className="flex-1 overflow-auto bg-white whitespace-nowrap scrollbar-hide">
      <div className="rounded overflow-hidden">
        <table className="w-full border rounded mt-2">
          <thead className="border-t border-b">
            <tr className="bg-gray-100">
              <th className="p-2 text-center">이용관리 제목</th>
              <th className="p-2 text-center">등록일</th>
            </tr>
          </thead>
          <tbody className="w-full overflow-auto text-center">
            {infos.map(info => (
              <tr
                className="leading-9 odd:bg-white even:bg-slate-50 cursor-pointer"
                key={info.id}
                onClick={() => navigate(`info/${info.id}`)}>
                <td className=" w-9/12 ">{info.title}</td>
                <td className="">{formatDateString(info.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
