import { useEffect, useState } from 'react'
import { ScanMode } from 'pages/Factory/FactoryPackScanPage'
import { useSearchParams } from 'react-router-dom'
import { AdminReceiveScanner } from './AdminReceiveScanner'
import { AdminRegisterScanner } from './AdminRegisterScanner'
import { AdminReleaseScanner } from './AdminReleaseScanner'
import { useHQ } from 'hooks/useHQ'

export const EarthingpackScanPage = () => {
  const [mode, setMode] = useState<ScanMode>()
  const [searchParams, setSearchParams] = useSearchParams()
  const { fetchHQ } = useHQ()

  useEffect(() => {
    fetchHQ()
  }, [fetchHQ])

  useEffect(() => {
    setMode((searchParams.get('mode') as ScanMode) || undefined)
  }, [searchParams])

  const changeMode = (m: ScanMode) => {
    searchParams.delete('partnerId')
    mode === m ? searchParams.delete('mode') : searchParams.set('mode', m)
    setSearchParams(searchParams)
  }

  return (
    <div className="w-full p-5">
      <div className="flex items-center justify-center gap-10 ">
        <div className=" overflow-hidden rounded-full bg-slate-200">
          <button
            className={`px-20 py-5 text-3xl m2xl:p-0 m2xl:py-6 m2xl:px-10 rounded-full ${
              mode === 'register'
                ? 'bg-blue-600 text-white rounded-full hover:bg-blue-800'
                : 'bg-slate-200 hover:bg-gray-300'
            }`}
            onClick={() => changeMode('register')}>
            등록
          </button>

          <button
            className={` px-20 py-5 text-3xl m2xl:p-0 m2xl:py-6 m2xl:px-10 rounded-full ${
              mode === 'receive'
                ? 'bg-blue-600 text-white rounded-full hover:bg-blue-800'
                : 'bg-slate-200 hover:bg-gray-300'
            }`}
            onClick={() => changeMode('receive')}>
            입고
          </button>

          <button
            className={`px-20 py-5 text-3xl m2xl:p-0 m2xl:py-6 m2xl:px-10 rounded-full ${
              mode === 'release'
                ? 'bg-blue-600 text-white rounded-full hover:bg-blue-800'
                : 'bg-slate-200 hover:bg-gray-300'
            }`}
            onClick={() => changeMode('release')}>
            출고
          </button>
        </div>
      </div>

      {!mode && (
        <div className="w-full py-10 flex items-center justify-center bg-gray-100 mt-5 text-3xl font-bold text-gray-500 rounded">
          스캔모드를 선택하세요
        </div>
      )}

      {mode === 'register' && <AdminRegisterScanner />}
      {mode === 'receive' && <AdminReceiveScanner />}
      {mode === 'release' && <AdminReleaseScanner />}
    </div>
  )
}
