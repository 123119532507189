import { useState } from 'react'
import { EventPage } from './Event/EventPage'
import { FaqPage } from './Faq/FaqPage'
import { InfoPage } from './Info/InfoPage'
import { NoticePage } from './Notice/NoticePage'
import { PointPage } from './Point/PointPage'

export const SettingPage = () => {
  const [isNotice, setIsNotice] = useState(true)
  const [isFaq, setIsFaq] = useState(false)
  const [isInfo, setIsInfo] = useState(false)
  const [isPoint, setIsPoint] = useState(false)
  const [isEvent, setIsEvent] = useState(false)

  return (
    <div className="w-full p-5">
      <div className=" flex gap-5 items-center justify-center mxl:flex-wrap">
        <button
          className={` mxl:w-full flex items-center justify-center border border-solid bg-slate-200 border-slate-200 rounded-md py-4 w-2/6 hover:bg-slate-500 text-xl font-bold hover:border-slate-500 hover:text-white ${
            isNotice && 'bg-slate-600 text-white border-slate-600'
          }`}
          onClick={() => {
            setIsNotice(true)
            setIsFaq(false)
            setIsInfo(false)
            setIsPoint(false)
            setIsEvent(false)
          }}>
          공지사항 관리
        </button>
        <button
          className={` mxl:w-full flex items-center justify-center border border-solid bg-slate-200 border-slate-200 rounded-md py-4 w-2/6 hover:bg-slate-500 text-xl font-bold hover:border-slate-500 hover:text-white ${
            isFaq && 'bg-slate-600 text-white border-slate-600'
          }`}
          onClick={() => {
            setIsFaq(true)
            setIsNotice(false)
            setIsInfo(false)
            setIsPoint(false)
            setIsEvent(false)
          }}>
          FAQ 관리
        </button>
        <button
          className={` mxl:w-full flex items-center justify-center border border-solid bg-slate-200 border-slate-200 rounded-md py-4 w-2/6 hover:bg-slate-500 text-xl font-bold hover:border-slate-500 hover:text-white ${
            isInfo && 'bg-slate-600 text-white border-slate-600'
          }`}
          onClick={() => {
            setIsInfo(true)
            setIsFaq(false)
            setIsNotice(false)
            setIsPoint(false)
            setIsEvent(false)
          }}>
          이용안내 관리
        </button>
        <button
          className={` mxl:w-full flex items-center justify-center border border-solid bg-slate-200 border-slate-200 rounded-md py-4 w-2/6 hover:bg-slate-500 text-xl font-bold hover:border-slate-500 hover:text-white ${
            isPoint && 'bg-slate-600 text-white border-slate-600'
          }`}
          onClick={() => {
            setIsPoint(true)
            setIsFaq(false)
            setIsInfo(false)
            setIsNotice(false)
            setIsEvent(false)
          }}>
          포인트 관리
        </button>
        <button
          className={` mxl:w-full flex items-center justify-center border border-solid bg-slate-200 border-slate-200 rounded-md py-4 w-2/6 hover:bg-slate-500 text-xl font-bold hover:border-slate-500 hover:text-white ${
            isEvent && 'bg-slate-600 text-white border-slate-600'
          }`}
          onClick={() => {
            setIsEvent(true)
            setIsFaq(false)
            setIsInfo(false)
            setIsPoint(false)
            setIsNotice(false)
          }}>
          이벤트 관리
        </button>
      </div>
      <div className="pt-5">
        {isNotice && <NoticePage />}
        {isFaq && <FaqPage />}
        {isInfo && <InfoPage />}
        {isPoint && <PointPage />}
        {isEvent && <EventPage />}
      </div>
    </div>
  )
}
