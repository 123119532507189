import axios from 'api/axios'
import { Roles } from 'enums/Roles'
import { useFactory } from 'hooks/useFactory'
import { formatBusinessHour, fatchRestCenter as formatRestDays } from 'lib/helpers'
import { useState, useCallback, useEffect } from 'react'
import { MdOutlineDoNotDisturb } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import { Place } from 'types/Place'
import { User } from 'types/User'
import { formatDateString, formatPhoneNumber } from './CentersPage'

export const FactoryCenterUpdatePage = () => {
  const { centerId } = useParams()
  const { factory } = useFactory()
  const retrievers = factory?.roles.filter(r => r.type === Roles.RETRIEVER).map(r => r.user)
  const [retriever, setRetriever] = useState<User>()
  const [center, setCenter] = useState<Place>()
  const navigate = useNavigate()

  const fetchCenter = useCallback(async () => {
    try {
      const res = await axios.get<{ place: Place }>(`/places/${centerId}`)
      if (!res.data.place.roles) return
      setCenter(res.data.place)
    } catch (error) {
      console.log(error)
    }
  }, [centerId])

  useEffect(() => {
    fetchCenter()
  }, [fetchCenter])

  const submitCenter = useCallback(async () => {
    try {
      if (!retriever) return
      await axios.patch<{ place: Place }>(`/places/${centerId}`, { retriever })
      navigate(`/centers/${centerId}`)
    } catch (error) {
      console.log(error)
    }
  }, [centerId, navigate, retriever])

  if (!center)
    return (
      <div className="flex flex-col items-center justify-center flex-1 text-2xl text-gray-400">
        <span className=" text-5xl mb-5">
          <MdOutlineDoNotDisturb />
        </span>
        해당 아이디와 일치하는 센터가 없습니다
        <div>(센터 아이디 : {centerId})</div>
      </div>
    )

  if (!retrievers)
    return (
      <div className="flex flex-col items-center justify-center flex-1 text-2xl text-gray-400">
        <span className=" text-5xl mb-5">
          <MdOutlineDoNotDisturb />
        </span>
        회수매니저가 없습니다
        <div>(센터 아이디 : {centerId})</div>
      </div>
    )

  return (
    <div className=" flex-1 p-5 mxl:p-0">
      <div className="flex items-center w-full justify-between h-12">
        <h2 className="text-2xl">회수매니저 지정</h2>
      </div>

      <div className="mt-5 mxl:px-5" id="retriever-list">
        {retrievers.map(r => {
          const isSelected = retriever?.id === r.id
          return (
            <div
              className={`mt-5 border-2 border-solid rounded-md px-5 py-3 ${
                center.retriever?.id === r.id && 'border-blue-600'
              }
            ${isSelected ? 'border-blue-600' : 'border-gray-300'}`}
              onClick={() => {
                isSelected ? setRetriever(undefined) : setRetriever(r)
              }}
              key={r.id}>
              <div className="">
                <span className=" font-bold">이름 : </span>
                {r.name ? r.name : '-'}
              </div>
              <div className="">
                <span className=" font-bold">전화번호 : </span>
                {formatPhoneNumber(r.phoneNumber)}
              </div>
              <div className="">
                <span className=" font-bold">가입일 : </span>
                {formatDateString(r.createdAt)}
              </div>
            </div>
          )
        })}
      </div>

      <div className="">
        <h2 className="text-2xl mxl:p-5 mt-5 h-12">정보</h2>
        <div className="border border-solid border-gray-300">
          <dl className="flex flex-wrap">
            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 flex items-center border-r border-solid border-gray-300 mxl:w-[35%] font-bold leading-10">
              반납센터명
            </dt>
            <dd className=" py-2 px-5 w-5/6 flex items-center mxl:w-[65%] leading-10">
              {center.name ? center.name : '-'}
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10">
              대표명
            </dt>
            <dd className=" py-2 px-5 w-5/6 border-t border-solid border-gray-300 flex items-center mxl:w-[65%] leading-10">
              {center.roles ? center.roles.find(r => r.type === Roles.CHIEF)?.user.name : '-'}
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10">
              전화번호
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 border-solid border-gray-300 flex items-center mxl:w-[65%] leading-10">
              {center.phoneNumber
                ? center.phoneNumber.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                : '-'}
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10">
              주소
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 border-solid border-gray-300 flex mxl:w-[65%] gap-2 flex-col leading-10">
              <span>
                {center.address.address
                  ? `${center.address.address} ${center.address.extraAddress}`
                  : '-'}
              </span>
              <span>
                {center.address.roadAddress
                  ? `${center.address.roadAddress} ${center.address.extraAddress}`
                  : ''}
              </span>
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10">
              가입일
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 border-solid border-gray-300 flex items-center mxl:w-[65%] leading-10">
              {formatDateString(center.createdAt)}
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10">
              이메일
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 border-solid border-gray-300 flex mxl:w-[65%] gap-2 flex-col leading-10">
              {center.email ? center.email : '-'}
            </dd>

            <dt className="bg-gray-100 py-2 px-5 text-xl text-start w-1/6 border-t border-r border-solid border-gray-300 flex items-center mxl:w-[35%] font-bold leading-10">
              영업시간
            </dt>
            <dd className=" py-2 px-5 border-t w-5/6 border-solid border-gray-300 flex items-center mxl:w-[65%] leading-10">
              {`평일 ${formatBusinessHour(Number(center.businessHours[1].open))} ~
                ${formatBusinessHour(Number(center.businessHours[1].close))}, 주말 
                ${formatBusinessHour(Number(center.businessHours[0].open))} ~
                ${formatBusinessHour(
                  Number(center.businessHours[0].close),
                )} (쉬는날 : ${formatRestDays(center)})`}
            </dd>
          </dl>
        </div>
        <div className="flex items-center justify-center gap-3 mt-5 mxl:flex-col">
          <button
            className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-blue-600 text-white hover:bg-blue-800"
            onClick={() => submitCenter()}>
            수정
          </button>
          <button
            className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-slate-200 text-gray-500 hover:bg-gray-400 hover:text-white"
            onClick={() => navigate(-1)}>
            취소
          </button>
        </div>
      </div>
      {/* <pre>{JSON.stringify(center, null, 2)}</pre> */}
    </div>
  )
}
