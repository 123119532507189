import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useNavigate } from 'react-router-dom'
import { Post } from 'types/Post'

interface Props {
  notices: Post[]
}

export const NoticeTableBoxComponent = ({ notices }: Props) => {
  const navigate = useNavigate()

  return (
    <div className="flex-1 overflow-auto whitespace-nowrap scrollbar-hide">
      <div className=" rounded overflow-hidden">
        <table className="w-full border rounded mt-2">
          <thead className="border-t border-b">
            <tr className="bg-gray-100">
              <th className="p-2 text-center">공지사항 제목</th>
              <th className="p-2 text-center">등록일</th>
            </tr>
          </thead>
          <tbody className="w-full overflow-auto text-center">
            {notices.length > 0 ? (
              notices.map(notice => (
                <tr
                  className="leading-9 odd:bg-white even:bg-slate-50 cursor-pointer"
                  key={notice.id}
                  onClick={() => navigate(`/setting/notice/${notice.id}`)}>
                  <td className="w-9/12 ">{notice.title}</td>
                  <td className="">{formatDateString(notice.createdAt)}</td>
                </tr>
              ))
            ) : (
              <tr className="">
                <td colSpan={6} className="p-2 text-center">
                  공지사항이 없습니다
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
