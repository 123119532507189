import axios from 'api/axios'
import { Roles } from 'enums/Roles'
import {
  formatAddress,
  formatDateString,
  formatPhoneNumber,
} from 'pages/RetrivalManager/CentersPage'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Place } from 'types/Place'
import { PackStatus } from './FactoryPackPage'

export const FactoryPage = () => {
  const [factories, setFactories] = useState<Place[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    const fetchFactorys = async () => {
      try {
        const res = await axios.get<{ places: Place[] }>('/places?type=factory')
        const { places } = res.data

        setFactories(places)
      } catch (error) {
        console.log(error)
      }
    }
    fetchFactorys()
  }, [])

  return (
    <div className="w-full p-5">
      <div className="flex items-center justify-between">
        <h2 className="">세척센터</h2>
        <button
          className="rounded bg-teal-500 px-4 py-1 text-white"
          onClick={() => navigate('add')}>
          새 세척센터 등록
        </button>
      </div>

      <table className="w-full border rounded mt-2 text-center">
        <thead className="border-t border-b">
          <tr className="bg-gray-100">
            <th className="p-2">이름</th>
            <th className="p-2">대표자</th>
            <th className="p-2">전화번호</th>
            <th className="p-2">주소</th>
            <th className="p-2">아이디</th>
            <th className="p-2">팩</th>
            <th className="p-2">준비된 팩</th>
            <th className="p-2">가입일</th>
            <th className="p-2">액션</th>
          </tr>
        </thead>

        <tbody className="">
          {factories.length === 0 && (
            <tr className="">
              <td className="p-2" colSpan={9}>
                세척센터가 없습니다
              </td>
            </tr>
          )}

          {factories.map(factory => {
            const chief = factory.roles.find(r => r.type === Roles.CHIEF)?.user
            return (
              <tr
                className="hover:bg-gray-50 hover:cursor-pointer even:bg-gray-50"
                onClick={() => navigate(`/factory/${factory.id}`)}
                key={factory.id}>
                <td className="p-2">{factory.name || '-'}</td>
                <td className="p-2">{chief?.name || '-'}</td>
                <td className="p-2">{formatPhoneNumber(factory.phoneNumber)}</td>
                <td className="p-2">{formatAddress(factory.address)}</td>
                <td className="p-2">{chief?.identifier ? chief.identifier : '-'}</td>
                <td className="p-2">{factory.packs.length}</td>
                <td className="p-2 font-bold text-green-500">
                  {factory.packs.filter(p => p.status === PackStatus.READY).length}
                </td>
                <td className="p-2">{formatDateString(factory.createdAt)}</td>
                <td className="p-2">
                  <button
                    className="rounded bg-teal-500 px-4 py-1 text-white"
                    onClick={e => {
                      e.stopPropagation()
                      navigate(`/order/add?from=${factory.id}`)
                    }}>
                    출고 주문
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
