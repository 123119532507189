import { SideBar } from 'components/SideBar'
import { ClientPage } from 'pages/Client/ClientPage'
import { DataPage } from 'pages/Data/DataPage'
import { IndexMainPage } from 'pages/Main/IndexMainPage'
import { RetrieverAddPage } from 'pages/RetrivalManager/RetrieverManagerPage'
import { CenterPage } from 'pages/Center/CenterPage'
import { CenterDetailsPage } from 'pages/Center/CenterDetailsPage'
import { ManagerPage } from 'pages/Setting/Manager/ManagerPage'
import { NoticePage } from 'pages/Setting/Notice/NoticePage'
import { SettingPage } from 'pages/Setting/SettingPage'
import PartnerPage from 'pages/Partner/PartnerPage'
import { FactoryPage } from 'pages/Factory/FactoryPage'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { EarthingpackScanPage } from 'pages/Earthingpack/EarthingpackScanPage'
import { MainHeaderComponent } from 'components/MainHeaderComponent'
import { PartnerDetailsPage } from 'pages/Partner/PartnerDetailsPage'
import { PartnerAddPage } from 'pages/Partner/PartnerAddPage'
import { PartnerUpdatePage } from 'pages/Partner/PartnerUpdatePage'
import { FactoryAddPage } from 'pages/Factory/FactoryAddPage'
import { FactoryAdminUpdatePage } from 'pages/Factory/FactoryAdminUpdatePage'
import { FactoryDetailsPage } from 'pages/Factory/FactoryDetailsPage'
import { CenterUpdatePage } from 'pages/Center/CenterUpdatePage'
import { EarthingpackPage } from 'pages/Earthingpack/EarthingpackPage'
import { NoticeAddPage } from 'pages/Setting/Notice/NoticeAddPage'
import { NoticeDetailsPage } from 'pages/Setting/Notice/NoticeDetailsPage'
import { FaqPage } from 'pages/Setting/Faq/FaqPage'
import { InfoPage } from 'pages/Setting/Info/InfoPage'
import { InfoAddPage } from 'pages/Setting/Info/InfoAddPage'
import { InfoDetailsPage } from 'pages/Setting/Info/InfoDetailsPage'
import { NoticeUpdatePage } from 'pages/Setting/Notice/NoticeUpdatePage'
import { ClientDetailsPage } from 'pages/Client/ClientDetailsPage'
import { FaqAddPage } from 'pages/Setting/Faq/FaqAddPage'
import { FaqDetailsPage } from 'pages/Setting/Faq/FaqDetailsPage'
import { FaqUpdatePage } from 'pages/Setting/Faq/FaqUpdatePage'
import { EventPage } from 'pages/Setting/Event/EventPage'
import { EventAddPage } from 'pages/Setting/Event/EventAddPage'
import { EventDetailsPage } from 'pages/Setting/Event/EventDetailsPage'
import { PointPage } from 'pages/Setting/Point/PointPage'
import { PointDetailsPage } from 'pages/Setting/Point/PointDetailsPage'
import { PointAddPage } from 'pages/Setting/Point/PointAddPage'
import { EventUpdatePage } from 'pages/Setting/Event/EventUpdatePage'
import { PointUpdatePage } from 'pages/Setting/Point/PointUpdatePage'
import { InfoUpdatePage } from 'pages/Setting/Info/InfoUpdatePage'
import { FactoryPackScanPage } from 'pages/Factory/FactoryPackScanPage'
import { RetrieverDetailsPage } from 'pages/RetrivalManager/RetrieverDetailsPage'
import { RetrieversPage } from 'pages/RetrivalManager/RetrieversPage'
import { CleanersPage } from 'pages/RetrivalManager/CleanersPage'
import { CentersPage } from 'pages/RetrivalManager/CentersPage'
import { FactoryCentersDetailsPage } from 'pages/RetrivalManager/FactoryCentersDetailsPage'
import { FactoryCenterUpdatePage } from 'pages/RetrivalManager/FactoryCenterUpdatePage'
import { useAppSelector } from 'store'
import { selectIsLoggedIn } from 'store/userSlice'
import { FactoryUpdatePage } from 'pages/RetrivalManager/FactoryUpdatePage'
import { FactoryPackPage } from 'pages/Factory/FactoryPackPage'
import { FactoryReleasePage } from 'pages/Release/FactoryReleasePage'
import { FactoryReceivePage } from 'pages/Release/FactoryReceivePage'
import OrdersPage from 'pages/Earthingpack/OrdersPage'
import OrderAddPage from 'pages/Earthingpack/OrderAddPage'
import AdminReciveList from 'pages/Earthingpack/AdminReciveList'
import AdminIssueList from 'pages/Earthingpack/AdminIssueList'
import EarthingpackDetailsPage from 'pages/Earthingpack/EarthingpackDetailsPage'

export const Layout = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  if (!isLoggedIn) return <Navigate to="/login" />

  return (
    <>
      <MainHeaderComponent />
      <div className="flex flex-1 mxl:flex-col">
        <SideBar />
        <Routes>
          <Route path="/" element={<IndexMainPage />} />

          <Route path="partner" element={<Outlet />}>
            <Route index element={<PartnerPage />} />
            <Route path=":partnerId" element={<PartnerDetailsPage />} />
            <Route path=":partnerId/edit" element={<PartnerUpdatePage />} />
            <Route path="add" element={<PartnerAddPage />} />
          </Route>

          <Route path="client" element={<Outlet />}>
            <Route index element={<ClientPage />} />
            <Route path=":clientId" element={<ClientDetailsPage />} />
          </Route>

          <Route path="center" element={<Outlet />}>
            <Route index element={<CenterPage />} />
            <Route path=":centerId" element={<CenterDetailsPage />} />
            <Route path=":centerId/edit" element={<CenterUpdatePage />} />
          </Route>

          {/* factory menu start */}
          <Route path="factory/edit" element={<FactoryUpdatePage />} />
          <Route path="cleaners" element={<CleanersPage />} />

          <Route path="centers" element={<Outlet />}>
            <Route index element={<CentersPage />} />
            <Route path=":centerId" element={<FactoryCentersDetailsPage />} />
            <Route path=":centerId/edit" element={<FactoryCenterUpdatePage />} />
          </Route>

          <Route path="retrievers" element={<Outlet />}>
            <Route index element={<RetrieversPage />} />
            <Route path=":retrieverId" element={<RetrieverDetailsPage />} />
            <Route path="add" element={<RetrieverAddPage />} />
          </Route>

          <Route path="earthing" element={<Outlet />}>
            <Route index element={<FactoryPackPage />} />
            <Route path="scan" element={<FactoryPackScanPage />} />
          </Route>

          <Route path="release" element={<FactoryReleasePage />} />
          <Route path="receive" element={<FactoryReceivePage />} />
          <Route path="scan" element={<FactoryPackScanPage />} />
          {/* factory menu end */}

          <Route path="factory" element={<Outlet />}>
            <Route index element={<FactoryPage />} />
            <Route path=":factoryId" element={<FactoryDetailsPage />} />
            <Route path=":factoryId/edit" element={<FactoryAdminUpdatePage />} />
            <Route path="add" element={<FactoryAddPage />} />
          </Route>

          <Route path="data" element={<DataPage />} />

          <Route path="setting" element={<Outlet />}>
            <Route index element={<SettingPage />} />

            <Route path="notice" element={<Outlet />}>
              <Route index element={<NoticePage />} />
              <Route path=":noticeId" element={<NoticeDetailsPage />} />
              <Route path=":noticeId/edit" element={<NoticeUpdatePage />} />
              <Route path="add" element={<NoticeAddPage />} />
            </Route>

            <Route path="manager" element={<ManagerPage />} />

            <Route path="faq" element={<Outlet />}>
              <Route index element={<FaqPage />} />
              <Route path=":faqId" element={<FaqDetailsPage />} />
              <Route path=":faqId/edit" element={<FaqUpdatePage />} />
              <Route path="add" element={<FaqAddPage />} />
            </Route>

            <Route path="info" element={<Outlet />}>
              <Route index element={<InfoPage />} />
              <Route path=":infoId" element={<InfoDetailsPage />} />
              <Route path=":infoId/edit" element={<InfoUpdatePage />} />
              <Route path="add" element={<InfoAddPage />} />
            </Route>

            <Route path="point" element={<Outlet />}>
              <Route index element={<PointPage />} />
              <Route path=":pointId" element={<PointDetailsPage />} />
              <Route path=":pointId/edit" element={<PointUpdatePage />} />
              <Route path="add" element={<PointAddPage />} />
            </Route>

            <Route path="event" element={<Outlet />}>
              <Route index element={<EventPage />} />
              <Route path=":eventId" element={<EventDetailsPage />} />
              <Route path=":eventId/edit" element={<EventUpdatePage />} />
              <Route path="add" element={<EventAddPage />} />
            </Route>
          </Route>

          <Route path="earthingpack" element={<Outlet />}>
            <Route index element={<EarthingpackPage />} />
            <Route path="scan" element={<EarthingpackScanPage />} />
            {/* <Route path="release" element={<ReleasePage />} /> */}
            <Route path="recive" element={<AdminReciveList />} />
            <Route path="release" element={<AdminIssueList />} />
            <Route path=":id" element={<EarthingpackDetailsPage />} />
          </Route>

          <Route path="order" element={<Outlet />}>
            <Route index element={<OrdersPage />} />
            <Route path="add" element={<OrderAddPage />} />
          </Route>
        </Routes>
      </div>
    </>
  )
}
