import axios from 'api/axios'
import { SubHeaderComponent } from 'components/SubHeaderComponent'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Post } from 'types/Post'
import { InfoTableBoxComponent } from './InfoTableBoxComponent'

export const InfoPage = () => {
  const navigate = useNavigate()
  const [infos, setInfos] = useState<Post[]>([])

  const openInfoPage = () => {
    navigate('info/add')
  }
  useEffect(() => {
    const fatchInfo = async () => {
      try {
        const res = await axios.get<{ posts: Post[] }>('/post?type=info')
        setInfos(res.data.posts)
      } catch (error) {
        console.log(error)
      }
    }
    fatchInfo()
  }, [])
  return (
    <div className="flex-1 bg-white">
      <SubHeaderComponent text="이용안내 관리" openAddPage={openInfoPage} />

      <InfoTableBoxComponent infos={infos} />
    </div>
  )
}
