import axios from 'api/axios'
import { BannerInputPreviewComponent } from 'components/BannerInputPreviewComponent'
import { QuillEditorComponent } from 'components/QuillEditorComponent'
import { Posts } from 'enums/Posts'
import { Roles } from 'enums/Roles'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Post } from 'types/Post'

export const EventAddPage = () => {
  const navigate = useNavigate()
  const [body, setBody] = useState('')
  const [bannerUrl, setBannerUrl] = useState<string>()
  const [url, setUrl] = useState('')

  const submitEvent = async () => {
    if (!bannerUrl) return alert('배너 이미지를 넣어주세요')
    if (!url) return alert('연결할 링크')
    if (!body) return alert('본문')
    try {
      const range = [Roles.CONSUMER]
      const res = await axios.post<{ post: Post }>('/post', {
        bannerUrl,
        body,
        type: Posts.EVENT,
        url,
        range,
      })
      navigate(`/setting/event/${res.data.post.id}`)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="flex-1 p-5 bg-white mxl:p-0">
      <h2 className="text-2xl h-12 flex items-center">이벤트 게시물 작성</h2>
      <BannerInputPreviewComponent
        type={Posts.EVENT}
        bannerUrl={bannerUrl}
        setBannerUrl={setBannerUrl}
      />
      <div className="flex items-center gap-4 py-3">
        <label htmlFor="" className="font-bold  text-md">
          연결할 링크
        </label>
        <input
          type="text"
          className="flex-1 w-full px-2 leading-10 border border-gray-300 border-solid rounded-md mxl:w-full"
          value={url}
          onChange={e => setUrl(e.target.value)}
          placeholder="연결할 이벤트 링크"
        />
      </div>

      <div className="mt-5 h-[55vh] md:h-[66vh]">
        <QuillEditorComponent type={Posts.EVENT} body={body} setBody={setBody} />
      </div>

      <div className="flex items-center justify-center gap-3 mt-20 mxl:flex-col">
        <button
          className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-blue-600 text-white hover:bg-blue-800"
          onClick={() => submitEvent()}>
          완료
        </button>
        <button
          className="py-[0.6rem] rounded-md text-2xl min-w-[200px] mmd:min-w-full bg-slate-200 text-gray-500 hover:bg-gray-400 hover:text-white"
          onClick={() => navigate(-1)}>
          취소
        </button>
      </div>
    </div>
  )
}
