import axios from 'api/axios'
import { useFactory } from 'hooks/useFactory'
import { beep, getPackStatusLabelText } from 'lib/helpers'
import { ScanMode } from 'pages/Factory/FactoryPackScanPage'
import { useEffect, useRef, useState } from 'react'
import { Pack } from 'types/Pack'

export const modeMapper = (mode: ScanMode) => {
  return mode === 'clean'
    ? 'cleaning'
    : mode === 'repair'
    ? 'repairing'
    : mode === 'drop'
    ? 'dropped'
    : 'ready'
}

const modeDescriptionMapper = (mode: ScanMode) => {
  return mode === 'clean'
    ? "세척스캔을 마치면 얼싱팩 상태가 '세척중'으로 표시됩니다"
    : mode === 'repair'
    ? "수리스캔을 마치면 얼싱팩 상태가 '수리중'으로 표시됩니다"
    : mode === 'drop'
    ? "폐기스캔을 마치면 얼싱팩 상태가 '폐기됨'으로 표시됩니다"
    : "준비완료스캔을 마치면 얼싱팩 상태가 '준비됨'으로 표시됩니다"
}

function PackStatusScanner({ mode }: { mode: ScanMode }) {
  const [packs, setPacks] = useState<Pack[]>([])
  const [msgError, setMsgError] = useState<string>('')
  const { factory, fetchFactory } = useFactory()
  const inputRef = useRef<HTMLInputElement>(null)
  const isStatusMode = ['clean', 'repair', 'drop', 'ready'].includes(mode)

  useEffect(() => {
    fetchFactory()
  }, [fetchFactory])

  useEffect(() => {
    const reset = async () => {
      const fetchedFactory = await fetchFactory()
      if (!fetchedFactory) return

      setPacks([])
      if (inputRef.current) inputRef.current.value = ''
    }

    reset()
  }, [fetchFactory, mode])

  useEffect(() => {
    const timer = setTimeout(() => setMsgError(''), 2000)
    return () => clearTimeout(timer)
  }, [msgError, setMsgError])

  const addPack = async () => {
    if (!inputRef.current) return
    const serialNumber = inputRef.current.value
    inputRef.current.value = ''

    const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
    if (korean.test(serialNumber)) {
      inputRef.current.value = ''
      beep(false)
      return alert('언어를 영어로 전환 후 바코드를 스캔하세요.')
    }

    if (!serialNumber) {
      beep(false)
      setMsgError('다시 한 번 스캔해 주세요')
      return
    }

    const duplicateCheck = packs.find(p => p.serialNumber === serialNumber)
    if (duplicateCheck) {
      beep(false)
      setMsgError('중복으로 스캔된 팩입니다')
      return
    }

    const pack = factory.packs.find(pack => pack.serialNumber === serialNumber)
    if (!pack) {
      beep(false)
      setMsgError('보유한 얼싱팩이 아닙니다')
      return
    }

    try {
      const res = await axios.put(`/packs/${pack.id}`, { status: modeMapper(mode) })
      setPacks(pre => [...pre, res.data.pack])
      beep()
    } catch (error) {
      console.log(error)
      beep(false)
    }
  }

  if (!isStatusMode) return null

  return (
    <div>
      <input
        autoFocus
        ref={inputRef}
        type="text"
        onBlur={() => inputRef.current?.focus()}
        className="w-0 h-0 noKorean"
        onKeyPress={e => {
          if (e.key === 'Enter') addPack()
        }}
      />

      <p className="text-center">{modeDescriptionMapper(mode)}</p>

      <div>
        {packs.length > 0 && (
          <div className="mt-4">
            <div className="flex gap-2 justify-center text-lg font-semibold">
              <div>총 스캔 수: </div>
              <div>{packs.length}</div>
            </div>

            {msgError && <div className="text-xl mt-2 font-semibold text-center">{msgError}</div>}

            <div className="w-[260px] mx-auto mt-4 border py-4">
              {packs.length > 0 &&
                packs.map(p => (
                  <div key={p.id} className="flex gap-2 mb-1 justify-center">
                    <div className="w-20">{p.serialNumber}</div>
                    <div className="text-blue-700">{getPackStatusLabelText(p)}</div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PackStatusScanner
