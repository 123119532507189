import axios from 'api/axios'
import { useFactory } from 'hooks/useFactory'
import { beep } from 'lib/helpers'
import { ScanMode } from 'pages/Factory/FactoryPackScanPage'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAppSelector } from 'store'
import { Pack } from 'types/Pack'
import { Transfer, Transfers, TransferStatus } from 'types/Transfer'
import { User } from 'types/User'

export function ReceiveScanner({ mode }: { mode: ScanMode }) {
  const [packs, setPacks] = useState<Pack[]>([])
  const [retrievings, setRetrievings] = useState<Transfer[]>([])
  const [retriever, setRetriever] = useState<User>()
  const [orphanRetrieving, setOrphanRetrieving] = useState<Transfer>()

  const { factory, fetchFactory } = useFactory()
  const inputRef = useRef<HTMLInputElement>(null)
  const user = useAppSelector(s => s.user)

  useEffect(() => {
    fetchFactory()
  }, [fetchFactory])

  const totalRetrievingPacks = useMemo(() => {
    return retrievings
      .map(r => (r.status === TransferStatus.COMPLETED ? r.missingPacks : r.packs))
      .flat()
  }, [retrievings])

  const orphanPacks = useMemo(
    () => packs.filter(p => !totalRetrievingPacks.find(_p => _p.id === p.id)),
    [packs, totalRetrievingPacks],
  )

  useEffect(() => {
    if (!factory) return
    if (!orphanPacks.length) return
    return setOrphanRetrieving(or => {
      return or
        ? {
            ...or,
            packs: orphanPacks,
            giver: retriever || user,
          }
        : {
            id: -1,
            createdAt: '',
            to: factory,
            giver: retriever || user,
            packs: orphanPacks,
            missingPacks: [],
            status: TransferStatus.STARTED,
            type: Transfers.RETRIEVING,
          }
    })
  }, [factory, orphanPacks, orphanPacks.length, retriever, user])

  const alertBadCode = useCallback((text: string) => {
    if (!inputRef.current) return
    beep(false)
    inputRef.current.value = ''
    alert(text)
  }, [])

  const checkPack = useCallback(
    async (serialNumber: string) => {
      if (!inputRef.current) return
      try {
        const res = await axios.post<{
          ok: boolean
          pack: Pack
          retriever?: User
          missingRetrieving?: Transfer
        }>('packs/check', { serialNumber })

        if (!res.data.ok) return alertBadCode(`잘못된 시리얼넘버입니다: ${serialNumber}`)

        beep()

        inputRef.current.value = ''

        if (res.data.retriever && res.data.missingRetrieving) {
          setRetriever(r => res.data.retriever ?? r)
          setRetrievings(rr =>
            res.data.missingRetrieving && !rr.find(r => r.id === res.data.missingRetrieving?.id)
              ? [...rr, res.data.missingRetrieving]
              : rr,
          )
        }
        setPacks(pp => [...pp, res.data.pack])
      } catch (error) {
        console.log('check pack by serialNumber error', error)
      }
    },
    [alertBadCode],
  )

  const checkRetrievings = useCallback(
    async (serialNumber: string) => {
      if (!factory) return
      if (retrievings.find(r => r.status === TransferStatus.STARTED)) return

      try {
        const res = await axios.post<{ retrievings: Transfer[]; retriever: User }>(
          'transfer/retrievings',
          {
            serialNumber,
            factoryId: factory.id,
          },
        )
        // console.log('retrievings: ', JSON.stringify(res.data.retrievings, null, 2))
        setRetrievings(rr => [...rr, ...res.data.retrievings])
        setRetriever(res.data.retriever)
      } catch (error) {
        console.log('fetch retrieving error', error)
      }
    },
    [factory, retrievings],
  )

  const addPack = useCallback(async () => {
    const serialNumber = inputRef.current?.value
    if (!serialNumber) return
    if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(serialNumber))
      return alertBadCode('언어를 영어로 전환 후 스캔하세요')
    if (serialNumber.length !== 7) return alertBadCode(`잘못된 시리얼넘버입니다: ${serialNumber}`)
    if (packs.find(p => p.serialNumber === serialNumber))
      return alertBadCode(`이미 스캔한 팩입니다.`)
    if (factory.packs.find(p => p.serialNumber === serialNumber))
      return alertBadCode('이미 보유중인 팩입니다: ' + serialNumber)

    await checkRetrievings(serialNumber)
    await checkPack(serialNumber)
  }, [alertBadCode, checkPack, checkRetrievings, factory.packs, packs])

  const completeRetrievings = useCallback(async () => {
    if (!factory) return

    try {
      await axios.post<{ retrievings: Transfer[] }>('/transfer/complete-retrievings', {
        packs,
        retrievings,
        orphanPacks,
        factoryId: factory.id,
        retrieverId: retriever ? retriever.id : user.id,
      })
      setPacks([])
      setRetrievings([])
      setOrphanRetrieving(undefined)
      setRetriever(undefined)
    } catch (error) {
      console.log(error)
    }
  }, [factory, orphanPacks, packs, retriever, retrievings, user.id])

  if (mode !== 'receive') return null

  return (
    <div className="w-full">
      <input
        autoFocus
        ref={inputRef}
        type="text"
        onBlur={() => inputRef.current?.focus()}
        className="w-0 h-0 noKorean"
        onKeyPress={e => {
          if (e.key === 'Enter') addPack()
        }}
      />

      <p className="text-center">입고스캔을 마치면 얼싱팩 상태가 '입고됨'으로 표시됩니다.</p>

      {packs.length === 0 && (
        <h2 className="flex items-center justify-center h-full text-3xl text-center">
          얼싱팩을 스캔해주세요
        </h2>
      )}

      {packs.length > 0 && (
        <div className="flex items-center justify-center mt-5">
          <div className="text-3xl ">
            현재 <span className="font-bold text-blue-700">{packs.length}</span>개 스캔됨
          </div>
          <button
            className="px-4 py-2 text-2xl text-white bg-blue-500 rounded-md ml-5"
            onClick={() => completeRetrievings()}>
            완료
          </button>
        </div>
      )}

      {retriever && (
        <h2 className=" text-3xl mb-5">
          {retriever?.name}
          {totalRetrievingPacks.length > 0 && <span> 총 {totalRetrievingPacks.length} 개</span>}
        </h2>
      )}

      <div className="grid grid-cols-4 rounded-md gap-5">
        {(orphanRetrieving ? [...retrievings, orphanRetrieving] : retrievings).map(ret => (
          <div
            className=" border border-solid border-gray-300 rounded-md overflow-hidden"
            key={ret.id}>
            <div className="leading-10 text-2xl">
              <div className=" bg-slate-200 border-gray-300 p-5 text-center">
                {ret.from ? ret.from.name : '출처없음'}{' '}
                <span className="font-bold">
                  {(ret.status === TransferStatus.COMPLETED ? ret.missingPacks : ret.packs).length}
                </span>
                개
                {ret.status === TransferStatus.COMPLETED && (
                  <span className="ml-4 text-base text-red-400">분실반납</span>
                )}
              </div>
            </div>

            <ul className="text-lg px-4 py-2">
              {(ret.status === TransferStatus.COMPLETED ? ret.missingPacks : ret.packs).map(p => (
                <li className="leading-7 text-center" key={p.id}>
                  <span
                    className={`text-base ${
                      packs.find(_p => _p.id === p.id)
                        ? 'font-bold text-emerald-600'
                        : 'text-gray-300'
                    }`}>
                    {p.serialNumber}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}
