import axios from 'api/axios'
import { SubHeaderComponent } from 'components/SubHeaderComponent'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Post } from 'types/Post'
import { FaqTableBoxComponent } from './FaqTableBoxComponent'

export const FaqPage = () => {
  const navigate = useNavigate()
  const [faqs, setFaqs] = useState<Post[]>([])
  const openAddFaq = () => {
    navigate('faq/add')
  }

  useEffect(() => {
    const fatchFaq = async () => {
      try {
        const res = await axios.get<{ posts: Post[] }>('/post?type=faq')
        setFaqs(res.data.posts)
      } catch (error) {
        console.log(error)
      }
    }
    fatchFaq()
  }, [])

  return (
    <div className="flex-1 bg-white">
      <SubHeaderComponent text="FQA 관리" openAddPage={openAddFaq} />

      <FaqTableBoxComponent faqs={faqs} />
    </div>
  )
}
